import React from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Redirect } from 'react-router-dom';

import { legal_name_schema } from "./schemas/legalName";
import { company_name_schema } from "./schemas/companyName";
import { vat_schema } from "./schemas/vat";
import { address_schema, address_ui_schema } from "./schemas/address";
import { locations_schema, locations_ui_schema } from "./schemas/locations";
// import { primary_person_schema, primary_person_ui_schema } from "./schemas/primaryPerson";
import { team_responsible_schema, team_responsible_ui_schema } from "./schemas/responsible";
// import { primary_person_contact_schema } from "./schemas/primaryPersonContact";
import { team_responsible_contact_schema } from "./schemas/responsibleContact";
import { company_type_schema } from "./schemas/companyType";
import { company_services_schema, company_services_ui_schema } from "./schemas/companyServices";
import { biography_schema, biography_ui_schema } from "./schemas/biography";
import { logo_schema, logo_ui_schema } from "./schemas/logo";
import { work_examples_schema, work_examples_ui_schema } from "./schemas/workExamples";
import { website_schema } from "./schemas/website";
import { some_schema } from "./schemas/some";
import { complete_schema, complete_ui_schema } from "./schemas/complete";

import Form from '../../../../components/Form';

export default class Basics extends React.Component {

  constructor( props ) {

    super( props );

    this.state = {

      // projectFetching: false,
      company: null,
      formConfig: [],
      project: null,
      companyServices: null,
      companyTypes: null,
      countries: null,
      step: 0

    }

  }


  componentDidMount() {
    
    let project;
    if ( this.props.project ) {

      project = this.props.project;

    } else if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.project &&
      this.props.location.state.project ) { 

        project = this.props.location.state.project;

    }

    if ( project ) this.getCompany( project );
    
  }


  componentDidUpdate() {

    if ( this.props.project && !this.state.project ) {

      this.getCompany( this.props.project );
      this.getCountries();
      this.getCompanyServices();
      this.getCompanyTypes();

    }

  }


  getCompany = ( project ) => {

    axios( {

      method: 'GET',
      url: `${process.env.REACT_APP_BACKEND_URL}/partners?id=${project.company.id}`,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + Cookies.get('authToken')
      }

    } )
    .then( response => {

      const company = response.data[ 0 ];

      this.setState( {
        
        formConfig: this.getFormConfig( company ),
        project: project,
        company: company

      } );
      
    } )
    .catch( error => console.log( 'An error occurred:', error.response ) );

  }


  getCountries = () => {

    axios( {

      method: 'GET',
      url: `${process.env.REACT_APP_BACKEND_URL}/content-manager/components/company.city-country`,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + Cookies.get('authToken')
      }

    } )
    .then( response => {

      const countries = response.data.data.component.schema.attributes.country.enum;
      this.setState( {
        
        countries: countries

      } );
      
    } )
    .catch( error => console.log( 'An error occurred:', error.response ) );

  }

  getCompanyServices = () => {

    axios( {

      method: 'GET',
      url: `${process.env.REACT_APP_BACKEND_URL}/company-services`,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + Cookies.get('authToken')
      }

    } )
    .then( response => {


      this.setState( {
        
        companyServices: response.data

      } );
      
    } )
    .catch( error => console.log( 'An error occurred:', error.response ) );
    
  }


  getCompanyTypes = () => {

    axios( {

      method: 'GET',
      url: `${process.env.REACT_APP_BACKEND_URL}/content-manager/content-types/application::partner.partner`,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + Cookies.get('authToken')
      }

    } )
    .then( response => {
      this.setState( { companyTypes: response.data.data.contentType.schema.attributes.company_type.enum } ) })
    .catch( error => console.log( 'An error occurred:', error.response ) );
    
  }


  handleUpdateContent = ( response ) => {

    const { formConfig, project, company, step } = this.state;
    
    if ( step === formConfig.length - 1) {
      console.log("COMPLETE", response);
      const updated_project = response.data;
      this.props.onProjectUpdate( updated_project );
      this.setState( { step: step + 1 } );
      
    } else if ( response.contentType === "partners" || response.contentType === "users" ) {

      const updated_company = { ...company, ...response.data };
      project.company = response.data;
      this.setState( {

        company: updated_company,
        project: project,
        step: step + 1

      } );
  
    } else if ( response.contentType === "projects" ) {

      const updated_project = response.data;;
      updated_project.company = this.state.company;
      this.setState( {

        project: updated_project,
        step: step + 1

      } );
    }

  }


  handleChangeContent = formData => {

    const { formConfig, step } = this.state;
     // If new data us comming in and the new data is an object
    // update data to autofill id field and email field
    if ( formConfig[ step ].data !== formData ) {
      if ( formConfig[ step ].foundation && formConfig[ step ].uiSchema ) {

        const first_key = Object.keys(formConfig[ step ].uiSchema)[ 0 ];
        if ( formData[ first_key ] ) {

          let update_form_data = false;
          if ( Array.isArray( formData[ first_key ] ) ) {

            for ( let i = 0; i < formData[ first_key ].length; i++ ) {
          
              if ( formData[ first_key ][ i ] && formData[ first_key ][ i ].autoComplete ) {
                  
                // Don't need this property any longer, so just delete it
                delete formData[ first_key ][ i ].autoComplete;
                update_form_data = true;
                
              }
              
            }

          } else if ( formData[ first_key ].autoComplete ) {
                  
            // Don't need this property any longer, so just delete it
            delete formData[ first_key ].autoComplete;
            update_form_data = true;
              
          }
          
          if ( update_form_data ) {
            
            formConfig[ step ].data = formData;
            this.setState( { formConfig: formConfig } );

          }

        }

      }

    }

  }


  getFormConfig = ( content ) => {

    const steps = [];

    if ( content ) {

      const { project } = this.props;

      let data;
      
      if ( content.company_legal_name_toggle ) {
        data = { company_legal_name: content.company_legal_name };
        steps.push( { title: "Company legal name", contentType: "partners", schema: legal_name_schema, data: data, content: content } );
      }
      
      
      if ( content.name_toggle ) {
        data = { name: content.name };
        steps.push( { title: "Company profile name", contentType: "partners", schema: company_name_schema, data: data, content: content } );
      }

      if ( content.vat_toggle ) {
        data = { vat: content.vat };
        steps.push( { title: "VAT Registration Number", contentType: "partners", schema: vat_schema, data: data, content: content } );
      }
      
      
      if ( content.address_toggle ) {
        
        data = { address: content.address };
        address_schema.properties.address.properties.city_country.properties.country.enum = this.state.countries;
        steps.push( { title: "List your company legal address", contentType: "partners", schema: address_schema, uiSchema: address_ui_schema, data: data, content: content } );

      }

      if ( content.locations_toggle ) {
        
        data = { locations: content.locations };
        locations_schema.properties.locations.items.properties.country.enum = this.state.countries;
        steps.push( { title: "Select any additional locations", contentType: "partners", schema: locations_schema, uiSchema: locations_ui_schema, data: data, content: content } );

      }

      if ( project.team_responsible_toggle ) {
        
        data = { team_responsible: { username: "", email: "" } };
        if ( project.team_responsible ) {

          data = { team_responsible: {} };
          if ( project.team_responsible.id ) data.team_responsible.id = project.team_responsible.id;
          if ( project.team_responsible.username ) data.team_responsible.username = project.team_responsible.username;
          if ( project.team_responsible.email ) data.team_responsible.email = project.team_responsible.email;

        }

        steps.push( {
          title: "Who’s the primary responsible?", 
          contentType: "projects",
          schema: team_responsible_schema,
          uiSchema: team_responsible_ui_schema,
          data: data,
          content: project,
          foundation: {
            url:`partners?id=${content.id}`,
            array: "team_persons",
            field: "username",
            return: "id"
          },
        } );

        data = { company_role: "", phone: "" };
        if ( project.team_responsible ) {
          
          data = {}
          if ( project.team_responsible.id ) data.id = project.team_responsible.id;
          if ( project.team_responsible.phone ) data.phone = project.team_responsible.phone;
          if ( project.team_responsible.company_role ) data.company_role = project.team_responsible.company_role;

        }

        steps.push( {
          title: "What is your contact information?",
          contentType: "users",
          schema: team_responsible_contact_schema,
          data: data,
          content: project.team_responsible
        } );
        
      }

      if ( content.company_type_toggle ) {
          
        data = { company_type: content.company_type };
        company_type_schema.properties.company_type.enum = this.state.companyTypes;
        steps.push( { title: "Define your company type", contentType: "partners", schema: company_type_schema, data: data, content: content } );

      }

      if ( content.company_services_toggle ) {
          
        data = { company_services: content.company_services };
        company_services_schema.properties.company_services.items.properties.id.enum = this.state.companyServices.map( item => {
          return item.id;
        } );
        company_services_schema.properties.company_services.items.properties.id.enumNames = this.state.companyServices.map( item => {
          return item.title;
        } );
        steps.push( {
          title: "Select your strongest competencies",
          contentType: "partners",
          schema: company_services_schema,
          uiSchema: company_services_ui_schema,
          data: data,
          content: content,
          options: { field: "company_services", options: this.state.companyServices }
        } );

      }

      if ( content.biography_toggle ) {
        data = { biography: content.biography };
        steps.push( { title: "Write a short company biography", contentType: "partners", schema: biography_schema, uiSchema: biography_ui_schema, data: data, content: content } );
      }

      if ( content.logo_toggle ) {
        data = { logo: content.logo };
        steps.push( { title: "Add company logo", contentType: "partners", schema: logo_schema, uiSchema: logo_ui_schema, data: data, content: content } );
      }

      if ( content.work_examples_toggle ) {
        data = { work_examples: content.work_examples };
        steps.push( { title: "Add 3 photos representing your best work", contentType: "partners", schema: work_examples_schema, uiSchema: work_examples_ui_schema, data: data, content: content } );
      }

      if ( content.website_toggle ) {
        data = { website: content.website };
        steps.push( { title: "Company website", contentType: "partners", schema: website_schema, data: data, content: content } );
      }

      if ( content.some_toggle ) {
        data = { some: content.some };
        steps.push( { title: "List social media handles", contentType: "partners", schema: some_schema, data: data, content: content } );
      }

      data = { basics_completed: true };
      steps.push( { title: "That’s all.<br>You are now ready to submit the details to SPACE10.", contentType: "projects", schema: complete_schema, uiSchema: complete_ui_schema, data: data, content: project, submitLabel: "Submit" } );
      
    }

    return steps;

  }


  render() {

    const { formConfig, step } = this.state;

    if ( this.props && this.props.project && formConfig && formConfig.length && step === formConfig.length) {
      
      return <Redirect to={`/projects/${this.props.project.id}/onboarding/overview/`}></Redirect>;

    }

    const form_config = formConfig[ step ];
    let title = form_config && form_config.title ? form_config.title : "";
    const title_element = { __html: title };

    let progress_element = <></>;
    if ( formConfig.length ) {

      const progress_unit = 100 / formConfig.length;
      const progress = progress_unit * step + progress_unit;
      const style = { width: `${progress}%` };
      progress_element = <div className="c-progress" style={style} />;

    }

    return (
      
      <>
        { progress_element}

        <div className="c-page">
          <div className="l-columns-container l-columns-container--outmost">
            <h1 className="c-page__headline c-form__headline" dangerouslySetInnerHTML={title_element}></h1>
          </div>
          
          <Form
            submitLabel="Next"
            cancelLabel="Previous"
            {...form_config}
            onUpdateContent={this.handleUpdateContent}
            onChange={this.handleChangeContent}
          />

        </div>
      </>
    
    );
  }
}