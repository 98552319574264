import React from "react";
import { Redirect } from "react-router-dom";

import { responsible_schema, responsible_ui_schema } from "./schemas/responsible";
import Form from "../../../../components/Form";

import "../create_project.css";


export default class CreateProjectResponsible extends React.Component {
  

  constructor( props ) {

    super( props );

    this.state = {

      completed: false,
      data: undefined,

    };

  }


  componentDidUpdate() {

    if ( !this.state.data && this.props.project && this.props.project.team_responsible ) {
      
      this.setState( {
        
        forceUpdate: true,
        data: {
          team_responsible: {
            id: this.props.project.team_responsible.id,
            username: this.props.project.team_responsible.username,
            email: this.props.project.team_responsible.email,
          }
        },

      } );
    
    }

  }

  
  handleChange = ( formData ) => {
    
    // If new data us comming in and the new data is an object
    // update data to autofill email field
    if ( this.state.data !== formData && typeof formData["team_responsible"] === "object" ) {
      this.setState( { data: formData, forceUpdate: true } );
    }

  }


  handleSubmit = ( formData ) => {
    
    this.props.projectUpdated( formData.data );
    this.setState( { completed: true } );

  }


  render() {
    
    const { completed, data, forceUpdate } = this.state;

    if ( completed ) {

      sessionStorage.removeItem( 'projectId' );
      return <Redirect to={`/projects/${this.props.project.id}`} />
      
    }

    let foundation = {};
    if ( this.props.project && this.props.project.company ) {

      foundation = {
        url: `partners?id=${this.props.project.company.id}`,
        array: "team_persons",
        field: "username",
        return: "id"
      };

    }


    return (
      <div className="c-create-project c-page">

        <div className="c-create-project__header l-columns-container l-columns-container--outmost">
          <h1 className="c-create-project__title">Add project responsible</h1>
        </div>

        <div className="c-create-project__main l-columns-container--outmost">
          
          <Form
            className="c-form"
            {...this.props}
            noValidate={true}
            schema={ responsible_schema }
            uiSchema={ responsible_ui_schema }
            content={this.props.project}
            contentType="projects"
            foundation={foundation}
            data={ data ? data : {} }
            forceUpdate={forceUpdate}
            onChange={this.handleChange}
            onUpdateContent={this.handleSubmit}
            submitLabel="Next"
          >
          </Form>

        </div>

      </div>
    );

  }

}