import React from "react";
import axios from 'axios';
import Cookies from 'js-cookie';

import Toggle from "../Toggle";
import Checkmark from "../../utils/icons/checkmark.js";
import ProjectSubLineItem from "./ProjectSubLineItem";

import "./project_line_item.css";

export default class ProjectLineItem extends React.Component {


  constructor( props ) {

    super( props );

    const schema = props.schema;
    const top_actions = this.props.actions.filter( item => !item.bottom );

    this.state = {

      completed: false,
      schema,
      topActions: top_actions

    }

  }


  componentDidMount() {

    if ( this.props.schema && this.state.schema !== this.props.schema ) {
      this.setupFields();
      this.setState( { schema: this.props.schema } );
    }

    if ( this.props.schema && !this.state.subLineItems) {
      this.setupFields();
    }

  }


  componentDidUpdate() {

    if ( this.props.schema && this.state.schema !== this.props.schema ) {
      this.setupFields();
      this.setState( { schema: this.props.schema } );
    }

    return true;

  }


  static getDerivedStateFromProps( props, state ) {

    if ( props.completed !== state.completed ) return { completed: props.completed };

    return null;

  }


  setupFields = ( toggled ) => {

    if ( this.props.schema ) {

      const { completed } = this.state;
      
      const sub_line_items = this.props.schema.map( ( schema, i ) => {
        
        if ( completed ) {

          return (
            <ProjectSubLineItem key={`field${i}`}
              toggleEditingField={ () => this.props.onEditToggled( schema, completed ) }
              onUpdateContent={this.props.onUpdateContent}
              completed={true}
              {...schema} />
          );

        }

        if ( toggled ) {

          return (
            <ProjectSubLineItem key={`field${i}`}
              toggleEditingField={ () => this.props.onEditToggled( schema, completed ) }
              onUpdateContent={this.props.onUpdateContent}
              toggled
              {...schema} />
          );

        }

        return (
          <ProjectSubLineItem key={`field${i}`}
            toggleEditingField={ () => this.props.onEditToggled( schema, completed ) }
            onUpdateContent={this.props.onUpdateContent}
            {...schema} />
        );

      } );

      this.setState( { subLineItems: sub_line_items } );

    }

  }


  handleToggleChange = ( toggled ) => {

    this.props.onToggleChange( toggled );

    const data = {};
    const field = ( this.props.toggleField ) ? this.props.toggleField : `${this.props.title.toLowerCase().replace(" ", "")}_toggle`;
    data[field] = toggled;

    // If toggle is true: toggle all subLineItems
    if ( toggled ) {
      this.setupFields( true );
    }
    
    axios( {

      method: 'PUT',
      url: `${process.env.REACT_APP_BACKEND_URL}/projects/${this.props.projectId}`,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + Cookies.get('authToken')
      },
      data: data

    } )
    .then( response => console.log(response) )
    .catch( error => console.log(error) );

  }


  handleTopActionClicked = ( i, action, params ) => {

    if ( action.idleOnClick ) {
      
      const { topActions } = this.state;
      
      topActions[ i ].title = "Sending...";
      this.setState( { topActions: topActions } );

      setTimeout( () => {

        const top_actions_unmodified = this.props.actions.filter( item => !item.bottom );
        topActions[ i ].title = top_actions_unmodified[ i ].title;
        console.log(topActions[i]);
        this.setState( { topActions: topActions } );

      }, 2000 );

    }

    action.handler(params);

  }


  topActions = () => {

    if ( !this.props.toggled || this.props.completed ) return <></>;
    if ( !this.props.actions || this.props.actions.length === 0) return <></>;

    const  { topActions } = this.state;
    return topActions.map( ( action, i ) => {
      
      const params = action.params ? action.params : [];
      return <a
        href="#0"
        className={`c-project-line-item__action c-project-line-item__action--${action.style}`}
        onClick={ () => this.handleTopActionClicked( i, action, params ) }
        key={`action-${action.title}`}
        >{action.title}</a>;
    } );

  }


  bottomActions = () => {

    if ( !this.props.toggled || this.props.completed ) return <></>;
    if ( !this.props.actions || this.props.actions.length === 0) return <></>;
    const actions = this.props.actions.filter( item => item.bottom );
    const action_elements = actions.map( action => {
      
      const params = action.params ? action.params : [];
      return <a
        href="#0"
        className={`c-project-line-item__action c-project-line-item__action--${action.style}`}
        onClick={() => action.handler(params)}
        key={`action-${action.title}`}
        >{action.title}</a>;
    } );

    if ( action_elements && action_elements.length ) {
  
      return (
        <div className="l-columns-container l-columns-container--outmost">
          <div className="c-project-line-item__actions c-project-line-item__actions--bottom">
            {action_elements}
          </div>
        </div>
      );
      
    }

    return <></>

  }


  checkmark = () => {

    if ( this.props.completed ) {
      
      return (

        <div className="c-project-line-item__checkmark">
          <Checkmark />
        </div>

      );

    }

    return <React.Fragment></React.Fragment>;

  }


  handleToggleEditField = () => {

    if ( this.props.onEditToggled ) this.props.onEditToggled();

    // { ( context ) => context.toggleEditingField() }

  }


  handleTitleClicked = ( event ) => {

    if ( event ) event.preventDefault();
    if ( this.props.onOpenToggled ) this.props.onOpenToggled();
    return false;

  }


  fields = () => {
    
    const { subLineItems } = this.state;
    if ( subLineItems ) {

      return (
        
        <div>
          <div className="l-columns-container l-columns-container--outmost">
            <div className="c-project-line-item__line"></div>
          </div>
        
          <div className="c-project-line-item__subline-line-items l-columns-container--outmost">
            {subLineItems}
          </div>
        </div>

      );

    }

    return <></>;

  }


  render() {

    const inherited_className = this.props.className ? this.props.className : "";
    let title = <></>;

    if ( this.props.onOpenToggled ) {
      title = <a href="#0" onClick={this.handleTitleClicked} className="c-project-line-item__title c-project-line-item__title--normal">{this.props.title}</a>;
    } else {
      title = <p className="c-project-line-item__title c-project-line-item__title--normal">{this.props.title}</p>;
    }
    
    return (
      <div className={`c-project-line-item ${inherited_className}`}>
        <div className="l-columns-container--outmost">
          <div className="c-project-line-item__inner l-columns-container">

            {title}
            
            <Toggle
              className="c-project-line-item__toggle"
              onToggleChange={this.handleToggleChange}
              size="normal"
              toggled={this.props.toggled} />

            <div className="c-project-line-item__actions c-project-line-item__actions--top">
              {this.topActions()}
            </div>

            {this.checkmark()}

            <p className="c-project-line-item__number">{this.props.number}</p>

          </div>
        </div>

        {this.fields()}

        {this.bottomActions()}

      </div>
    );

  }

}