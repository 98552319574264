export const delivery_documents_schema = {
  type: "object",
  // title: "Delivery documents",
  properties: {
    // delivery_documents: {
    //   type: "array",
    //   items: {
    //     type: "string"
    //   }
    // }
    delivery_documents_link_new: {
      title: "",
      type: "object",
      properties: {
        title: { type: "string", default: "", title: "Title" },
        link_title: { type: "string", default: "", title: "Link title" },
        link_subtitle: { type: "string", default: "", title: "Subtitle" },
        link: { type: "string", default: "", title: "Link" },
        visual: {
          title: "Visual",
          type: ["string"],
        }
      }
    }
  }
};

export const delivery_documents_ui_schema = {
  delivery_documents_link_new: {
    // "ui:options":  {
    //   orderable: false
    // },
    // items: {
    //   "ui:widget": "file"
    // }
    "ui:displayLabel": false,
    visual: {
      "ui:widget": "file"
    }

  }
}