export const rating_schema = {
  type: "object",
  properties: {
    rating: {
      title: "",
      description: "Please rate the question from 1 (unsatisfying) — 5 (excellent) ",
      type: "number",
      enum: [1,2,3,4,5],
    }
  }
};

export const rating_ui_schema = {
  rating: {
    "ui:options": { label: false },
    "ui:widget": "RatingWidget"
  }
}
