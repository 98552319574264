export const title_schema = {
  type: "object",
  properties: {
    title: {
      type: "string",
      default: "",
      title: "Project title",
      required: true,
    },
    project_type: {
      type: "object",
      title: "",
      properties: {
        id: {
          title: "Project type",
          type: "number",
          enum: [],
          enumNames: [],
          required: true,
        }
      },
      required: [ "id" ]
    }
  },
  required: [ "title" ]
};