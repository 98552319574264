export const address_schema = {
  type: "object",
  properties: {
    address: {
      type: "object",
      title: "",
      properties: {
        street_name_and_number: {
          type: "string",
          default: "",
          title: "Enter street name and number"
        },
        zip: {
          type: "string",
          default: "",
          title: "Zip",
        },
        city_country: {
          type: "object",
          title: "",
          properties: {
            city: {
              type: "string",
              default: "",
              title: "City"
            },
            country: {
              title: "Choose country",
              type: "string",
              enum: [],
            },
          }
        }
      }
    }
  }
};

export const address_ui_schema = {
  address: {
    street_name_and_number: {},
    zip: {
      classNames: "c-input__zip"
    },
    city_country: {
      city: {},
      country: {}
    }
  }
}