import React from 'react';
import { Link } from 'react-router-dom';
import IconArrow from '../../../../components/Icons/IconArrow';
import Checkmark from '../../../../utils/icons/checkmark'
import "./overview.css";

export default class Overview extends React.Component {

  constructor( props ) {

    super( props );

    this.state = {

      project: null

    }

  }


  componentDidMount() {
    
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.project &&
      this.props.location.state.project ) { 
      
      this.setState( { project: this.props.location.state.project } );

    }
    
  }

  
  getSteps = () => {

    const { project } = this.props;
    if ( project ) {

      let steps = [

        { id: "basics", title: "Basics", text: "Setup your company by adding the basics.", textCompleted: "Your company information has been submitted." },
        { id: "nda", title: "NDA", text: "" },
        { id: "brief", title: "Brief", text: "Introduction to the brief and working with SPACE10.", textCompleted: "Review completed. We’ll get back soon."},
        { id: "contract", title: "Contract", text: "" },
        { id: "details", title: "Details", text: "Review and enter project related details.", textCompleted: "You are now all setup for the project to start." },
        { id: "delivery", title: "Delivery", text: "Structure and deliver your project sucessfully.", textCompleted: "Your project delivery has been sent." },
        { id: "evaluation", title: "Evaluation", text: "Evaluate and review your project for future collaborations.", textCompleted: "Evaluation completed. We appreciate your input." }

      ];

      steps = steps.filter( step => project[ `${step.id}_toggle` ] );

      let last_completed;
      let last_completed_text = <></>;
      let show_last_completed_modifier = "";
      const completed = steps.map( ( step, i ) => {
        
        if ( project[`${step.id}_toggle`] && project[`${step.id}_completed` ] ) {
          
          last_completed = i;
          return step;

        }

        return null;

      } );

      let i = 0;
      const elements = steps.map( ( step, j ) => {

        last_completed_text = <></>;
        show_last_completed_modifier = "";
        
        if ( project[`${step.id}_toggle`] ) {
          i++;
          
          if  ( project[`${step.id}_completed`] ) {
            
            if ( i-1 === last_completed ) {
              
              if ( completed && completed.length ) {
                // Check if theres a step after the last completed
                const next_step = steps[ last_completed + 1];
                if ( next_step ) {
                  
                  // If next step has sent email but not completed, don't show last completed step in expanded state
                  if ( !project[`${next_step.id}_email_sent`] && !project[`${next_step.id}_completed`] ) {
                    show_last_completed_modifier = "s-active";
                    last_completed_text = (
                      <div className="l-columns-container l-columns-container--outmost">
                        <p className="c-onboarding-overview__text">{steps[last_completed].textCompleted}</p>
                      </div>
                    );
                  }

                }

              }
              
            }

            return (
              <div key={step.id} className={`c-onboarding-overview__list-item ${show_last_completed_modifier}`}>
                <div className="l-columns-container l-columns-container--outmost">

                  <h3 className="c-onboarding-overview__headline">{step.title}</h3>
                  <div className="c-onboarding-overview__right">
                    <div className="c-onboarding-overview__icon c-onboarding-overview__icon--checkmark">
                      <Checkmark></Checkmark>
                    </div>
                    <p className="c-onboarding-overview__number">{i}</p>
                  </div>

                </div>

                {last_completed_text}

              </div>
            );

          }
          
          if ( project[`${step.id}_email_sent`] && !project[`${step.id}_completed`]) {

            return (
              <Link to={`/projects/${project.id}/onboarding/${step.id}`} key={step.id} className="c-onboarding-overview__list-item c-onboarding-overview__list-item--link">
                
                <div className="l-columns-container l-columns-container--outmost">

                  <h3 className="c-onboarding-overview__headline">{step.title}</h3>
                  <div className="c-onboarding-overview__right">
                    <div className="c-onboarding-overview__icon c-onboarding-overview__icon--arrow">
                      <IconArrow direction="right" />
                    </div>
                    <p className="c-onboarding-overview__number">{i}</p>
                  </div>

                </div>

                <div className="l-columns-container l-columns-container--outmost">
                  <p className="c-onboarding-overview__text">{step.text}</p>
                </div>

              </Link>
            );

          }

          return (
            <div key={step.id} className="c-onboarding-overview__list-item">
              <div className="l-columns-container l-columns-container--outmost">

                <h3 className="c-onboarding-overview__headline">{step.title}</h3>
                <div className="c-onboarding-overview__right">
                  <p className="c-onboarding-overview__number">{i}</p>
                </div>

              </div>
            </div>
          );
        }

        return <React.Fragment key={`overview_${j}`} />;
        
      } );

      return elements;

    }

    return <></>;

  }


  render() {

    return (
      <div className="c-page c-onboarding-overview">

        <ul className="c-onboarding-overview__list">
          {this.getSteps()}
        </ul>

      </div>
    );
  }
}