export const address_schema = {
  type: "object",
  properties: {
    address: {
      type: "object",
      title: "",
      properties: {
        street_name_and_number: {
          type: "string",
          default: "",
          title: "Street name and number"
        },
        zip: {
          type: "string",
          default: "",
          title: "zip"
        },
        city_country: {
          type: "object",
          title: "",
          properties: {
            city: {
              type: "string",
              default: "",
              title: "City"
            },
            country: {
              type: "string",
              default: "",
              title: "Country"
            },
          }
        }
      }
    }
  }
};