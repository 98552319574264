import React from "react";

import Tag from '../../components/Tag';

import "./search_form.css";

export default class SearchForm extends React.Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: "",
      queries: []

    };

  }


  handleSearchInputChange = event => this.setState( { value: event.target.value } );

  handleSearchInputKeyDown = event => {

    if ( event.key === "Backspace" || event.keyCode === 8 ) {

      const { queries, value } = this.state;
      if ( value === "" && queries.length ) {

        const last_query = queries.pop();
        this.removeQuery( last_query );

      }

    }

  }


  handleSubmit = ( event ) => {

    if ( event ) event.preventDefault();
    
    if ( this.state.value !== "" ) {
      
      const { queries } = this.state;
      queries.push( this.state.value );
      this.setState( { queries: queries, value: "" } );
      this.props.updateQuery( this.state.queries );

    }

    return false;

  }

  removeQuery = ( remove ) => {

    const { queries } = this.state;
    const updated_queries = queries.filter( query => query !== remove );
    this.setState( { queries: updated_queries } );

    if ( this.props.updateQuery ) this.props.updateQuery( updated_queries );

  }

  render() {

    const { queries } = this.state;
    let tags = <></>;
    if ( queries && queries.length ) {
      tags = queries.map( ( query, i )=> {
        return <Tag key={`query_${i}`} title={query} onRemove={this.removeQuery} removeable />
      })
    }

    return (
      <form className="c-search-form" onSubmit={this.handleSubmit}>

        {tags}

        <input
          className="c-search-form__input"
          type="text"
          name="search"
          placeholder="Search"
          value={this.state.value}
          onChange={this.handleSearchInputChange}
          onKeyDown={this.handleSearchInputKeyDown}
        />  

      </form>
    );

  }

}
