export const team_responsible_schema = {
  type: "object",
  properties: {
    team_responsible: {
      title: "",
      properties: {
        id: {
          type: "number",
        },
        username: {
          type: "string",
          title: "Name" 
        },
        email: {
          type: "string",
          title: "Email" 
        }
      }
    },
  }
};

export const team_responsible_ui_schema = {
  team_responsible: {
    "ui:options": {
      label: false
    },
    id: {
      "ui:widget": "hidden",
      "ui:options": {
        label: false
      }
    },
    username: {
      "ui:widget": "AutoCompleteWidget"//   "ui:widget": ""
    }
  }
}