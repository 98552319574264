import React from "react";
import axios from 'axios';

import { LoginForm } from "../../components/LoginForm";
import Image from '../../components/Image';

import "./Login.css";

export default class Login extends React.Component {

  constructor( props ) {

    super( props );

    this.state = {

      welcomeText: '',
      backgroundImages: []

    };

  }


  componentDidMount() {
    
    axios( {
      method: 'GET',
      url: `${process.env.REACT_APP_BACKEND_URL}/login`,
    } )
    .then(response => {

      // Handle success.
      const data = response.data;
      this.setState( {
        
        welcomeText: data.welcome_text,
        backgroundImages: data.background_images

       } );
      
    })
    .catch(error => {
      // Handle error.
      console.log('An error occurred:', error.response);

    } );
    
  }


  randomBackgroundImage() {

    if ( this.state.backgroundImages && this.state.backgroundImages.length ) {
    
      const random_i = Math.floor( Math.random() * this.state.backgroundImages.length );
      const background_image = this.state.backgroundImages[ random_i ];

      if ( background_image ) {
        
        return (
          <figure className="c-login__background-image__container">
            
            <Image image={background_image} className="c-login__background-image" />
            
            <figcaption className="c-login__background-image__caption">
              <p className="c-login__background-image__caption-text">{background_image.caption}</p>
            </figcaption>

          </figure>
        );

      }
      
    }

    return (
      <div></div>
    );

  }

  render() {

    return (

      <div className="c-login l-columns-container l-columns-container--outmost">

        <LoginForm {...this.props} text={this.state.welcomeText}/>

        <div className="c-login__background">
          {this.randomBackgroundImage()}
        </div>

      </div>

    );

  }

}