export const work_examples_schema = {
  type: "object",
  properties: {
    work_examples: {
      type: "array",
      items: {
        type: ["string"],
        title: "Upload photos (.jpg or .png)",
      }
    }
  }
};

export const work_examples_ui_schema = {
  work_examples: {
    items: {
      "ui:description": `Please use simple iconic photography, no black and white photos or logos on a plain background (<a href="#0">view examples</a>)`,
      "ui:widget": "file",
    }
  }
}