import React from "react";
import IconCross from "../Icons/IconCross";

import "./error.css";

export default class Error extends React.Component {

  constructor( props ) {

    super( props );

    this.state = {

      dismissed: true,
      messages: null,
      style: ""

    }

  }


  static getDerivedStateFromProps( props, state ) {

    if ( props.messages !== state.messages ) {

      return {
        dismissed: props.isShowingError,
        messages: props.messages,
        style: props.errorStyle
      };

    }

    return null;

  }

  

  handleDismissClicked = () => {

    this.setState( { dismissed: true } );

  }

  render() {

    const { dismissed, messages, style } = this.state;
    const messages_element = messages.map( ( message, i ) => {
      return <p key={`error-${i}`} className="f-error">{ message }</p>;
    } );

    const dismissed_modifier = dismissed ? 's-dismissed' : '';
    
    return (
      <div className={`c-error ${dismissed_modifier} ${style} l-columns-container l-columns-container--outmost`}>
        <div className="c-error__inner">
                
          {messages_element}

          <a href="#0" className="c-error__dismiss" onClick={this.handleDismissClicked}>
            <IconCross deleteIcon />
          </a>

        </div>
      </div>
    );

  }

}