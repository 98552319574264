export const delivery_credits_schema = {
  type: "object",
  properties: {
    delivery_credits: {
      type: "array",
      title: "",
      buttonTopSpacing: true,
      items: {
        type: "object",
        properties: {
          id: {
            type: "number",
            hidden: true
          },
          username: {
            type: "string",
            default: "",
            title: "Name" 
          },
          email: {
            type: "string",
            title: "Email" 
          }
        }
      }
    }
  }
};

export const delivery_credits_ui_schema = {
  delivery_credits: {
    "ui:options":  {
      orderable: false,
      label: false
    },
    items: {
      id: {
        "ui:options": {
          widget: "hidden"
        }
      },
      username: {
        "ui:widget": "AutoCompleteWidget"
      }
    }
  }
}