import React from "react";

export default class NoMatch extends React.Component {
  render() {
    return (
      <div>
        <h1>404</h1>
      </div>
    );
  }
}