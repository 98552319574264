export const bio_schema = {
  type: "object",
  properties: {
    biography: {
      type: "string",
      default: "",
      title: "Bio"
    },
  }
};

export const bio_ui_schema = {
  biography: {
    "ui:widget": "TextAreaWidget"
  }
}