export const some_schema = {
  type: "object",
  properties: {
    facebook: {
      type: "string",
      default: "",
      title: "Facebook"
    },
    instagram: {
      type: "string",
      default: "",
      title: "Instagram"
    },
    linkedin: {
      type: "string",
      default: "",
      title: "LinkedIn"
    },
    twitter: {
      type: "string",
      default: "",
      title: "Twitter"
    }
  }
};