import React from "react";

export default class Dev extends React.Component {

  constructor( props ) {
    
    super( props );

    this.state = {

      active: false,
      ctrlPressed: false

    };

  }


  componentDidMount() {

    document.addEventListener("keydown", ( event ) => {

      if ( event.key === "Control" ) this.setState( { ctrlPressed: true } );

    } );
    
    document.addEventListener("keyup", ( event ) => {

      if ( event.key === "Control" ) this.setState( { ctrlPressed: false } );
      if ( event.key === 'l' && this.state.ctrlPressed ) this.setState( { active: !this.state.active } );

    } );

  }

  render() {
    return (
      <div className={`c-dev ${this.state.active ? 's-active' : ''}`}>

        <div className="c-dev__guides">
          <div className="c-dev__columns-container l-columns-container">
            <div className="c-dev__column l-columns--1"></div>
            <div className="c-dev__column l-columns--1"></div>
            <div className="c-dev__column l-columns--1"></div>
            <div className="c-dev__column l-columns--1"></div>
            <div className="c-dev__column l-columns--1"></div>
            <div className="c-dev__column l-columns--1"></div>
            <div className="c-dev__column l-columns--1"></div>
            <div className="c-dev__column l-columns--1"></div>
            <div className="c-dev__column l-columns--1"></div>
            <div className="c-dev__column l-columns--1"></div>
            <div className="c-dev__column l-columns--1"></div>
            <div className="c-dev__column l-columns--1"></div>
          </div>
        </div>

      </div>
    );
  }
}