import React from "react";
import axios from 'axios';
import { Switch, Route } from "react-router-dom";
import Cookies from 'js-cookie';

import CreateProjectTitle from "./Title";
import CreateProjectCompany from "./Company";
import CreateProjectResponsible from "./Responsible";

export default class CreateProject extends React.Component {
  
  constructor( props ) {

    super( props );

    this.state = {

      project: null,
      step: 0

    }

  }


  componentDidMount() {

    // See if there's a project in session storrage
    // Used if user has refreshed page during project creation flow
    const project_id = sessionStorage.getItem( 'projectId' );
    if ( project_id ) {

      axios( {
        method: 'GET',
        withCredentials: true,
        url: `${process.env.REACT_APP_BACKEND_URL}/projects?id=${project_id}`,
        headers: {
          Authorization: "Bearer " + Cookies.get('authToken')
        }
      } )
      .then( response => this.setState( { project: response.data[ 0 ] } ) )
      .catch(error => console.log(error) );

    }

  }

  decreaseStep = ( step ) => {

    const new_step = ( step ) ? step : this.state.step - 1;
    this.setState( { step: new_step } );

  }


  handleProjectUpdated = project => {

    sessionStorage.setItem( 'projectId', project.id );
    this.setState( { project: project } );

  }


  render() {
  
    return (
      
      <Switch>
            
        <Route path="/create-project/title" exact>
          <CreateProjectTitle project={this.state.project} projectUpdated={this.handleProjectUpdated} user={this.props.user} />
        </Route>
        
        <Route path="/create-project/company" exact>
          <CreateProjectCompany project={this.state.project} projectUpdated={this.handleProjectUpdated} />
        </Route>

        <Route path="/create-project/responsible" exact>
          <CreateProjectResponsible project={this.state.project} decreaseStep={this.decreaseStep} projectUpdated={this.handleProjectUpdated} />
        </Route>

      </Switch>
    );

  }

};