export const basics_schema = {
  type: "object",
  properties: {
    title: {
      type: "string",
      default: "",
      title: "Title"
    },
    project_managers: {
      type: "array",
      title: "",
      items: {
        type: "object",
        properties: {
          id: {
            type: "number",
            hidden: true
          },
          username: {
            type: "string",
            default: "",
            title: "Name" 
          }
        }
      }
    }
  }
};

export const basics_ui_schema = {
  project_managers: {
    "ui:options":  {
      orderable: false
    },
    items: {
      id: {
        "ui:options": {
          widget: "hidden",
        }
      },
      username: {
        "ui:widget": "AutoCompleteWidget"
      }
    }
  }
}