export const team_schema = {
  type: "object",
  properties: {
    team: {
      title: "",
      type: "array",
      items: {
        type: "object",
        properties: {
          id: {
            type: "number",
          },
          username: {
            type: "string",
            default: "",
            title: "Name" 
          },
          email: {
            type: "string",
            title: "Email" 
          }
        }
      }
    }
  }
};

export const team_ui_schema = {
  team: {
    "ui:options":  {
      orderable: false
    },
    items: {
      id: {
        "ui:widget": "hidden",
        "ui:options": {
          label: false
        }
      },
      username: {
        "ui:widget": "AutoCompleteWidget"
      }
    }
  }
}