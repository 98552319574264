import React from "react";
import { Redirect } from "react-router-dom";

// import Engine from "./engine";
// import Onboarding from "./onboarding";

import "./project.css";


export default class Project extends React.Component {
  

  constructor( props ) {

    super( props );

    this.state = {

      id: null

    };

  }
  
  componentDidMount() {
    
    this.ifMatchParamsReady();
    
  }


  componentDidUpdate() {

    this.ifMatchParamsReady();

  }


  ifMatchParamsReady() {
    
    if ( !this.state.id && this.props.match && this.props.match.params && this.props.match.params && this.props.match.params.id ) {
      
      this.setState( { id: this.props.match.params.id } );

    }
    
  }


  render() {
    
    const { id } = this.state;
    if ( this.props.user && id ) {

      if ( this.props.user.role.name === "Project manager" ) {
        
        return <Redirect to={`/projects/${id}/engine`}></Redirect>

      }

      return <Redirect to={`/projects/${id}/onboarding/welcome`}></Redirect>

    }

    return <></>;

  }

};