import React from "react";

const Checkmark = () => {
  
    return (

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 36">
        <g fill="none" fillRule="evenodd" strokeLinecap="square">
          <g stroke="#ffffff" strokeWidth="2" className="c-checkmark__stroke">
            <path d="M1.5 17.1l16.74 17.4 27.26-33"/>
          </g>
        </g>
      </svg>

    );

}

export default Checkmark;