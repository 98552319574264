import React from "react";
import { Link } from "react-router-dom";

// import IconArrow from "../Icons/IconArrow";
import Checkmark from "../../utils/icons/checkmark.js";

import "./project_teaser.css";

const ProjectTeaser = ( props ) => {

  let categories_string = <></>;
  if ( props.project_type && props.project_type.length ) {
    
    const categories = props.project_type.map( category => category.title );
    if ( categories && categories.length )categories_string = <p className="c-project-teaser__categories">{categories.join( ", " )}</p>;

  }

  const steps = ["basics", "nda", "brief", "contract", "details", "delivery", "evaluation"];
  const step_titles = ["Basics", "NDA", "Brief", "Contract", "Details", "Delivery", "Evaluation"];
  const applied_step_titles = [];
  const applied_steps = steps.filter( (step, i ) => {

    if ( props[`${step}_toggle`] ) {

      applied_step_titles.push(step_titles[i]);
      return props[`${step}_toggle`];

    }
    
    return null;

  } );

  const completed_steps_titles = [];
  const completed_steps = applied_steps.filter( ( step, i ) => {

    if ( props[`${step}_completed`] ) {

      completed_steps_titles.push( applied_step_titles[ i ] );
      return step;

    }

    return null;

  } );

  let next_step;
  let next_step_element = <></>;
  let next_step_text;
  let next_step_number;
  if ( applied_steps.length && applied_steps.length === completed_steps.length ) {

    next_step_text = (
      <div className="c-project-teaser__next-step">
        <p className="c-project-teaser__next-step__text">Project is completed</p>
      </div>
    );

  } else {

    next_step = applied_steps[ completed_steps.length ];
    next_step_text = ( next_step ) ? next_step : 'Project is being created';
    next_step_number = ( next_step ) ? completed_steps.length + 1 : '';
    let next_step_notification = <></>;
    if ( !props[ `${applied_steps[completed_steps.length]}_email_sent`] ) {
      next_step_notification = <div className="c-project-teaser__step__notification"></div>;
    }

    if ( next_step ) {

      const open_step = {}
      open_step[ `${next_step}Open` ] = true;
      const path = `/projects/${props.id}/engine/`
      
      next_step_element = (
        <Link
          key={`next_step_link`}
          to={{
            pathname: path,
            state: open_step
          }}
          className="c-project-teaser__step c-project-teaser__step--next c-project-teaser__line">
          
          <p className="c-project-teaser__step__title">{next_step_text}</p>
          {next_step_notification}
          <p className="c-project-teaser__step__number">{next_step_number}</p>

        </Link>
      );
    }

    // next_step_text = (
    //   <div className="c-project-teaser__next-step">
    //     <p className="c-project-teaser__next-step__text">{next_step_text}</p>
    //     <p className="c-project-teaser__next-step__number">{next_step_number}</p>
    //   </div>
    // );

  }

  const completed_steps_elements = completed_steps.map( (step, i) => {
    
    const open_step = {}
    open_step[ `${step}Open` ] = true;
    const path = `/projects/${props.id}/engine/`;

    return (
      <Link
        key={`${props.id}_${step}`}
        to={{
          pathname: path,
          state: open_step
        }}
        className="c-project-teaser__step c-project-teaser__line">
        
        <p className="c-project-teaser__step__title">{completed_steps_titles[ i ]}</p>
        <div className="c-project-teaser__step__checkmark">
          <Checkmark />
        </div>
        <p className="c-project-teaser__step__number">{i+1}</p>

      </Link>
    );
  } );

  const project_managers = props.project_managers;
  let project_manager_initials = <></>;
  if ( project_managers && project_managers.length ) {

    const project_manager_name = project_managers[0].username.replace("_", " ");
    const project_manager_initials_string = project_manager_name.match(/\b(\w)/g).join("").substring(0, 2);
    project_manager_initials = <p className="c-project-teaser__project-manager">{project_manager_initials_string}</p>
    
  }

  // let partner = "";
  // if ( props.company ) partner = props.company.name;
  
  return (
    <div className="c-project-teaser">
      
      <Link to={`/projects/${props.id}`} className="c-project-teaser__line c-project-teaser__line--main">
        
          <p className="c-project-teaser__headline">{props.title}</p>
          {categories_string}
          {project_manager_initials}
          {/* <p>{partner}</p> */}
          {/* {next_step_text} */}

      </Link>
      
      {completed_steps_elements}
      {next_step_element}

    </div>
  );

}

export default ProjectTeaser;