export const logo_schema = {
  type: "object",
  properties: {
    logo: { type: ["string"], title: "Logo" }
  }
};

export const logo_ui_schema = {
  logo: {
    "ui:widget": "file",
  }
}