import React from 'react';
import { Link } from 'react-router-dom';

import "./welcome.css";

export default class Welcome extends React.Component {

  constructor( props ) {

    super( props );

    this.state = {

      project: null

    }

  }


  componentDidMount() {
    
    if ( this.props.location && this.props.location.state.project && this.props.location.state.project ) { 
      
      this.setState( { project: this.props.location.state.project } );

    } else {

      // this.ifMatchParamsReady();

    }
    
  }


  // componentDidUpdate() {

  //   if ( this.state.project ) this.ifMatchParamsReady();    

  // }
  
  
  // ifMatchParamsReady() {
    
  //   if ( !this.state.projectFetching && !this.state.project && this.props.match && this.props.match.params && this.props.match.params ) this.getProject();
    
  // }


  // getProject() {

  //   this.setState( { projectFetching: true } );

  //   axios( {

  //     method: 'GET',
  //     url: `${process.env.REACT_APP_BACKEND_URL}/projects/${this.props.match.params.id}`,
  //     withCredentials: true,
  //     headers: {
  //       Authorization: "Bearer " + Cookies.get('authToken')
  //     }

  //   } )
  //   .then( response => {

  //     this.setState( {
        
  //       project: response.data,

  //     } );
      
  //   } )
  //   .catch( error => console.log( 'An error occurred:', error.response ) );

  // }


  render() {

    const { project } = this.props;
    const id = ( project ) ? project.id : '';
    const company = ( project ) ? project.company[ 0 ] : '';
    const name = ( company ) ? company.name : '';

    return (
      <div className="c-page c-welcome">

        <div className="l-columns-container l-columns-container--outmost">
          <h1 className="c-page__headline c-form__headline">Hi, {name}</h1>
        </div>

        <div className="c-welcome__text-container l-columns-container l-columns-container--outmost">
          <p className="c-welcome__text">Welcome to the Space10 Network</p>
        </div>

        <div className="c-form">
        
          <div className="c-form__bottom">
                
            <Link to={`/projects/${id}/onboarding/overview`} className="c-form__submit c-form__submit--left">Get startet</Link>

          </div>
        </div>

      </div>
    );
  }
}