//http://localhost:3000/auth/email-confirmation?confirmation=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MDA0NDE4NTgsImV4cCI6MTYwMzAzMzg1OH0.jF8K7dWZTzKDnetTFT3ksDG2g1xCk8h6pIg7dv1AwoE

import React from "react";
import axios from 'axios';
import Cookies from 'js-cookie';

import Image from '../../components/Image';

import "./auth.css";

export default class Auth extends React.Component {

  constructor( props ) {

    super( props );

    this.state = {

      welcomeText: '',
      backgroundImage: <></>,
      password: '',
      repeatPassword: '',

    };

  }


  componentDidMount() {
    
    axios( {
      method: 'GET',
      url: `${process.env.REACT_APP_BACKEND_URL}/login`,
    } )
    .then(response => {

      // Handle success.
      const data = response.data;

      let image = <></>;
      if ( data.background_images && data.background_images.length ) {
    
        const random_i = Math.floor( Math.random() * data.background_images.length );
        const background_image = data.background_images[ random_i ];
  
        if ( background_image ) {
          
          image = (
            <figure className="c-login__background-image__container">
              
              <Image image={background_image} className="c-login__background-image" />
              
              <figcaption className="c-login__background-image__caption">
                <p className="c-login__background-image__caption-text">{background_image.caption}</p>
              </figcaption>
  
            </figure>
          );
  
        }
        
      }

      this.setState( {
        
        welcomeText: data.welcome_text,
        backgroundImage: image

       } );
      
    })
    .catch(error => {
      // Handle error.
      console.log('An error occurred:', error.response);
      return (
        <div>Error</div>
      );
    } );
    
  }


  handlePasswordChange = ( event ) => { this.setState( { password: event.target.value } ) };
  handleRepeatPasswordChange = ( event ) => { this.setState( { repeatPassword: event.target.value } ) };
  
  handleEditPasswordSubmit = ( event ) => {

    if ( event ) event.preventDefault();
    
    const { password, repeatPassword } = this.state;
    if ( password.length > 0 && password !== repeatPassword ) {

      this.setState( { errorMessages: [ "Passwords doesn't match" ] })
      return false;

    } else {

      const url_params = new URLSearchParams(window.location.search);
      const user_id = url_params.get('user');
      const auth_token = url_params.get('confirmation');

      
      console.log(user_id, auth_token);
      // Request API.
      axios( {
        method: 'PUT',
        url: `${process.env.REACT_APP_BACKEND_URL}/users/${user_id}`,
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + auth_token
        },
        data: {
          password: this.state.password,
        } 
      } )
      .then(password_response => {
        
        console.log('Password set', password_response);
        // If isn't logged in
        if ( !Cookies.get('authToken') ) {

          // this.setState( { errorMessages: [] } );
            // Login 
          axios( {
            method: 'POST',
            url: `${process.env.REACT_APP_BACKEND_URL}/auth/local`,
            withCredentials: true,
            data: {
              identifier: password_response.data.username,
              password: this.state.password,
            } 
          } )
          .then(login_response => {
              
            Cookies.set('authToken', login_response.data.jwt );//, { sameSite: 'strict' } );
            this.props.userSignIn( login_response.data.user );
            
          })
          .catch(error => {
      
            error = error.response;
            
            // Handle error.
            if ( error.data.message ) {
              if ( Array.isArray(error.data.message) ) {
                if (
                  error.data.message[ 0 ].messages &&
                  error.data.message[ 0 ].messages[ 0 ] &&
                  error.data.message[ 0 ].messages[ 0 ].message
                ) {
                  this.setState( { errorMessages: [ error.data.message[ 0 ].messages[ 0 ].message ] } );
                }
              } else if ( error.data.message === "missing.email" ) {
                this.setState( { errorMessages: ["Missing email"] } );
              }
            }
          });

        }
        

        
        
      })
      .catch(error => {

        error = error.response;
        console.log(error);
        // Handle error.
        if ( error.data.message ) {
          if ( Array.isArray(error.data.message) ) {
            if (
              error.data.message[ 0 ].messages &&
              error.data.message[ 0 ].messages[ 0 ] &&
              error.data.message[ 0 ].messages[ 0 ].message
            ) {
              this.setState( { errorMessages: [ error.data.message[ 0 ].messages[ 0 ].message ] } );
            }
          } else if ( error.data.message === "missing.email" ) {
            this.setState( { errorMessages: ["Missing email"] } );
          } else if ( error.data.message ) {
            this.setState( { errorMessages: [error.data.message] } );
          }
        }
      });
    }
    
    return false;
  }


  getForm = () => {

    return (
      <form className="c-form" onSubmit={this.handleEditPasswordSubmit}>
        <div className="c-input">
          <label className="c-input__label" htmlFor="loginPassword">Password</label>
          <input className="c-input__input" type="password" id="loginPassword" name="password" value={this.state.password} onChange={this.handlePasswordChange} />
        </div>

        <div className="c-input">
          <label className="c-input__label" htmlFor="loginRepeatPassword">Repeat password</label>
          <input className="c-input__input" type="password" id="loginRepeatPassword" name="repaeatPassword" value={this.state.repeatPassword} onChange={this.handleRepeatPasswordChange} />
        </div>

        <div className="c-form__bottom l-columns-container--outmost">
          <input className="c-form__submit" type="submit" value="Set password" />
        </div>
      </form>
    );

  }

  errorMessages() {

    if ( this.state.errorMessages && this.state.errorMessages.length ) {
      return this.state.errorMessages.map( ( message, i ) => {
        return (<p key={`error-${i}`} className="f-error">{ message }</p>);
      });
      
    }

    return <></>;

  }


  render() {

    return (

      <div className="c-login l-columns-container l-columns-container--outmost">

        <div className="c-page c-login__login">
          
          <h1 className="c-login__headline c-form__headline">{ this.state.welcomeText}</h1>
          <p>Please set your password</p>

          {this.errorMessages()}
          {this.getForm()}

        </div>

        <div className="c-login__background">
          {this.state.backgroundImage}
        </div>

      </div>

    );

  }

}