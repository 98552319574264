import React from 'react';
import { Redirect } from 'react-router-dom';

import Form from '../../../../components/Form';
import LinkPage from '../../../../components/LinkPage';

import { complete_schema, complete_ui_schema } from "./schemas/complete";

export default class Brief extends React.Component {

  constructor( props ) {

    super( props );

    this.state = {

      project: null,
      subPages: [],
      step: 0

    }

  }


  componentDidMount() {
    
    let project;
    if ( this.props.project ) {

      project = this.props.project;

    } else if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.project &&
      this.props.location.state.project ) { 

        project = this.props.location.state.project;

    }

    if ( project ) { 

      this.setState( {
        project: project,
        subPages: this.getSubPages( project )
      } );

    }
    
  }


  componentDidUpdate() {

    if ( this.props.project && !this.state.project ) {

      this.setState( {
        project: this.props.project,
        subPages: this.getSubPages( this.props.project )
      } );

    }

  }


  handlePreviousClicked = ( event ) => {

    if ( event ) event.preventDefault();
    
    const { step } = this.state;
    this.setState( { step: step - 1 } );

    return false;
    
  }


  handleNextClicked = ( event ) => {

    if ( event && event.data ) {

      

    } else if ( event ) {

      event.preventDefault();

    }
    
    
    const { step } = this.state;
    this.setState( { step: step + 1 } );

    return false;

  }


  handleUpdateContent = ( response ) => {

    const { step, subPages } = this.state;
    
    if ( step === subPages.length - 1) {

      const updated_project = response.data;
      this.props.onProjectUpdate( updated_project );
      this.setState( { step: step + 1 } );

    // } else if ( response.contentType === "partners" || response.contentType === "users" ) {

    //   console.log('updateContent else');
    //   const updated_company = { ...company, ...response.data };
    //   project.company[ 0 ] = response.data;
    //   this.setState( {

    //     company: updated_company,
    //     project: project,
    //     step: step + 1

    //   } );
  
    }

  }


  handleChangeContent = response => {

    console.log('handleChangeContent', response);

  }


  getSubPages = ( content ) => {

    const sub_pages = [];

    if ( content ) {            

      if ( content.approach && content.approach.toggle ) {
        sub_pages.push( <LinkPage content={ content.approach } onCancelClicked={this.handlePreviousClicked} onNextClicked={this.handleNextClicked} /> );
      }

      if ( content.method && content.method.toggle ) {
        sub_pages.push( <LinkPage content={ content.method } onCancelClicked={this.handlePreviousClicked} onNextClicked={this.handleNextClicked} /> );
      }

      if ( content.creative_brief && content.creative_brief.toggle ) {
        sub_pages.push( <LinkPage content={ content.creative_brief } onCancelClicked={this.handlePreviousClicked} onNextClicked={this.handleNextClicked} /> );
      }

      if ( content.research && content.research_toggle && content.research.length ) {
        sub_pages.push( <LinkPage content={ content.research } onCancelClicked={this.handlePreviousClicked} onNextClicked={this.handleNextClicked} /> );
      }

      if ( content.tools && content.tools_toggle && content.tools.length ) {
        sub_pages.push( <LinkPage content={ content.tools } onCancelClicked={this.handlePreviousClicked} onNextClicked={this.handleNextClicked} /> );
      }

      const complete_data = { brief_completed: true };
      const form_config = {
        contentType: "projects",
        schema: complete_schema,
        uiSchema: complete_ui_schema,
        data: complete_data,
        content: this.props.project,
        submitLabel: "Confirm",
      };
      console.log(form_config);
      const title = "That’s it for now.<br>Let us know you’ve reviewed the brief so we can arrange the next step.";
      const title_element = { __html: title };
      const complete_page = (
        <div className="c-page">
          <div className="l-columns-container l-columns-container--outmost">
            <h1 className="c-page__headline c-form__headline" dangerouslySetInnerHTML={title_element}></h1>
          </div>

          <Form
            cancelLabel="Previous"
            {...form_config}
            onUpdateContent={this.handleUpdateContent}
            onChange={this.handleChangeContent}
          />

        </div>
      );
      sub_pages.push(complete_page);

    }

    return sub_pages;

  }


  render() {

    const { subPages, step } = this.state;

    if ( this.props && this.props.project && subPages && subPages.length ) {
      if ( step < 0 || step === subPages.length ) {

        return <Redirect to={`/projects/${this.props.project.id}/onboarding/overview/`}></Redirect>;

      }
    }

    const sub_page = subPages[ step ];

    let progress_element = <></>;
    if ( subPages.length ) {

      const progress_unit = 100 / subPages.length;
      const progress = progress_unit * step + progress_unit;
      const style = { width: `${progress}%` };
      progress_element = <div className="c-progress" style={style} />;

    }

    return (
      <>
        {progress_element}
        {sub_page}
      </>
    );
  }
}