export const logo_schema = {
  type: "object",
  properties: {
    logo: {
      type: ["string"],
      title: "Upload a high-res logo (.jpg, .png or .pdf)"
    }
  }
};

export const logo_ui_schema = {
  logo: {
    "ui:widget": "file",
  }
}