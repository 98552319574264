import React from "react";
import axios from 'axios';
import Cookies from 'js-cookie';
import { NavLink } from "react-router-dom";

import "./header.css";

export default class Header extends React.Component {

  constructor( props ) {

    super( props );

    const user = this.props.user ? this.props.user : null;

    this.state = {

      assignedProjects: null,
      menuOpen: false,
      showBackground: false,
      user: user

    }

    this.handleSignoutClick = this.handleSignoutClick.bind(this);

  }

  componentDidMount() {

    this.setNotifications();
    window.addEventListener( "scroll", () => this.handleWindowScroll() );
  }


  componentDidUpdate() {

    this.setNotifications();
    
  }


  handleWindowScroll = () => {

    const { showBackground } = this.state;
    if ( showBackground && window.scrollY <= 0 ) {

      this.setState( { showBackground: false } );

    } else if ( !showBackground && window.scrollY > 0 ) {

      this.setState( { showBackground: true } );

    }

  }
  
  
  setNotifications = () => {

    // if assigned projects is still null
    // if user role is Project manager
    if ( !this.state.assignedProjects && this.state.user && `${this.state.user.role.id}` === `${process.env.REACT_APP_USER_ROLE_PROJECT_MANAGER}` ) {

      // Fetch projects where user is assigned as project manager
      axios({

        method: 'GET',
        url: `${process.env.REACT_APP_BACKEND_URL}/projects/action-needed?project_managers.id=${this.props.user.id}`,
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + Cookies.get('authToken')
        }

      })
      .then( response => {
        this.setState( { assignedProjects: response.data } );
      } )
      .catch(error => {
        console.log( error );
      } );

    }

  }


  handleSignoutClick( event ) {

    event.preventDefault();
    
    axios( {
      method: 'POST',
      withCredentials: true,
      url: `${process.env.REACT_APP_BACKEND_URL}/logout`,
      headers: {
        Authorization: "Bearer " + Cookies.get('authToken')
      }
    } )
    .then(response => {

      // Handle success.
      this.props.userSignOut();

    })
    .catch(error => {
      // Handle error.
    } );

    return false;

  }


  handleHamburgerClick = event => {

    event.preventDefault();
    console.log('handleHamburgerClick');
    const { menuOpen } = this.state;
    this.setState( { menuOpen: !menuOpen } )
    return false;
  }


  handleLinkClicked = event => {

    this.setState( {
      menuOpen: false
    } )
    
  }


  links() {

    if ( this.props.isLoggedIn ) {
      
      const hidden_modifier = !this.props.showMenu ? 'c-header__nav--hidden' : '';

      // Note: role.type project_manager has an role.id of 3.
      // Only setting the "or" condition in case the type or id changes down the road
      if ( this.props.user ) {

        if ( `${this.props.user.role.id}` === `${process.env.REACT_APP_USER_ROLE_PROJECT_MANAGER}` ) {

          const links = [
            { url: "/partners", title: "Partners" },
            { url: "/projects", title: "Projects" },
            // { url: "/create-project-manager", title: "Create PM" },
          ];
          
          const link_elements = links.map( ( link, i ) => {
            return <NavLink key={`link_${i}`} className="c-header__nav__link" activeClassName='s-active' onClick={this.handleLinkClicked} to={link.url}>{link.title}</NavLink>
          } );

          return (
            <nav className={`c-header__nav ${hidden_modifier}`}>
              {link_elements}
            </nav>
          );

        } else if ( this.props.user.role.name === "External primary" ) {
          
          // return (
          //   <nav className={`c-header__nav ${hidden_modifier}`}>
          //     <NavLink className="c-header__nav__link" to="/projects">Projects</NavLink>
          //   </nav>
          // );

        }

      }

    }

    return <React.Fragment></React.Fragment>;

  }


  user() {

    if ( this.props.user && `${this.props.user.role.id}` === `${process.env.REACT_APP_USER_ROLE_PROJECT_MANAGER}` ) {

      return (
        <div className="c-header__user">
          <p className="c-header__username">{this.props.user.username}</p>
          <a href="#0" className="c-header__signout" onClick={this.handleSignoutClick}>Sign out</a>
        </div>
      );

    }

    return <></>;

  }


  hamburger() {
    if ( this.props.user && `${this.props.user.role.id}` === `${process.env.REACT_APP_USER_ROLE_PROJECT_MANAGER}` ) {
    
      return (
        <a href="#0" onClick={this.handleHamburgerClick} className="c-hamburger">
          <div className="c-hamburger__line"></div>
          <div className="c-hamburger__line"></div>
        </a>
      );

    }

    return <></>;

  }


  notifications() {

    if ( this.props.user && `${this.props.user.role.id}` === `${process.env.REACT_APP_USER_ROLE_PROJECT_MANAGER}` ) {
      
      const { assignedProjects } = this.state;
      const num_assigned_projects = assignedProjects ? assignedProjects.length : 0;
      return (

        <div className="c-header__notifications">
          <NavLink to="/notifications" className="c-header__notifications__counter-container">
            <p className="c-header__notifications__counter">{num_assigned_projects}</p>
          </NavLink>
        </div>

      );
    }

    return <></>;

  }


  render() {

    let header_inner = <></>;
    if ( this.props.showHeader ) {

      header_inner = (
        <>
          {this.links()}
          {this.user()}
          {this.hamburger()}
          {this.notifications()}
        </>
      );

    }

    const menu_open_modifier = this.state.menuOpen ? "open" : "closed";
    const show_background_modifier = this.state.showBackground ? "background" : "no-background";

    return (
      
      <header className={`c-header c-header--${menu_open_modifier} c-header--${show_background_modifier}`}>
        
        <div className="c-header__background"></div>

        <div className="c-header__inner l-columns-container l-columns-container--outmost">
        
          <div className="c-header__logo-container">
            <NavLink className="c-header__logo" to="/">
              <svg xmlns="http://www.w3.org/2000/svg" width="176" height="17" viewBox="0 0 176 17">
                <defs>
                  <path id="a" d="M.05.09h11.99v15.64h-12z"/>
                </defs>
                <g fill="none" fillRule="evenodd">
                  <path className="c-header__logo__network" fill="#FFF" fillRule="nonzero" d="M113.5 1.05h1.75V16h-2.14l-4.96-7.7a129.04 129.04 0 01-3.1-5.07c.06 2.94.06 5.71.06 7.73V16h-1.75V1.05h2.15l4.95 7.7c1.03 1.62 2.3 3.62 3.11 5.07-.06-2.94-.06-5.72-.06-7.73V1.05zm13.23 9.53v.59h-8.24c.17 2.44 1.45 3.55 3.34 3.55 1.54 0 2.5-.8 2.88-2.02h1.74a4.52 4.52 0 01-4.66 3.55c-2.96 0-5.04-2.16-5.04-5.67 0-3.4 2-5.67 5-5.67 3.23 0 4.98 2.56 4.98 5.67zm-4.98-4.13c-1.7 0-2.94 1.07-3.21 3.2h6.38c-.17-1.78-1.24-3.2-3.17-3.2zm10.49.18H130v7.04c0 .57.27.92 1.24.92h.99V16c-.47.04-.86.06-1.2.06-1.68 0-2.7-.58-2.7-2.24V6.63h-1.8V5.16h1.8V2.1H130v3.06h2.23v1.47zm14.35-1.47h1.8L144.8 16h-1.77l-1.6-5.02c-.38-1.2-.75-2.46-1.09-3.67a187.7 187.7 0 01-1.11 3.67L137.64 16h-1.76l-3.61-10.84h1.8l1.54 4.9c.42 1.32.8 2.62 1.17 3.95a93.3 93.3 0 011.16-3.95l1.5-4.9h1.77l1.51 4.9c.4 1.3.8 2.6 1.18 3.9.36-1.3.76-2.6 1.16-3.9l1.53-4.9zm6.66 11.1c-3.04 0-5.25-2.21-5.25-5.68 0-3.46 2.21-5.67 5.25-5.67 3.05 0 5.25 2.2 5.25 5.67 0 3.47-2.2 5.67-5.25 5.67zm0-1.52c2.1 0 3.5-1.53 3.5-4.16 0-2.62-1.4-4.16-3.5-4.16s-3.48 1.54-3.48 4.16c0 2.63 1.38 4.16 3.48 4.16zM164.98 5c.17 0 .34 0 .57.02v1.61h-.42c-2.16 0-3.55 1.1-3.55 3.2V16h-1.68V5.16h1.6v1.92A3.78 3.78 0 01164.98 5zm8.81 11l-3.65-5.82-1.94 1.8V16h-1.68V1.05h1.68V10l5.02-4.85h2.23l-4.12 3.91 4.41 6.93h-1.95z"></path>
                  <path fill="#FFF" d="M3.93 11.2C4 13 5.1 13.57 6.77 13.57c1.19 0 2.41-.42 2.41-1.55 0-1.33-2.15-1.59-4.33-2.2C2.7 9.19.45 8.2.45 5.44c0-3.3 3.3-4.6 6.13-4.6 3 0 6.02 1.46 6.04 4.88H8.7c.06-1.38-1.23-1.83-2.43-1.83-.85 0-1.9.3-1.9 1.3 0 1.16 2.17 1.37 4.37 1.99 2.17.61 4.37 1.63 4.37 4.37 0 3.83-3.25 5.06-6.57 5.06-3.46 0-6.52-1.52-6.54-5.4h3.93zM19.15 8.2h2.63c1.23 0 2.11-.51 2.11-1.89 0-1.42-.9-2-2.1-2h-2.64V8.2zm-3.93-7.04h7.15c2.81 0 5.45 1.3 5.45 4.87 0 3.76-2.13 5.22-5.45 5.22h-3.22v5.05h-3.93V1.16zM32.55 10.66h3.42l-1.67-5.3h-.04l-1.71 5.3zm-.26-9.5h4l5.63 15.14h-4.1l-.92-2.72h-5.26l-.95 2.72h-4.03l5.63-15.14zM52.28 6.56c-.21-1.52-1.4-2.33-3.04-2.33-2.53 0-3.5 2.25-3.5 4.5 0 2.24.97 4.5 3.5 4.5 1.84 0 2.9-1.07 3.04-2.8h3.92c-.2 3.9-3.06 6.18-6.88 6.18-4.55 0-7.5-3.5-7.5-7.88 0-4.4 2.95-7.9 7.5-7.9 3.25 0 6.78 2.09 6.88 5.73h-3.92zM58.57 1.16h12.01v3.16H62.5v2.65h7.38v3.05H62.5v2.88h8.3v3.4H58.56zM72.9 6.86V4.21c2.07.04 4.2-.72 4.35-3.05h2.97V16.3h-3.8V6.86H72.9z"></path>
                  <g transform="translate(82.95 .75)">
                    <mask id="b" fill="#fff"><use xlinkHref="#a"/></mask>
                    <path fill="#FFF" d="M8.6 7.9c0-2-.13-4.8-2.57-4.8-2.35 0-2.55 2.8-2.55 4.8 0 1.99.2 4.81 2.55 4.81 2.44 0 2.58-2.82 2.58-4.81m-8.56 0C.05 3.83 1.6.09 5.92.09c4.45 0 6.12 3.74 6.12 7.8 0 4.08-1.67 7.84-6.12 7.84-4.3 0-5.87-3.76-5.87-7.83" mask="url(#b)"/>
                  </g>
                </g>
              </svg>
            </NavLink>
          </div>

          {header_inner}

        </div>
      </header>

    );

  }

}