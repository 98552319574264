import React from "react";

export default class Image extends React.Component {
  
  render() {

    const base_path = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : '';
    const thumbnail = `${base_path}${this.props.image.formats.thumbnail}`;
    const formats_arr = Object.values(this.props.image.formats);
    const srcset = formats_arr.map( format => {

      return `${base_path}${format.url} ${format.width}w`;

    });

    return (
      <img
        alt={this.props.image.alternativeText}
        src={thumbnail.url}
        srcSet={srcset.join(',')}
        sizes={this.props.sizes}
        className={this.props.className}
      />
    );

  }

}