import React from "react";
import axios from 'axios';
import Cookies from 'js-cookie';

import Tag from "../../Tag";
import Toggle from "../../Toggle";

import "./project_sub_line_item.css";

export default class ProjectSubLineItem extends React.Component {


  constructor(props) {

    super(props);

    this.state = {

      toggled: false,
      editingField: false

    }

  }


  componentDidMount() {

    if (this.props.content) {

      let toggle_field;
      if ( this.props.field ) {

        toggle_field = this.props.content[ this.props.field.name ][ this.props.field.i ].toggle;

      } else {

        // Get toggle field from content
        const first_key = Object.keys( this.props.data )[ 0 ];
        toggle_field = this.props.toggleField ? this.props.content[this.props.toggleField] : this.props.content[`${first_key}_toggle`];
        
        // In case the toggle field doens't exist, it might be because we are fetchiong from
        // A Strapi component, so we're trying to grab the toggle field from the component
        if ( toggle_field === undefined) {
          
          // console.log( this.props.title, toggle_field );
          const first_key = Object.keys( this.props.data )[ 0 ];
          const link = this.props.content[ first_key ];
          if ( link ) toggle_field = link["toggle"];
          
        }

      }

      const toggled = ( toggle_field ) ? true : false;
      this.setState( { toggled: toggled } );

    }

    if ( this.props.title === "Files") {
      // this.handleEditClick();
    }

  }



  componentDidUpdate() {

    if ( !this.state.toggled && this.props.toggled && this.props.toggled === true ) {

      this.handleToggleChange( true, false );

    }

  }


  handleToggleChange = ( toggled, dispatch = true ) => {

    this.setState( { toggled: toggled } );
    
    // Get entity id
    const id = (this.props.id) ? this.props.id : this.props.content.id;
    if ( id ) {
      
      const data = {};

      // If field to update has been explicitly set
      if ( this.props.field ) {
        
        const field = this.props.content[this.props.field.name];
        if ( this.props.field.i > -1 && field[ this.props.field.i ] !== undefined ) {
          
          data[ this.props.field.name] = this.props.content[ this.props.field.name ];
          data[ this.props.field.name][ this.props.field.i ].toggle = toggled;
          
        }
        
      } else {
        
        let first_key = Object.keys( this.props.data )[ 0 ];
        const field = ( this.props.toggleField ) ? this.props.toggleField : `${first_key}_toggle`;
        if ( this.props.content[ field ] !== undefined ) {
          
          data[ field ] = toggled;
          
        } else {
          
          data[ first_key ] = { toggle: toggled }
          
        }
        
      }

      axios({

        method: 'PUT',
        url: `${process.env.REACT_APP_BACKEND_URL}/${this.props.contentType}/${id}`,
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + Cookies.get('authToken')
        },
        data: data

      })
        .then(response => {

          console.log(response);
          if ( dispatch ) this.props.onUpdateContent( response );

        })
        .catch(error => {

          console.log(error);

        });

    }

  }


  handleEditClick = ( event = undefined ) => {

    if ( event ) event.preventDefault();

    this.props.toggleEditingField();
    this.setState( { editingField: true } );

    return false;

  }


  handleEditClose = () => {

    this.props.toggleEditingField();
    this.setState( { editingField: false } );

  }


  render() {

    const tag_label = this.props.completed ? "View" : "Edit";

    return (
      
      <>

        <div className="c-project-sub-line-item l-columns-container">
          
          <div className="c-project-sub-line-item__title-container">

            <p className="c-project-sub-line-item__title">{this.props.title}</p>
            <Tag
              className="c-project-sub-line-item__action"
              title={tag_label}
              transparent
              small
              onClick={this.handleEditClick}
            />
            {/* <a href="#0" onClick={this.handleEditClick} className="c-project-sub-line-item__action c-tag--transparent">Edit</a> */}

          </div>

          <Toggle
            className="c-project-sub-line-item__toggle"
            onToggleChange={this.handleToggleChange}
            size="small"
            toggled={this.state.toggled} />

        </div>

      </>

    );

  }

}