export const github_schema = {
  type: "object",
  properties: {
    delivery_github: {
      type: "string",
      default: "",
      title: "Github repositories"
    },
  }
};

// export const bio_ui_schema = {
//   biography: {
//     "ui:widget": "TextAreaWidget"
//   }
// }