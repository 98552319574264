import React from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';

import SearchForm from "../../components/SearchForm";
import IconCross from "../../components/Icons/IconCross";
import ProjectTeaser from "../../components/ProjectTeaser";

import "./projects.css";

export default class Projects extends React.Component {
 
  constructor( props ) {

    super( props );

    this.state = {

      projects: [],
      projectsFiltered: [],
      sortBy: "partner",
      notificationProjectsFetched: false

    }

  }


  componentDidMount() {

    this.getProjects();

  }


  componentDidUpdate() {

    if ( this.state.projects.length === 0 ) {
      this.getProjects();
    }


  }


  requestProjects = ( url, state_params ) => {

    axios( {
      method: 'GET',
      withCredentials: true,
      url: url,
      headers: {
        Authorization: "Bearer " + Cookies.get('authToken')
      }
    } )
    .then( response => {

      // Handle success.
      const data = response.data;
      const projects = data.filter( project => project );
      this.setState( {

        projects: projects,
        projectsFiltered: projects,
        ...state_params

      } );
      
    } )
    .catch(error => console.log( 'An error occurred:', error.response ) );

  }


  getProjects = () => {

    const { notificationProjectsFetched, projects } = this.state;
    const { user, notifications } = this.props;
    let url = `${process.env.REACT_APP_BACKEND_URL}/projects`;

    if ( user && notifications && !notificationProjectsFetched) {

      url += `/action-needed?project_managers.id=${user.id}`;
      this.requestProjects( url, { user: user, notificationProjectsFetched: true } );
      
    } else if ( ( notificationProjectsFetched && !notifications ) || projects.length === 0 ) {
        
      this.requestProjects( url, { notificationProjectsFetched: false } );

    }

  }


  getProjectsSorted = ( projects, sort_by ) => {
    
    const steps = ["basics", "nda", "brief", "contract", "details", "delivery", "evaluation"];

    projects.sort( ( a, b ) => {

      if ( sort_by === "newest" ) {
        
        if ( a.id > b.id ) return -1;
        if ( a.id < b.id ) return 1;

      } else if ( sort_by === "oldest" ) {

        if ( a.id < b.id ) return -1;
        if ( a.id > b.id ) return 1;

      } else if ( sort_by === "completed" ) {

        const a_applied_steps = steps.filter( step => a[`${step}_toggle`] );
        if ( a_applied_steps.length === 0) return 1;
        
        const b_applied_steps = steps.filter( step => b[`${step}_toggle`] );
        if ( b_applied_steps.length === 0) return -1;
        
        const a_completed_steps = a_applied_steps.filter( step => a[`${step}_completed`] );
        const a_completed = a_applied_steps.length === a_completed_steps.length;
        if ( a_completed ) return -1;

        const b_completed_steps = b_applied_steps.filter( step => b[`${step}_completed`] );
        const b_completed = b_applied_steps.length === b_completed_steps.length;  
        if ( b_completed ) return 1;

      } else if ( sort_by === "myProjects" && this.props.user ) {

        const a_has_pm = a.project_managers.filter( pm => {
          if (pm.id === this.props.user.id) return pm.id;
          return null;
        } ).length > 0;
        if ( a_has_pm ) return -1;

        const b_has_pm = b.project_managers.filter( pm => {
        if (pm.id === this.props.user.id) return pm.id;
        return null;
        } ).length > 0;
        if ( b_has_pm ) return 1;
      
      } else if ( sort_by === "partner" ) {

        if ( !a.company ) return 1;
        if ( !b.company ) return -1;
        
        if ( a.company.name.toUpperCase() < b.company.name.toUpperCase() ) return -1;
        if ( a.company.name.toUpperCase() > b.company.name.toUpperCase() ) return 1;

      } else if ( sort_by === "title" ) {

        if ( a.title.toUpperCase() < b.title.toUpperCase() ) return -1;
        if ( a.title.toUpperCase() > b.title.toUpperCase() ) return 1;

      }

      return 0;


    } );

    return projects;

  }


  handleSortChanged = ( event ) => {

    this.setState( { sortBy: event.target.value } );

  }


  handleUpdateQuery = ( queries ) => {

    let result;
    if ( queries.length) {

      // Returns an array for each query
      const results = queries.map( query => {
      
        return this.state.projects.filter( project => {
  
          // Filter by project title
          if ( project.title.toLowerCase().indexOf(query.toLowerCase()) > -1) {
            return true;
          }

          // Filter by partner services
          for ( let i = 0; i < project.project_type.length; i++ ) {
            if ( project.project_type[ i ].title.toLowerCase().indexOf(query.toLowerCase()) > -1) {
              return true;
            }
          }

          // Filter by partner name
          if ( project.company && project.company.name.toLowerCase().indexOf(query.toLowerCase()) > -1) {
            return true;
          }
          
          return false;
  
        } );
  
      });

      // Combine arrays to a single object
      result = [];
      for ( let i = 0; i < results.length; i++ ) {
        result = result.concat(results[i]);
      }

      // Remove duplicates
      const unique_results = new Set( result );
      result = [ ...unique_results ];

    } else {

      result = this.state.projects;

    }

    this.setState( { projectsFiltered: result } );

  }


  projects() {

    const { projectsFiltered, sortBy } = this.state;
    let projects = projectsFiltered;
    projects = this.getProjectsSorted( projects, sortBy );

    return projects.map( ( project, i ) => {
      return <ProjectTeaser key={`projectTeaser-${i}` } { ...project } />
    } );

  }


  getCreateProjectLink = () => {
    
    if (this.props.user && this.props.user.role.name === "Project manager" ) {

      return (
        <Link to="/create-project/title" className="c-projects__header__link-right">
          <IconCross />
        </Link>
      );

    }

    return <></>

  }


  render() {

    return (
      <div className="c-projects c-page">

        <div className="c-projects__header l-columns-container--outmost">
          <SearchForm updateQuery={this.handleUpdateQuery} />

          {this.getCreateProjectLink()}

        </div>

        <div className="c-projects__sort l-columns-container--outmost">
          <div className="c-projects__sort__inner">
            
            <label htmlFor="filter">Sort by —</label>
            <select id="filter" name="filter" onChange={this.handleSortChanged}>
              <option value="newest">Newest</option>
              <option value="oldest">Oldest</option>
              <option value="completed">Completed</option>
              <option value="myProjects">My projects</option>
              <option value="title">Title</option>
              <option value="partner">Partner</option>
            </select>

            <div className="c-projects__sort__icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11"><g fill="none" fillRule="evenodd"><g className="c-select__arrow"><path d="M14.34 1.59l-6.03 8.3a1 1 0 01-1.62 0L.66 1.59A1 1 0 011.46 0h12.08a1 1 0 01.8 1.59z"/></g></g></svg>
            </div>

          </div>
        </div>


        <div className="c-projects__projects l-columns-container l-columns-container--outmost">
          
            {this.projects()}
              
        </div>

      </div>
    );
  }
}