export const partner_schema = {
  type: "object",
  properties: {
    id: {
      type: "number",
    },
    name: {
      type: "string",
      title: "Name",
    },
    company_services: {
      title: "Services",
      type: "array",
      items: {
        properties: {
          id: {
            type: "number",
            enum: [],
            enumNames: [],
            required: true,
          }
        }
      }
    },
    biography: {
      type: "string",
      default: "",
      title: "Bio"
    },
    company_legal_name: {
      type: "string",
      default: "",
      title: "Legal name"
    },
    primary_person: {
      title: false,
      properties: {
        id: {
          type: "number",
        },
        username: {
          type: "string",
          title: "Point of contact" 
        },
        email: {
          type: "string",
          title: "Email" 
        }
      }
    },
    address: {
      type: "object",
      title: "",
      properties: {
        street_name_and_number: {
          type: "string",
          default: "",
          title: "Street name and number"
        },
        zip: {
          type: "string",
          default: "",
          title: "zip"
        },
        city_country: {
          type: "object",
          title: "",
          properties: {
            city: {
              type: "string",
              default: "",
              title: "City"
            },
            country: {
              type: "string",
              default: "",
              title: "Country"
            },
          }
        }
      }
    },
    // locations: {
    //   title: "",
    //   type: "array",
    //   items: {
    //     type: "object",
    //     properties: {
    //       city: {
    //         title: "Choose city",
    //         type: "string",
    //       },
    //       country: {
    //         title: "Choose country",
    //         type: "string",
    //         enum: [],
    //       }
    //     }
    //   }
    // },
    vat: {
      type: "string",
      default: "",
      title: "VAT"
    },
    website: {
      type: "string",
      default: "",
      title: "WWW"
    },
    facebook: {
      type: "string",
      default: "",
      title: "Facebook"
    },
    instagram: {
      type: "string",
      default: "",
      title: "Instagram"
    },
    linkedin: {
      type: "string",
      default: "",
      title: "LinkedIn"
    },
    twitter: {
      type: "string",
      default: "",
      title: "Twitter"
    }
  }
};

export const partner_ui_schema = {
  id: {
    "ui:widget": "hidden",
    "ui:options": {
      label: false
    }
  },
  company_services: {
    "ui:options":  {
      orderable: false,
    },
    items: {
      "ui:options": {
        label: false
      },
      id: {
        "ui:options": {
          label: false
        }
      }
    }
  },
  biography: {
    "ui:widget": "TextAreaWidget"
  },
  primary_person: {
    "ui:options": {
      label: false
    },
    id: {
      "ui:options": {
        label: false,
        // widget: "hidden"
      },
    },
    username: {
      "ui:widget": "AutoCompleteWidget"
    }
  },
  locations: {
    "ui:options":  {
      orderable: false,
    },
    items: {
      classNames: "c-form-group c-form-group--inline c-form-group--location",
    }
  }
}