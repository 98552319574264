export const approach_schema = {
  type: "object",
  properties: {
    approach: {
      title: "",
      type: "object",
      properties: {
        title: { type: "string", default: "", title: "Title" },
        link_title: { type: "string", default: "", title: "Link title" },
        link_subtitle: { type: "string", default: "", title: "Subtitle" },
        link: { type: "string", default: "", title: "Link" },
        visual: {
          title: "Visual",
          type: ["string"],
        }
      }
    }
  }
};

export const approach_ui_schema = {
  approach: {
    "ui:displayLabel": false,
    visual: {
      "ui:widget": "file"
    }
  }
}