import React from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Redirect } from 'react-router-dom';

import { team_schema, team_ui_schema } from "./schemas/team";
import { complete_schema, complete_ui_schema } from "./schemas/complete";

import Form from '../../../../components/Form';
import LinkPage from '../../../../components/LinkPage';

export default class Details extends React.Component {

  constructor( props ) {

    super( props );

    this.state = {

      company: null,
      formConfig: [],
      project: null,
      step: 0

    }

  }


  componentDidMount() {
    
    let project;
    if ( this.props.project ) {

      project = this.props.project;

    } else if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.project &&
      this.props.location.state.project ) { 

        project = this.props.location.state.project;

    }

    if ( project ) this.getCompany( project );
    
  }


  componentDidUpdate() {

    if ( this.props.project && !this.state.project ) {

      this.getCompany( this.props.project );

    }

  }


  getCompany = ( project ) => {

    axios( {

      method: 'GET',
      url: `${process.env.REACT_APP_BACKEND_URL}/partners?id=${project.company.id}`,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + Cookies.get('authToken')
      }

    } )
    .then( response => {

      const company = response.data[ 0 ];
      project.company = company

      this.setState( { project: project } );
      this.setState( { formConfig: this.getFormConfig(project) } );
      // this.setState( {
        
      //   formConfig: this.getFormConfig(project),
      //   project: project,

      // } );
      
    } )
    .catch( error => console.log( 'An error occurred:', error.response ) );

  }


  handleUpdateContent = ( response ) => {

    const { formConfig, step } = this.state;
    
    if ( step === formConfig.length - 1) {

      const updated_project = response.data;
      this.props.onProjectUpdate( updated_project );
      this.setState( { step: step + 1 } );

    } else if ( response.contentType === "projects" ) {

      const updated_project = response.data;
      this.setState( {

        project: updated_project,
        step: step + 1

      } );
  
    }

  }


  handleChangeContent = formData => {

    const { formConfig, step } = this.state;
     // If new data us comming in and the new data is an object
    // update data to autofill id field and email field
    if ( formConfig[ step ].data !== formData ) {
      if ( formConfig[ step ].foundation && formConfig[ step ].uiSchema ) {

        const first_key = Object.keys(formConfig[ step ].uiSchema)[ 0 ];
        if ( formData[ first_key ] ) {

          let update_form_data = false;
          for ( let i = 0; i < formData[ first_key ].length; i++ ) {
            
            if ( formData[ first_key ][ i ] && formData[ first_key ][ i ].autoComplete ) {
                
              // Don't need this property any longer, so just delete it
              delete formData[ first_key ][ i ].autoComplete;
              update_form_data = true;
              
            }
            
          }
          
          if ( update_form_data ) {
            
            formConfig[ step ].data = formData;
            this.setState( { formConfig: formConfig } );

          }

        }

      }

    }

  }


  handlePreviousClicked = ( event ) => {

    if ( event ) event.preventDefault();
    
    const { step } = this.state;
    this.setState( { step: step - 1 } );

    return false;
    
  }


  handleNextClicked = ( event ) => {

    if ( event && event.data ) {

      

    } else if ( event ) {

      event.preventDefault();

    }
    
    
    const { step } = this.state;
    this.setState( { step: step + 1 } );

    return false;

  }


  getFormConfig = () => {

    const steps = [];
    
    const { project } = this.state;
    
    if ( project ) {

      let data;

      // Links
      for ( let i = 0; i < project.details.length; i++ ) {
        
        if ( project.details[ i ].toggle ) {

          data = project.details[ i ]
          steps.push( {
            
            type: "link",
            element: (
              <LinkPage content={ data } onCancelClicked={this.handlePreviousClicked} onNextClicked={this.handleNextClicked} />
              )
          } );

        }

      }
      
      // Team step
      if ( project.team_toggle ) {
        data = { team: project.team };
        let details_schema = {
          title: "Add core project team",
          contentType: "projects",
          schema: team_schema,
          uiSchema: team_ui_schema,
          data: data,
          content: project,
          toggleField: "team_toggle",
          foundation: {
            url:`partners?id=${project.company.id}`,
            array: "team_persons",
            field: "username",
            return: "id"
          },
        };
        steps.push( details_schema );

      }

      // Complete step
      data = { details_completed: true };
      steps.push( {
        title: "That’s all.<br>Please confirm you’ve added the milstones and you are up and running with slack and the G suite.", contentType: "projects",
        schema: complete_schema,
        uiSchema: complete_ui_schema,
        data: data,
        content: project,
        submitLabel: "Submit"
      } );
      
    }

    return steps;

  }


  render() {

    const { formConfig, step } = this.state;

    if ( this.props && this.props.project && formConfig && formConfig.length && step === formConfig.length) {
      
      return <Redirect to={`/projects/${this.props.project.id}/onboarding/overview/`}></Redirect>;

    }

    const form_config = formConfig[ step ];
    let title = form_config && form_config.title ? form_config.title : "";
    const title_element = { __html: title };

    let page_content = <></>;
    if ( form_config && form_config.type && form_config.type === "link" ) {

      page_content = form_config.element;

    } else {

      page_content = (
        <div className="c-page">
          
          <div className="l-columns-container l-columns-container--outmost">
            <h1 className="c-page__headline c-form__headline" dangerouslySetInnerHTML={title_element}></h1>
          </div>
          
          <Form
            submitLabel="Next"
            cancelLabel="Previous"
            {...form_config}
            onUpdateContent={this.handleUpdateContent}
            onChange={this.handleChangeContent}
          />
        </div>
      );

    }

    let progress_element = <></>;
    if ( formConfig.length ) {

      const progress_unit = 100 / formConfig.length;
      const progress = progress_unit * step + progress_unit;
      const style = { width: `${progress}%` };
      progress_element = <div className="c-progress" style={style} />;

    }

    return (
      <>
        {progress_element}
        {page_content}  
      </>
    );
  }
}