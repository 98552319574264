import React from "react";
import axios from 'axios';
import Cookies from 'js-cookie';

import Image from '../../Image'

import "./file_upload.css";

export default class FileUpload extends React.Component {

  constructor( props ) {

    super( props );
   
    const files = ( props.files ) ? props.files : [];
    
    this.state = {

      files: files

    }

  }


  componentDidMount() {

    if ( ( !this.state.files || this.state.files.length === 0 ) && this.props.value ) {

      this.setState( { files: [ this.props.value ] } );

    }

  }


  handleInputChange = ( event ) => {

    const response_object = {};
    if ( this.props.dataField ) response_object.dataField = this.props.dataField;

    const form_data = new FormData();
    Array.from( event.target.files ).forEach( file => {

      form_data.append('files', file);

    } );

    axios( {

      method: 'POST',
      url: `${process.env.REACT_APP_BACKEND_URL}/upload`,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + Cookies.get('authToken'),
        "Content-Type": 'multipart/form-data'
      },
      data: form_data

    } )
    .then(response => {

      // if ( this.props.limit )
      // const existing_files = this.state.files;

      // console.log('existing', existing_files, response.data);
      // const files = existing_files.concat( response.data );
      // console.log('files', files);

      // Transform id to string
      // response.data = response.data.map( item => {
      //   item.id = `${item.id}`;
      //   return item;
      // });

      const files = response.data;
      
      // const urls = files.map( file => file.url );
      // console.log(files);
      this.setState( { files: files } );
      
      response_object.response = files;
      // this.props.onChange(event.target.value)
      this.props.onChange( response_object );

    })
    .catch(error => {

      response_object.response = error;
      this.props.onChange( response_object );

    });

  }


  deleteUploadedFiles() {

    // TODO: Implement deleteUploadedFiles functionality
    // This is demo code, haven't yet been implemented
    const files = this.state.files;
    if ( files ) {

      for ( let i = 0; i < files.length; i++ ) {

        axios( {

          method: 'DELETE',
          url: `${process.env.REACT_APP_BACKEND_URL}/upload/files/${files[ i ].id}`,
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + Cookies.get('authToken')
          }
    
        } )
        .then(response => {
    
          console.log(response);
    
        })
        .catch(error => {
    
          console.log(error);
    
        });

      }

    }

  }


  thumbnails = () => {

    const files = this.state.files;

    // console.log(files);
    if ( files && files.length ) {
 
      return files.map( file => {

        const mime = file.mime;
        let type = "file";
        let image = <></>;
        // console.log(mime);
        console.log(mime);
        if ( mime.indexOf( "image" ) > -1 && mime.indexOf("svg") === -1 ) {
          
          // console.log('if');
          type = "image";
          image = <Image image={file} />

        } else {

          image = <p>{file.name}</p>

        }

        // console.log(image);
        return (
          <div key={file.id} className={`c-file-upload__thumbnail c-file-upload__thumbnail--${type}`}>
            {image}
          </div>
        );

      } );
    }

    return <></>;

  }


  render() {

    const { id } = this.props;
    // const value = (this.state.files && this.state.files.length ) ? this.state.files[0].url : "";

    return (

      <div className="c-input">

        <div className="c-input__file-container">
          <div className="c-input__file-container__inner">
            
            {this.thumbnails()}
            <input
              className="c-input__input c-input__input--file"
              type="file"
              name={id}
              // value={this.state.files}
              // required={this.props.required}
              onChange={this.handleInputChange} />

          </div>
        </div>

      </div>

    );

  }

}