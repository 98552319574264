export const complete_schema = {
  type: "object",
  properties: {
    basics_completed: {
      type: "boolean",
    }
  }
};

export const complete_ui_schema = {
  basics_completed: {
    "ui:options": { label: false },
    "ui:widget": "hidden",
  }
};