export const rating_schema = {
  type: "object",
  properties: {
    rating: {
      title: "",
      type: "object",
      properties: {
        question: {
          type: "string",
          default: "",
          title: "Question"
        },
        rating: {
          type: "number",
          title: "Rating",
        }
      }
    }
  }
};

export const rating_ui_schema = {
  
}