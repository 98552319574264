export const research_schema = {
  type: "object",
  properties: {
    research: {
      title: "",
      type: "array",
      items: {
        type: "object",
        properties: {
          title: { type: "string", default: "", title: "Title" },
          link_title: { type: "string", default: "", title: "Link title" },
          link_subtitle: { type: "string", default: "", title: "Subtitle" },
          link: { type: "string", default: "", title: "Link" },
          visual: {
            title: "Visual",
            type: ["string"],
          }
        }
      }
    }
  }
};

export const research_ui_schema = {
  research: {
    "ui:displayLabel": false,
    items: {
      visual: {
        "ui:widget": "file"
      }
    }
  }
}