import React from 'react';
import { withTheme } from "react-jsonschema-form";
import Link from '../../Link';

import FileUpload from "../../Fields/FileUpload";
import IconArrow from "../../Icons/IconArrow";
import IconCross from "../../Icons/IconCross";

export default class Completed extends React.Component {

  constructor( props ) {

    super( props );
    
    // const data = props.data ? props.data : undefined;
    this.state = {
      
  

    }

  }

  static getDerivedStateFromProps( props, state ) {

    // return null;
    // Setting data on initial state
    if ( !state.data && props.data && props.data !== state.data ) {
      
      const new_state = state.data = props.data;
      return new_state;
      
    } else if (
      props.data &&
      props.data !== state.data &&
      props.foundation &&
      props.uiSchema ) {
      
      const first_key = Object.keys( props.uiSchema )[ 0 ];
      let new_data = {};
      new_data[ first_key ] = props.data[ first_key ];
      
      return { data: new_data };
    
    } else if (
      props.data &&
      props.data !== state.data ) {
      
        return { data: props.data };

    }

    return null;

  }


  handleCancelClicked = () => {

    if (this.props.onCancelClicked ) this.props.onCancelClicked();

  }

  
  CustomFieldTemplate = (props) => {

    const {id, classNames, label, help, description, rawDescription, errors, children, displayLabel, required, schema } = props;
    
    const label_text = ( required ) ? `${label}<span className="c-input__required">*</span>`: label ;
    const raw_label_text = {__html: label_text};
    const label_element = ( displayLabel && label !== "" ) ? <label className="c-input__label" htmlFor={id} dangerouslySetInnerHTML={raw_label_text}></label> : <></>;
    
    let description_element = description;
    if ( rawDescription && rawDescription.length ) {
      const raw_description = {__html: rawDescription};
      description_element = <p className="c-input__description" dangerouslySetInnerHTML={raw_description}></p>
    }

    const hidden_modifier = schema && schema.hidden ? 'c-input--hidden' : '';

    return (
      <div className={`c-input ${hidden_modifier} ${classNames}`}>
        {description_element}
        {label_element}
        {children}
        {errors}
        {help}
      </div>
    );
  }


  render() {
    
    const textWidget = (props) => {
      
      const value = ( !props.value ) ? "" : props.value;
    
      return (
        <input type="text"
          className="c-input__input"
          value={value}
          disabled
          onChange={(event) => props.onChange(event.target.value)} />
      );
    
    };

    const numberWidget = (props) => {
      
      const value = ( !props.value ) ? 0 : props.value;
    
      return (
        <input type="number"
          className="c-input__input"
          value={value}
          disabled
          onChange={(event) => props.onChange(event.target.value)} />
      );
    
    };

    const fileWidget = (props) => {
      return <FileUpload
        { ...props }
        disabled
      />
    };
    
    const textAreaWidget = (props) => {
    
      const value = ( !props.value ) ? "" : props.value;
    
      return (
        <textarea type="text"
          className="c-input__input c-input__input--textarea"
          disabled
          onChange={(event) => props.onChange(event.target.value)}
          value={value}
        />
      );
    
    };
    

    const ratingWidget = props => {

      const value = ( !props.value ) ? "" : props.value;
      const options = props.options.enumOptions;
      
      const rate_buttons = options.map( ( option, i ) => {

        const modifier = value && value === option.value ? "s-selected" : "";
        // console.log(rating, option.value);
        return (
          <a
            key={`${props.id}_${i}`}
            href="#0"
            className={`c-input__rating-number ${modifier}`}
            onClick={ (event) => {

              event.preventDefault();
              // if ( rating ) this.setState( { rating: option.value } );
              props.onChange(option.value);
              return false;

            } }
            >
            <span>{option.label}</span>
          </a>
        );
      } );

      return (
        <div className="c-input__input-container c-input__input-container--rating">
          {rate_buttons}
        </div>
      );
      
    }

    const myWidgets = {
      TextWidget: textWidget,
      NumberWidget: numberWidget,
      TextAreaWidget: textAreaWidget,
      FileWidget: fileWidget,
      AutoCompleteWidget: textWidget,
      SelectWidget: textWidget,
      RatingWidget: ratingWidget
    };


    const ArrayFieldTemplate = ( props ) => {

      const {className, title, required, schema } = props;
    
      const title_text = ( required ) ? `${title}<span className="c-input__required">*</span>`: title ;
      const raw_title_text = {__html: title_text};
      const title_element = ( title && title !== "" ) ? <p className="c-input__label" dangerouslySetInnerHTML={raw_title_text}></p> : <></>;
      const button_top_spacing_modifier = schema && schema.buttonTopSpacing ? 'c-form__array-item--button-top-spacing' : '';
      
      return (
        <div className={className}>
          
          {title_element}
          
          {props.items.map( (element, i) => {
            return (
              <div key={ element.key } className={`c-form__array-item ${button_top_spacing_modifier}`}>
                {element.children}
                {element.hasMoveUp && <button className="c-input__button" type="button" onClick={element.onReorderClick(element.index, element.index - 1)}><IconArrow direction="up" /></button>}      
                {element.hasMoveDown && <button className="c-input__button" type="button" onClick={element.onReorderClick(element.index, element.index + 1)}><IconArrow direction="down" /></button>}      
                {element.hasRemove && <button className="c-input__button" type="button" onClick={element.onDropIndexClick(element.index)}><IconCross deleteIcon /></button>}      
              </div>
            );
          })}
          
          {props.canAdd && <button className="c-input__button" type="button" onClick={props.onAddClick}><IconCross /></button>}
        </div>
      );
    }

    const theme = { ArrayFieldTemplate: ArrayFieldTemplate, widgets: myWidgets };
    const ThemedForm = withTheme(theme);
    
    let cancel_button = <></>;
    if ( this.props.onCancelClicked ) {
      
      const cancel_label = ( this.props.cancelLabel ) ? this.props.cancelLabel : "Back";
      cancel_button = (
          <a
            href="#0"
            onClick={this.handleCancelClicked}
            className="c-form__button c-form__button--secondary c-form__submit--left"
            >{cancel_label}</a>
      );
    }

    const { data } = this.state;
    let link = <></>;
    if ( data ) {
      
      const first_key = Object.keys( data )[0]; 
      if ( data.link && data.link.length ) {
      
        link = <Link content={data}></Link>;

      } else if ( first_key && data[ first_key ] ) {

        if (
          Array.isArray( data[ first_key ] ) &&
          data[ first_key ].length &&
          data[ first_key ][0].id &&
          data[ first_key ][0].link
        ) {

          link = data[ first_key ].map( link => {
            return <Link key={`link_${link.id}`} content={link}></Link>;
          } );
          
        } else if ( data[ first_key ].id && data[ first_key ].link && data[ first_key ].link.length ) {

          link = <Link content={data[ first_key ]}></Link>;

        }

      }

    }


    return (

      <>

        {link}
        <ThemedForm
          className="c-form c-form--completed"
          noValidate={true}
          schema={ (this.props.schema) ? this.props.schema : {} }
          formData={ (data) ? data : {} }
          uiSchema={ (this.props.uiSchema) ? this.props.uiSchema : {} }
          FieldTemplate={this.CustomFieldTemplate}
        >
          <div className="c-form__bottom">

            {cancel_button}

          </div>
          
        </ThemedForm>

      </>
    );

  }

}