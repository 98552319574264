import React from "react";
import axios from 'axios';
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';

import { title_schema } from "./schemas/title";
import Form from "../../../../components/Form";

import "../create_project.css";

export default class CreateProjectTitle extends React.Component {
  
  constructor( props ) {

    super( props );

    // const rand = Math.random() * 1000;
    this.state = {

      completed: false,
      typeOptions: [],
      data: {

        title: "",
        project_type: "",
        project_managers: [ this.props.user ],
        approach: {},
        method: {},
        details: [{}],
        research: [{}],
        tools: [{}],
        brief: {},
        google_drive_link: {}

      }

    };

  }


  getServices() {

    axios( {
      method: 'GET',
      url: `${process.env.REACT_APP_BACKEND_URL}/company-services/`,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + Cookies.get('authToken')
      }
    } )
    .then(response => {
      
      this.setState( {

        typeOptions: response.data,

      } );
      
    })
    .catch(error => console.log( 'An error occurred:', error.response.data ) );

  }


  componentDidMount() {

    this.getServices();

  }


  handleSubmit = ( formData ) => {
    
    this.props.projectUpdated( formData.data );
    this.setState( { completed: true } );

  }


  handleCancelClicked = () => {

    // TODO: Implement deleteUploadedFiles functionality
    // Make sure this loop works
    // const file_upload_components = this.state.fileUploadComponents;
    // for ( let i = 0; i < file_upload_components.length; i++ ) file_upload_components[ i ].deleteUploadedFiles();

    this.props.onEditClose();

    // Delete users created
    const users_created = this.state.usersCreated;
    for ( let i = 0; i < users_created.length; i++ ) {

      axios( {

        method: 'DELETE',
        url: `${process.env.REACT_APP_BACKEND_URL}/users/${users_created[ i ].id}`,
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + Cookies.get('authToken')
        }
  
      } )
      .then( response => console.log( response ) )
      .catch( error => console.log( error ) );
  
    }

  }


  render() {
  
    const { completed, typeOptions, data } = this.state;

    if ( completed ) {
      return <Redirect to="/create-project/company" />
    }
    
    if ( typeOptions && typeOptions.length ) {
      title_schema.properties.project_type.properties.id.enum = typeOptions.map( item => item.id );
      title_schema.properties.project_type.properties.id.enumNames = typeOptions.map( item => item.title );
    }
  
    return (
      <div className="c-create-project c-page">

        <div className="c-create-project__header l-columns-container l-columns-container--outmost">
          <h1 className="c-create-project__title">Setup new project collaboration</h1>
        </div>

        <div className="c-create-project__main l-columns-container--outmost">

          <Form
            className="c-form"
            {...this.props}
            noValidate={true}
            schema={ title_schema }
            contentType="projects"
            method="POST"
            data={ (data) ? data : undefined }
            onUpdateContent={this.handleSubmit}
            submitLabel="Next"
          >
          </Form>

          {/* <form className="c-create-project__form c-form" onSubmit={this.handleCreateProjectSubmit}>
          
            <div className="l-columns-container">
              <div className="c-create-project__form__inner">
                <div className="c-input">
                  <label className="c-input__label" htmlFor="title">Project title</label>
                  <input className="c-input__input" type="text" id="title" name="title" placeholder="Project title" value={this.state.title} onChange={this.handleTitleChange} />
                </div>

                <div className="c-input">
                  <label className="c-input__label" htmlFor="type">Project type</label>
                  <select className="c-input__input" id="type" name="type" onChange={this.handleTypeChange}>
                    {this.typeOptions()}
                  </select>
                </div>
              </div>
            </div>
            
            <div className="c-form__bottom l-columns-container--outmost">
              <input className="c-form__submit c-form__submit--right" type="submit" value="Next" />
            </div>

          </form> */}

        </div>

      </div>
    );

  }

};