import React from "react";
import axios from 'axios';
import Cookies from 'js-cookie';

import Image from '../../components/Image';
import Tag from '../../components/Tag';
import ProjectTeaser from "../../components/ProjectTeaser";

import AppContext from '../../utils/AppContext.js';
import Edit from "../../components/ProjectLineItem/ProjectSubLineItem/Edit"

import { partner_schema, partner_ui_schema } from "./schemas/partner";

import "./partner.css";
import IconCross from "../../components/Icons/IconCross";

export default class Partner extends React.Component {

  constructor( props ) {
    
    super( props );

    this.state = { 
      
      company: null,
      editToggled: false,

    };

  }


  componentDidMount() {

    this.props.hideHeader();

    axios( {
      method: 'GET',
      withCredentials: true,
      url: `${process.env.REACT_APP_BACKEND_URL}/partners/?id=${this.props.match.params.id}`,
      headers: {
        Authorization: "Bearer " + Cookies.get('authToken')
      }
    } )
    .then( response => {

      // Handle success.
      const data = response.data[0];
      this.setState( { 

        company: data,

      } );
      
    } )
    .catch(error => {

      // Handle error.
      console.log( 'An error occurred:', error.response );
      return (
        <div>Error</div>
      );

    } );

    this.getServices();

  }


  componentWillUnmount() {

    this.props.showHeader();

  }


  handleEditClicked = ( event ) => {

    if (event) event.preventDefault();
    this.setState( { editToggled: true } );
    return false;

  }


  handleEditClose = ( event ) => {

    if (event) event.preventDefault();
    this.setState( { editToggled: false } );
    return false;

  }


  handleUpdateContent = ( { data } ) => {

    console.log(data);
    this.setState( {
      company: data,
      editToggled: false
    } );

    return false;

  }


  getServices() {

    axios( {
      method: 'GET',
      url: `${process.env.REACT_APP_BACKEND_URL}/company-services/`,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + Cookies.get('authToken')
      }
    } )
    .then(response => {
      
      this.setState( {

        serviceOptions: response.data,

      } );
      
    })
    .catch(error => console.log( 'An error occurred:', error.response.data ) );

  }


  profilePicture() {
    
    const { company } = this.state;
    if ( company.profile_picture ) {
      return <Image image={company.profile_picture} className="c-partner__profile-image" />
    }

    if ( company.work_examples && company.work_examples.length ) {   
      return <Image image={company.work_examples[0]} className="c-partner__profile-image" />
    }
    
  }


  biography() {
    
    const { company } = this.state;
    if ( company.biography ) {
      return (
        <div className="c-partner__biography">
          <h3 className="c-partner__subheadline">Biography</h3>
          <p className="c-partner__text">{company.biography}</p>
        </div>
      );
    }

    return <></>;

  }


  companyLegalName() {
    
    const { company } = this.state;
    if ( company.company_legal_name ) {
      return (
        <div className="c-partner__company-legal-name">
          <h3 className="c-partner__subheadline">Company Legal Name</h3>
          <p className="c-partner__text">{company.company_legal_name}</p>
        </div>
      );
    }

    return <></>;

  }

  
  primaryPerson = () => {
    
    const { company } = this.state;
    if ( company.primary_person ) {
      return (
        <div className="c-partner__primary-person">
          <h3 className="c-partner__subheadline">Primary responsible</h3>
          <p className="c-partner__text">
            {company.primary_person.username}<br />
            {company.primary_person.company_role}<br />
            <a href={`mailto:${company.primary_person.email}`}>{company.primary_person.email}</a>
          </p>
        </div>
      );
    }

    return <></>;

  }


  address = () => {

    const { company } = this.state;
    let address_text = "";
    if ( company.address ) {

      address_text += company.address.street_name_and_number ? `${company.address.street_name_and_number}\n` : "";
      address_text += company.address.zip ? `${company.address.zip},` : "";
      address_text += ( company.address.city_country && company.address.city_country.city ) ? `${company.address.city_country.city}\n` : "";
      address_text += ( company.address.city_country && company.address.city_country.country ) ? company.address.city_country.country : "";

    }

    if ( address_text !== "" ) {

      return (
        <div className="c-partner__address">
          <h3 className="c-partner__subheadline">Address</h3>
          <p className="c-partner__text">
            {address_text}
          </p>
        </div>
      );
    }

    return <></>;

  }


  locations = () => {

    const { company } = this.state;
    if ( company.locations ) {
      const location_elements = company.locations.map( ( location, i ) => {
        return (
          <React.Fragment key={`partnerLocation-${i}`}>
            <span className="c-partner__locations__location">{location.city}, {location.country}</span><br />
          </React.Fragment >
        );
      } );
      return (
        <div className="c-partner__locations">
          <h3 className="c-partner__subheadline">Locations</h3>
          <p className="c-partner__text">
            {location_elements}
          </p>
        </div>
      );
    }

    return <></>

  }


  vat = () => {

    const { company } = this.state;
    if ( company.vat ) {
      return (
        <div className="c-partner__vat">
          <h3 className="c-partner__subheadline">VAT</h3>
          <p className="c-partner__text">{company.vat}</p>
        </div>
      );
    }

    return <></>

  }


  website = () => {

    const { company } = this.state;

    if ( company.website ) {
      return (
        <div className="c-partner__website">

          <h3 className="c-partner__subheadline">Website</h3>
          <a
            href={company.website}
            target="_blank"
            rel="noopener noreferrer"
            className="c-partner__text"
          >{company.website}</a>
          
        </div>
      );
    }

    return <></>

  }


  socialMedia = () => {

    const { company } = this.state;
    const urls = [];
    if ( company.instagram ) urls.push( `instagram.com/${company.instagram}` );
    if ( company.twitter ) urls.push( `twitter.com/${company.twitter}` );
    if ( company.linkedin ) urls.push( `linkedin.com/${company.linkedin}` );
    if ( company.facebook ) urls.push( `facebook.com/${company.facebook}` );
    if ( urls.length ) {

      const links = urls.map( ( url, i ) => {
        return (
          <li key={`partnerSocialMediaItem-${i}`} className="c-partner__list__item">
            <a
              href={`https://${url}`}
              target="_blank"
              rel="noopener noreferrer"
              className="c-partner__social-media__link"
            >{url}</a>
          </li>
        );
      } );

      return (
        <div className="c-partner__social-media">
          <h3 className="c-partner__subheadline">Social</h3>
          <ul className="c-partner__list">
            {links}
          </ul>
        </div>
      );
    }

    return <></>

  }


  projects = () => {

    const { company } = this.state;
    if ( company.projects && company.projects.length ) {
      return company.projects.map( ( project, i ) => {
        return <ProjectTeaser key={`projectTeaser-${i}` } { ...project } />
      } );
    }

    return <p>No projects created for {company.name} yet.</p>

  }


  render() {

    const { company, editToggled, serviceOptions } = this.state;

    if ( company ) {

      // Projects length
      const projects_length = ( company.projects ) ? company.projects.length : 0;
      
      // Services
      const services = company.company_services.map( ( service, i ) => {
        return <Tag title={service.title} key={`partnerTeaserService-${i}`} url={`/service/${service.id}`} />;
      } );

      if ( serviceOptions && serviceOptions.length ) {
        partner_schema.properties.company_services.items.properties.id.enum = serviceOptions.map( item => item.id );
        partner_schema.properties.company_services.items.properties.id.enumNames = serviceOptions.map( item => item.title );
      }

      let editing_field = <></>;
      if ( editToggled )  {

        let foundation = {}
        if ( company && company.id ) {
          foundation = {
            data: company.team_persons,
            // array: "primary_person",
            field: "username",
            return: "id"
          }
        };

        editing_field = (
          <AppContext.Consumer>
            { ( context ) => {
              return (
                <Edit
                data={company}
                schema={partner_schema}
                uiSchema={partner_ui_schema}
                contentType="partners"
                content={company}
                foundation={foundation}
                onEditClose={this.handleEditClose}
                onUpdateContent={this.handleUpdateContent}
                toggleEditingField={context.toggleEditingField}
                />
                );
              }}
          </AppContext.Consumer>
        );
      }
        
      return (
        <>
          {editing_field}
        
          <div className="c-partner l-columns-container l-columns-container--outmost">

            <IconCross className="c-partner__close" closeIcon={true} to="/partners" />

            <div className="c-partner__profile-picture__container">
              {this.profilePicture()}
            </div>

            <div className="c-partner__content">

              <div className="c-partner__header">

                <Tag className="c-partner__edit-toggle" title="Edit" transparent small onClick={this.handleEditClicked} />

                <h1 className="c-partner__headline c-partner__name">{company.name} <span className="c-partner__headline__projects">{projects_length}</span></h1>
                <div className="c-partner-teaser__services">{services}</div>

              </div>

              <div className="c-partner__main">
                
                <div className="c-partner__column">

                  {this.biography()}

                </div>

                <div className="c-partner__column">
                  
                  {this.companyLegalName()}
                  {this.primaryPerson()}
                  {this.address()}
                  {this.locations()}
                  {this.vat()}
                  {this.website()}
                  {this.socialMedia()}

                </div>

              </div>

              <div className="c-partner__projects">
                
                <h2 className="c-partner__headline">Projects <span className="c-partner__headline__projects">{projects_length}</span></h2>

                <div className="c-partner__projects__list">
                  {this.projects()}
                </div>

              </div>

              
            </div>

          </div>
        </>
      );

    }

    return <></>

  }
  
}