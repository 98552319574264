import React from "react";
import { Link } from "react-router-dom";

import "./icon_arrow.css";

const IconArrow = ( props ) => {

  const background = props.background ? 'c-icon-arrow--background' : ''; 
  const direction = props.direction ? props.direction : '';
  const arrow = <div>
    <svg className="c-icon-arrow__mobile" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 27">
      <g className="c-icon-arrow__group" stroke="#FFF" strokeWidth="2" fillRule="evenodd" strokeLinecap="square">
        <path d="M1 13.5h21M12 25.5l12.18-12L12 1.5"/>
      </g>
    </svg>
    <svg className="c-icon-arrow__tablet" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 40">
      <g stroke="#FFF" strokeWidth="2" fillRule="evenodd" strokeLinecap="square">
        <path d="M1 20l42.35.22M27.8 38.31l18.02-18.1L27.8 2.12"/>
      </g>
    </svg>
  </div>;



  const to = props.to;
  if ( to ) {
  
    return (
      <Link
        className={`c-icon-arrow c-icon-arrow--${direction} ${background} ${props.className}`}
        to={to}>
        {arrow}
      </Link>
    );

  }

  return (
    <div className={`c-icon-arrow c-icon-arrow--${direction} ${background} ${props.className}`}>
      {arrow}
    </div>
  );

}

export default IconArrow;