import React from "react";
import axios from 'axios';
import Cookies from 'js-cookie';

import AppContext from '../../utils/AppContext';
import ErrorConsumer from '../../components/ErrorConsumer'

export class LoginForm extends React.Component {

  constructor(props) {

    super(props);

    const username = ( this.props.email ) ? this.props.email : '';
    const password = ( this.props.email ) ? 'nopassword' : '';
    
    this.state = {

      // username: 'andreasoby_pm',
      // password: 'chalder',
      errorMessages: [],
      loginResponse: null,
      username: username,
      password: password,
      repeatPassword: '',
      setPassword: false

    };

  }


  componentDidUpdate() {

    const { username, password, setPassword } = this.state;
    if ( username === this.props.email && password === 'nopassword' && !setPassword ) {

      this.handleSubmit();

    }

  }


  handleUsernameChange = ( event ) => { this.setState( { username: event.target.value } ) };
  handlePasswordChange = ( event ) => { this.setState( { password: event.target.value } ) };
  handleRepeatPasswordChange = ( event ) => { this.setState( { repeatPassword: event.target.value } ) };


  handleSubmit = ( event ) => {

    if ( event ) event.preventDefault();
    
    console.log(this.state.username, this.state.password);
    axios( {
      method: 'POST',
      url: `${process.env.REACT_APP_BACKEND_URL}/auth/local`,
      withCredentials: true,
      data: {
        identifier: this.state.username,
        password: this.state.password,
      } 
    } )
    .then(response => {

      if ( this.state.password === 'nopassword' ) {
        
        this.setState( {
          
          loginResponse: response.data,
          password: '',
          setPassword: true
          
        } );
        
      } else {
        
        Cookies.set('authToken', response.data.jwt );//, { sameSite: 'strict' } );
        this.props.userSignIn( response.data.user );
        
      }
      
    })
    .catch(error => {

      error = error.response;
      
      // Handle error.
      if ( error.data.message ) {
        if ( Array.isArray(error.data.message) ) {
          if (
            error.data.message[ 0 ].messages &&
            error.data.message[ 0 ].messages[ 0 ] &&
            error.data.message[ 0 ].messages[ 0 ].message
          ) {
            this.setState( { errorMessages: [ error.data.message[ 0 ].messages[ 0 ].message ] } );
          }
        } else if ( error.data.message === "missing.email" ) {
          this.setState( { errorMessages: ["Missing email"] } );
        }
      }
    });
    
    return false;
    
  }
  
  
  handleEditPasswordSubmit = event => {
    
    if ( event ) event.preventDefault();

    const { password, repeatPassword, loginResponse } = this.state;

    if ( password !== repeatPassword ) return false;
    
    axios( {
      method: 'PUT',
      url: `${process.env.REACT_APP_BACKEND_URL}/users/${loginResponse.user.id}`,
      withCredentials: true,
      data: {
        password: this.state.password,
      } 
    } )
    .then( response => {
      
      Cookies.set('authToken', loginResponse.jwt );
      this.props.userSignIn( loginResponse.user );

    })
    .catch(error => {
      // Handle error.
      console.log('handleEditPasswordSubmit', error);
    });

    return false;

  }


  handleForgotPasswordClicked = ( event ) => {

    event.preventDefault();

    axios( {
      method: 'POST',
      url: `${process.env.REACT_APP_BACKEND_URL}/auth/forgot-password`,
      data: {
        email: this.state.username
      } 
    } )
    .then( response => {
        
      console.log(response);
      
      
    })
    .catch(error => {

      error = error.response;
      
      // Handle error.
      if ( error.data.message ) {
        if ( Array.isArray(error.data.message) ) {
          if (
            error.data.message[ 0 ].messages &&
            error.data.message[ 0 ].messages[ 0 ] &&
            error.data.message[ 0 ].messages[ 0 ].message
          ) {
            this.setState( { errorMessages: [ error.data.message[ 0 ].messages[ 0 ].message ] } );
          }
        } else if ( error.data.message === "missing.email" ) {
          this.setState( { errorMessages: ["Missing email"] } );
        }
      }
    });

    return false;

  }


  getForm = () => {

    if ( this.state.setPassword ) {

      return (
        <form className="c-form" onSubmit={this.handleEditPasswordSubmit}>
          <div className="c-input">
            <label className="c-input__label" htmlFor="loginPassword">Password</label>
            <input className="c-input__input" type="password" id="loginPassword" name="password" value={this.state.password} onChange={this.handlePasswordChange} />
          </div>

          <div className="c-input">
            <label className="c-input__label" htmlFor="loginRepeatPassword">Repeat password</label>
            <input className="c-input__input" type="password" id="loginRepeatPassword" name="repaeatPassword" value={this.state.repeatPassword} onChange={this.handleRepeatPasswordChange} />
          </div>

          <div className="c-form__bottom l-columns-container--outmost">
            <input className="c-form__submit" type="submit" value="Set password" />
          </div>
        </form>
      );

    }

    return (
      <form className="c-form" onSubmit={this.handleSubmit}>
        <div className="c-input">
          <label className="c-input__label" htmlFor="loginUsername">Mail</label>
          <input className="c-input__input" type="text" id="loginUsername" name="username" placeholder="Username" value={this.state.username} onChange={this.handleUsernameChange} />
        </div>

        <div className="c-input">
          <label className="c-input__label" htmlFor="loginPassword">Password</label>
          <input className="c-input__input" type="password" id="loginPassword" name="password" value={this.state.password} onChange={this.handlePasswordChange} />
        </div>
        
        <p className="c-form__links f-small f-dimmed">
          <a href="#0" onClick={this.handleForgotPasswordClicked}>Forgot passsword</a>
          {/* <span>/</span>
          <a href="#0">Create account</a> */}
        </p>

        <div className="c-form__bottom l-columns-container--outmost">
          <input className="c-form__submit" type="submit" value="Sign in" />
        </div>
      </form>
    );

  }


  render() {

    const form = this.getForm();
    let text = <></>;
    if ( this.props.text) text = <h1 className="c-login__headline c-form__headline">{this.props.text}</h1>

    let error_messages = <></>;
    if ( this.state.errorMessages && this.state.errorMessages.length ) {
      
      error_messages = (
        <AppContext.Consumer>
          { ( context ) => {
            return (
              <ErrorConsumer
                showError={() => {
                  context.showError(this.state.errorMessages, 'c-error--left c-error--3')
                  this.setState( { errorMessages: [] } );
                } }
              />
            );
          } }
        </AppContext.Consumer>
      );
      
    }

    return (
    
      <div className="c-login__login">

        {error_messages}

        <div className="c-page c-login__page">
          {text}
          {form}
        </div>

      </div>
    );

  }

}