import React from "react";
import { Link } from "react-router-dom";

import "./tag.css";


const handleTagClicked = ( props ) => {

  if ( props.onClick ) props.onClick();
  return false;

}


const Tag = ( props ) => {

  const tag_text = <p className="c-tag__text">{ props.title }</p>;
  const remove = props.removeable ? <div className="c-tag__cross" /> : <></>;
  let class_name = props.className ? `${props.className} c-tag` : "c-tag";
  if ( props.transparent ) class_name += " c-tag--transparent";
  if ( props.small ) class_name += " c-tag--small";

  if ( props.url ) {
    return (
      <Link to={props.url} className={class_name}>
        {tag_text}
        {remove}
      </Link>
    );
  }

  if ( props.onClick ) {
    return (
      <a href="#0" className={class_name} onClick={() => handleTagClicked( props )}>
        {tag_text}
        {remove}
      </a>
    );
  }

  if ( props.onRemove ) {
    return (
      <a href="#0" className={class_name} onClick={() => props.onRemove(props.title)}>
        {tag_text}
        {remove}
      </a>
    );
  }

  return (
    <div className={class_name}>
      {tag_text}
      {remove}
    </div>
  );
}


export default Tag;