import React from "react";
import axios from 'axios';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

import { basics_schema, basics_ui_schema } from "./schemas/basics";
import { legal_name_schema } from "./schemas/legalName";
import { company_name_schema } from "./schemas/companyName";
import { vat_schema } from "./schemas/vat";
import { address_schema } from "./schemas/address";
// import { primary_person_schema, primary_person_ui_schema } from "./schemas/primaryPerson";
import { responsible_schema, responsible_ui_schema } from "./schemas/responsible";
import { bio_schema, bio_ui_schema } from "./schemas/bio";
import { logo_schema, logo_ui_schema } from "./schemas/logo";
import { website_schema } from "./schemas/website";
import { some_schema } from "./schemas/some";
import { approach_schema, approach_ui_schema } from "./schemas/approach";
import { method_schema, method_ui_schema } from "./schemas/method";
import { creative_brief_schema, creative_brief_ui_schema } from "./schemas/creativeBrief";
import { research_schema, research_ui_schema } from "./schemas/research";
import { tools_schema, tools_ui_schema } from "./schemas/tools";
import { link_schema, link_ui_schema } from "./schemas/link";
import { team_schema, team_ui_schema } from "./schemas/team";
// import { google_drive_schema, google_drive_ui_schema } from "./schemas/googleDrive";
import { delivery_files_schema, delivery_files_ui_schema } from "./schemas/deliveryFiles";
import { delivery_documents_schema, delivery_documents_ui_schema } from "./schemas/deliveryDocuments";
import { github_schema } from "./schemas/github";
import { delivery_credits_schema, delivery_credits_ui_schema } from "./schemas/deliveryCredits";
import { rating_schema, rating_ui_schema } from "./schemas/rating";
import { evaluation_comment_schema, evaluation_comment_ui_schema } from "./schemas/evaluationComment";

import "./engine.css";

import AppContext from '../../../utils/AppContext.js';
import ErrorConsumer from '../../../components/ErrorConsumer';
import ProjectLineItem from "../../../components/ProjectLineItem";
import Edit from "../../../components/ProjectLineItem/ProjectSubLineItem/Edit"
import IconCross from "../../../components/Icons/IconCross";
import Tag from "../../../components/Tag";

export default class Engine extends React.Component {
  

  constructor( props ) {

    super( props );

    this.state = {

      editingFieldSchema: null,
      editingFieldCompleted: false,
      company: null,
      project: null,
      basicsToggled: false,
      basicsOpen: false,
      ndaToggled: false,
      briefToggled: false,
      briefOpen: false,
      contractToggled: false,
      detailsToggled: false,
      detailsOpen: false,
      deliveryToggled: false,
      deliveryOpen: false,
      evaluationToggled: false,
      evaluationOpen: false,
      ...props.location.state

    };

    this.handleAddLinkClicked = this.handleAddLinkClicked.bind(this);
    this.handleAddQuestionClicked = this.handleAddQuestionClicked.bind(this);

  }


  componentDidMount() {

    this.props.hideMenu();
    this.getProject();
    
  }


  callAPI = ( url, method, data = {}, successCallback, errorCallback ) => {

    axios( {
          
      method: method,
      url: `${process.env.REACT_APP_BACKEND_URL}/${url}`,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + Cookies.get('authToken')
      },
      data: data
      
    } )
    .then( response => {
      if ( successCallback ) successCallback( response )
    } )
    .catch( error => {
      if ( errorCallback ) errorCallback( error );
    } );

  }


  getProject = () => {

    const project_success = ( response ) => {
      
      const project = response.data[0];
      if ( project.company ) {
        
        const company_success = ( response ) => {
        
          project.company = response.data[0];
          this.setStateProject( project );

        };

        this.callAPI( `partners?id=${project.company.id}`, "GET", null, company_success );
      
      } else {

        this.setStateProject( project );
        
      }

    };

    this.callAPI( `projects?id=${this.props.match.params.id}`, "GET", null, project_success );

  }


  setStateProject = ( project ) => {

    this.setState( {
          
      project: project,
      basicsToggled: project.basics_toggle,
      ndaToggled: project.nda_toggle,
      briefToggled: project.brief_toggle,
      contractToggled: project.contract_toggle,
      detailsToggled: project.details_toggle,
      deliveryToggled: project.delivery_toggle,
      evaluationToggled: project.evaluation_toggle,
      
    } );

  }
  


  componentWillUnmount() {

    this.props.showMenu();

  }


  handleUpdateContent = ( data ) => {

    let project = this.state.project;

    if ( data.contentType === 'projects' ) project = data.data;
    if ( data.contentType === 'partners' ) project.company = data.data;
    
    this.setState( { project: project } );

  }


  handleBasicsToggleChange = ( toggled ) => this.setState( { basicsToggled: toggled } );
  handleNDAToggleChange = ( toggled ) => this.setState( { ndaToggled: toggled } );
  handleBriefToggleChange = ( toggled ) => this.setState( { briefToggled: toggled } );
  handleContractToggleChange = ( toggled ) => this.setState( { contractToggled: toggled } );
  handleDetailsToggleChange = ( toggled ) => this.setState( { detailsToggled: toggled } );
  handleDeliveryToggleChange = ( toggled ) => this.setState( { deliveryToggled: toggled } );
  handleEvaluationToggleChange = ( toggled ) => this.setState( { evaluationToggled: toggled } );

  sendEmail = ( data ) => {

    const success = ( response ) => { this.setState( { project: response.data } ); };
    const error = ( error ) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {

        this.setState( { errorMessages: [error.response.data.message] } );

      } else {

        console.log( error.response );

      }
    };
    this.callAPI( `projects/${this.state.project.id}`, "PUT", data, success, error );
    return false;

  }

  handleBasicsSendClicked = () => this.sendEmail( { basics_email_sent: true } );
  handleNDACompleteClicked = () => this.sendEmail( { nda_email_sent: true, nda_completed: true } );
  handleNDAUncompleteClicked = () => this.sendEmail( { nda_email_sent: false, nda_completed: false } );
  handleBriefSendClicked = () => this.sendEmail( { brief_email_sent: true } );
  handleContractCompleteClicked = () => this.sendEmail( { contract_email_sent: true, contract_completed: true } );
  handleContractUncompleteClicked = () => this.sendEmail( { contract_email_sent: false, contract_completed: false } );
  handleDetailsSendClicked = () => this.sendEmail( { details_email_sent: true } );
  handleDeliverySendClicked = () => this.sendEmail( { delivery_email_sent: true } );
  handleEvaluationSendClicked = () => this.sendEmail( { evaluation_email_sent: true } );

  handleAccordionToggle = ( property ) => {
     
    if ( property ) {
      
      const is_open = this.state[property];
      const new_state = {};
      new_state[property] = !is_open;
      this.setState( new_state );

    }
  }


  handleAddLinkClicked( params ) {

    const field = params[0];
    const exisiting_data = this.state.project[field];
    const data = {};
    data[`${field}`] = [ ...exisiting_data, { title: "Empty", toggle: true } ];
    
    axios( {

      method: 'PUT',
      url: `${process.env.REACT_APP_BACKEND_URL}/projects/${this.state.project.id}`,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + Cookies.get('authToken')
      },
      data: data
    } )
    .then( response => {

      console.log(response.data);
      this.setState( { project: response.data } );

    } )
    .catch( error => {
      
      console.log(error);

    });

    return false;

  }


  handleAddQuestionClicked( params ) {

    const field = params[0];
    const exisiting_data = this.state.project[field];
    const data = {};
    data[`${field}`] = [ ...exisiting_data, { title: "Question", toggle: true } ];
    
    axios( {

      method: 'PUT',
      url: `${process.env.REACT_APP_BACKEND_URL}/projects/${this.state.project.id}`,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + Cookies.get('authToken')
      },
      data: data
    } )
    .then( response => {

      console.log(response.data);
      this.setState( { project: response.data } );

    } )
    .catch( error => {
      
      console.log(error);

    });

    return false;

  }


  getLinks = (field_name) => {

    const field = this.state.project[field_name];
    let link_data = this.prepareLinkDataForForm( this.state.project[field_name] );
    
    return field.map( ( link, i ) => {
      return {
        title: link.title,
        contentType: "projects",
        schema: link_schema,
        uiSchema: link_ui_schema,
        data: { link: link_data[ i ] },
        content: this.state.project,
        field: { name: field_name, i: i }
      }
    });

  }


  prepareLinkDataForForm = ( links ) => {

    if ( links && links.length ) {
      
      return links.map( link => {
        if ( link ) {
          if ( !link.visual ) delete link.visual;
          if ( !link.link_subtitle ) link.link_subtitle = "";
        }
        return link;
      } );

    }
    
    return [];

  }


  getRatings = (field_name) => {

    const field = this.state.project[field_name];
    let rating_data = this.prepareRatingDataForForm( this.state.project[field_name] );
    
    return field.map( ( rating, i ) => {
      return {
        title: rating.question,
        contentType: "projects",
        schema: rating_schema,
        uiSchema: rating_ui_schema,
        data: {rating: rating_data[ i ] },
        content: this.state.project,
        field: { name: field_name, i: i }
      }
    });

  }


  prepareRatingDataForForm = ( ratings ) => {

    if ( ratings && ratings.length ) {
      
      return ratings.map( rating => {
        // if ( rating ) {
        //   if ( !rating.question ) delete rating.visual;
        //   if ( !rating.rating_subtitle ) rating.rating_subtitle = "";
        // }
        return rating;
      } );

    }
    
    return [];

  }


  handleEditShow = ( schema, completed ) => {

    this.setState( {

      editingFieldCompleted: completed,
      editingFieldSchema: schema

    } );

  }


  handleEditClose = () => {

    this.setState( {

      editingFieldCompleted: false,
      editingFieldSchema: null
      
    } );

  }


  handleBasicsEditClick = () => {

    const { project } = this.state;
    const basics_data = {
      title: project.title,
      project_managers: project.project_managers
    };

    const schema = {
      title: "Project settings",
      contentType: "projects",
      schema: basics_schema,
      uiSchema: basics_ui_schema,
      content: project,
      data: basics_data,
      foundation: {
        url:`users?role=${process.env.REACT_APP_USER_ROLE_PROJECT_MANAGER}`,
        // array: "team_persons",
        field: "username",
        key: 'project_managers',
        return: "id"
      }
    };

    this.handleEditShow( schema );

  }
  

  render() {
    
    // if (
    //   !this.props.user ||
    //   ( this.props.user && this.props.user.role.id !== process.env.REACT_APP_USER_ROLE_PROJECT_MANAGER )
    // ) {
    //   return <Redirect to={`/projects/${this.props.match.params.id}/onboarding`}></Redirect>
    // }

    const { project } = this.state;
    
    if ( !project ) return <></>;

    const { title, company, id } = project;

    const basics_send_label = ( project.basics_email_sent ) ? "Send again" : "Send";
    const basics_actions =[
      { title: basics_send_label, handler: this.handleBasicsSendClicked, style: "primary", idleOnClick: true },
    ];

    const first_company = company;
    if ( !first_company ) {

      return (
        <>
        <h1>Error</h1>
        <p>No company has been attathed to this project. Please contact an administrator to get the issue resolved</p>
        </>
      );

    }

    let legal_name_data = { company_legal_name: first_company.company_legal_name };
    let company_name_data = { name: first_company.name };
    let vat_data = { vat: first_company.vat };
    let address_data = { address: first_company.address };
    // let primary_person_data = { primary_person: { username: "" } };
    // if ( first_company.primary_person ) {

    //   primary_person_data = {primary_person: {}};
    //   if ( first_company.primary_person.id ) primary_person_data.primary_person.id = first_company.primary_person.id;
    //   if ( first_company.primary_person.username ) primary_person_data.primary_person.username = first_company.primary_person.username;
    //   if ( first_company.primary_person.email ) primary_person_data.primary_person.email = first_company.primary_person.email;

    // }
    let responsible_data = { team_responsible: { username: "" } };
    if ( project.team_responsible ) {

      responsible_data = {team_responsible: {}};
      if ( project.team_responsible.id ) responsible_data.team_responsible.id = project.team_responsible.id;
      if ( project.team_responsible.username ) responsible_data.team_responsible.username = project.team_responsible.username;
      if ( project.team_responsible.email ) responsible_data.team_responsible.email = project.team_responsible.email;

    }
    
    let bio_data = { biography: first_company.biography };
    let logo_data = { logo: first_company.logo };
    let website_data = { website: first_company.website };
    let some_data = {
      instagram: first_company.instagram,
      facebook: first_company.facebook,
      linkedin: first_company.linkedin,
      twitter: first_company.twitter
    };
    
    const basics_schema = [

      { title: "Legal name", contentType: "partners", schema: legal_name_schema, data: legal_name_data, content: first_company },
      { title: "Company name", contentType: "partners", schema: company_name_schema, data: company_name_data, content: first_company },
      { title: "VAT", contentType: "partners", schema: vat_schema, data: vat_data, content: first_company },
      { title: "Address", contentType: "partners", schema: address_schema, data: address_data, content: first_company },
      {
        title: "Project responsible",
        contentType: "projects",
        schema: responsible_schema,
        uiSchema: responsible_ui_schema,
        data: responsible_data,
        content: project,
        toggleField: "team_responsible_toggle",
        foundation: {
          url:`partners?id=${first_company.id}`,
          array: "team_persons",
          field: "username",
          return: "id"
        },
      },
      { title: "Bio", contentType: "partners", schema: bio_schema, uiSchema: bio_ui_schema, data: bio_data, content: first_company },
      { title: "Logo", contentType: "partners", schema: logo_schema, uiSchema: logo_ui_schema, data: logo_data, content: first_company },
      { title: "WWW", contentType: "partners", schema: website_schema, data: website_data, content: first_company },
      { title: "@social", contentType: "partners", schema: some_schema, data: some_data, content: first_company, toggleField: "some_toggle" },

    ];


    let nda_actions;
    if ( project.nda_completed ) {
      nda_actions = [ { title: "Unapprove", handler: this.handleNDAUncompleteClicked, style: "secondary" } ];
    } else {
      nda_actions = [ { title: "Approve", handler: this.handleNDACompleteClicked, style: "primary" } ];
    }

    const brief_send_label = ( project.brief_email_sent ) ? "Send again" : "Send";
    const brief_actions = [
      { title: brief_send_label, handler: this.handleBriefSendClicked, style: "primary", idleOnClick: true },
    ];

    
    let approach_data = this.prepareLinkDataForForm( [ project.approach ] );
    approach_data = { approach: approach_data[ 0 ] };
    const approach_title = project.approach && project.approach.link_title ? project.approach.link_title : "Approach";
    let method_data = this.prepareLinkDataForForm( [ project.method ] );
    method_data = { method: method_data[ 0 ] };
    const method_title = project.method && project.method.link_title ? project.method.link_title : "Method";
    let creative_brief_data = this.prepareLinkDataForForm( [ project.creative_brief ] );
    creative_brief_data = { creative_brief: creative_brief_data[ 0 ] };
    const creative_brief_title = project.creative_brief && project.creative_brief.link_title ? project.creative_brief.link_title : "Creative brief";
    let research_data = this.prepareLinkDataForForm( project.research );
    research_data = { research: research_data };
    let tools_data = this.prepareLinkDataForForm( project.tools );
    tools_data = { tools: tools_data };

    const brief_schema = [

      { title: approach_title, contentType: "projects", schema: approach_schema, uiSchema: approach_ui_schema, data: approach_data, content: project },
      { title: method_title, contentType: "projects", schema: method_schema, uiSchema: method_ui_schema, data: method_data, content: project },
      { title: creative_brief_title, contentType: "projects", schema: creative_brief_schema, uiSchema: creative_brief_ui_schema, data: creative_brief_data, content: project },
      { title: "Research", contentType: "projects", schema: research_schema, uiSchema: research_ui_schema, data: research_data, content: project },
      { title: "Tools", contentType: "projects", schema: tools_schema, uiSchema: tools_ui_schema, data: tools_data, content: project },

    ];

    let contract_actions;
    if ( project.contract_completed ) {
      contract_actions = [ { title: "Unapprove", handler: this.handleContractUncompleteClicked, style: "secondary" } ];
    } else {
      contract_actions = [ { title: "Approve", handler: this.handleContractCompleteClicked, style: "primary" } ];
    }

    const details_send_label = ( project.details_email_sent ) ? "Send again" : "Send";
    const details_actions = [

      { title: details_send_label, handler: this.handleDetailsSendClicked, style: "primary", idleOnClick: true },
      { title: "Add link", handler: this.handleAddLinkClicked, params: ['details'], style: "secondary", bottom: true },

    ];

    const details_links = this.getLinks('details');
    const team_data = { team: project.team };
    let details_schema = [
      ...details_links,
      {
        title: "Add core project team",
        contentType: "projects",
        schema: team_schema,
        uiSchema: team_ui_schema,
        data: team_data,
        content: project,
        toggleField: "team_toggle",
        foundation: {
          url:`partners?id=${first_company.id}`,
          array: "team_persons",
          field: "username",
          return: "id"
        },
      },
    ];

    const delivery_send_label = ( project.delivery_email_sent ) ? "Send again" : "Send";
    const delivery_actions = [
      { title: delivery_send_label, handler: this.handleDeliverySendClicked, style: "primary", idleOnClick: true },
    ];

    let delivery_files_link_data = this.prepareLinkDataForForm( [ project.delivery_files_link_new ] );
    delivery_files_link_data = { delivery_files_link_new: delivery_files_link_data[ 0 ] };
    
    let delivery_files_title = "Files";
    if (
      project.delivery_files_link_new &&
      project.delivery_files_link_new.link_title &&
      project.delivery_files_link_new.link_title !== "Link headline" )
    {
      delivery_files_title = project.delivery_files_link_new.link_title;
    }

    let delivery_documents_link_data = this.prepareLinkDataForForm( [ project.delivery_documents_link_new ] );
    delivery_documents_link_data = { delivery_documents_link_new: delivery_documents_link_data[ 0 ] };

    let delivery_documents_title = "Documents";
    if (
      project.delivery_documents_link_new &&
      project.delivery_documents_link_new.link_title &&
      project.delivery_documents_link_new.link_title !== "Link headline" )
    {
      delivery_files_title = project.delivery_files_link_new.link_title;
    }

    let github_data = { delivery_github: project.delivery_github };
    let delivery_credits_data = { delivery_credits: project.delivery_credits };

    const delivery_schema = [

      { title: delivery_files_title, contentType: "projects", schema: delivery_files_schema, uiSchema: delivery_files_ui_schema, data: delivery_files_link_data, content: project },
      { title: delivery_documents_title, contentType: "projects", schema: delivery_documents_schema, uiSchema: delivery_documents_ui_schema, data: delivery_documents_link_data, content: project },
      { title: "Github repository", contentType: "projects", schema: github_schema, data: github_data, content: project },
      {
        title: "Project credits",
        contentType: "projects",
        schema: delivery_credits_schema,
        uiSchema: delivery_credits_ui_schema,
        data: delivery_credits_data,
        content: project,
        // toggleField: "team_toggle",
        foundation: {
          url:`partners?id=${first_company.id}`,
          array: "team_persons",
          field: "username",
          return: "id"
        },
      },

    ];

    const evaluation_send_label = ( project.evaluation_email_sent ) ? "Send again" : "Send";
    const evaluation_actions = [
      { title: evaluation_send_label, handler: this.handleEvaluationSendClicked, style: "primary", idleOnClick: true },
      { title: "Add question", handler: this.handleAddQuestionClicked, params: ['evaluation_ratings'], style: "secondary", bottom: true },
    ];

    let evaluation_comment_data = { evaluation_comment: project.evaluation_comment };

    const evaluation_ratings = this.getRatings('evaluation_ratings');
    const evaluation_schema = [
      
      ...evaluation_ratings,
      { title: "Comment", contentType: "projects", schema: evaluation_comment_schema, uiSchema: evaluation_comment_ui_schema, data: evaluation_comment_data, content: project },
      
    ];
    
    let editing_field = <></>;
    const { editingFieldCompleted, editingFieldSchema } = this.state;
    if ( editingFieldSchema ) {
      editing_field = (
        <AppContext.Consumer>
          { ( context ) => {
            return (
              <Edit
                {...editingFieldSchema}
                completed={editingFieldCompleted}
                onEditClose={this.handleEditClose}
                onUpdateContent={this.handleUpdateContent}
                toggleEditingField={context.toggleEditingField}
              />
            );
          }}
        </AppContext.Consumer>
      );
    }

    // Get company name if company has been added to the project
    const company_name = company ? company.name : "";

    let error_messages = <></>;
    if ( this.state.errorMessages && this.state.errorMessages.length ) {
      
      error_messages = (
        <AppContext.Consumer>
          { ( context ) => {
            return (
              <ErrorConsumer
                showError={() => {
                  context.showError(this.state.errorMessages)
                  this.setState( { errorMessages: [] } );
                } }
              />
            );
          } }
        </AppContext.Consumer>
      );
      
    }

    return (

      <div className="c-engine-project">

        {error_messages}
        
        <div className="c-engine-project__top l-columns-container l-columns-container--outmost">
          <div className="c-engine-project__title-container">
            <h1 className="c-engine-project__title">
              <span className="c-engine-project__title__title">{title} —</span>
              <span className="c-engine-project__title__company">{company_name}</span>
              <Tag
                className="c-engine-project__edit"
                title="Edit"
                transparent
                small
                onClick={this.handleBasicsEditClick}
              />
            </h1>
          </div>

          <Link to="/projects" className="c-engine-project__close">
            <IconCross deleteIcon />
          </Link>
        </div>

        {editing_field}

        <div className="c-page">

          <div className="c-engine-project__page-header l-columns-container l-columns-container--outmost">
            <h2 className="c-engine-project__page-header__title">Project onboarding</h2>
          </div>


          <ProjectLineItem
            title="Basics"
            className={`c-project-line-item--${this.state.basicsOpen ? "expanded" : "collapsed"}`}
            projectId={id}
            toggled={this.state.basicsToggled}
            completed={project.basics_completed}
            toggleField="basics_toggle"
            onToggleChange={this.handleBasicsToggleChange}
            onUpdateContent={this.handleUpdateContent}
            onEditToggled={ this.handleEditShow }
            onOpenToggled={ () => this.handleAccordionToggle( "basicsOpen" ) }
            actions={basics_actions}
            schema={basics_schema}
            number="1"/>

          <ProjectLineItem
            title="NDA"
            projectId={id}
            toggled={this.state.ndaToggled}
            completed={project.nda_completed}
            toggleField="nda_toggle"
            onToggleChange={this.handleNDAToggleChange}
            onUpdateContent={this.handleUpdateContent}
            onEditToggled={ this.handleEditShow }
            actions={nda_actions}
            number="2"/>

          <ProjectLineItem
            title="Brief"
            className={`c-project-line-item--${this.state.briefOpen ? "expanded" : "collapsed"}`}
            projectId={id}
            toggled={this.state.briefToggled}
            completed={project.brief_completed}
            toggleField="brief_toggle"
            onToggleChange={this.handleBriefToggleChange}
            onUpdateContent={this.handleUpdateContent}
            onEditToggled={ this.handleEditShow }
            onOpenToggled={ () => this.handleAccordionToggle( "briefOpen" ) }
            actions={brief_actions}
            schema={brief_schema}
            number="3"/>

          <ProjectLineItem
            title="Contract"
            projectId={id}
            toggled={this.state.contractToggled}
            completed={project.contract_completed}
            toggleField="contract_toggle"
            onToggleChange={this.handleContractToggleChange}
            onUpdateContent={this.handleUpdateContent}
            onEditToggled={ this.handleEditShow }
            actions={contract_actions}
            number="4"/>

          <ProjectLineItem
            title="Details"
            className={`c-project-line-item--${this.state.detailsOpen ? "expanded" : "collapsed"}`}
            projectId={id}
            toggled={this.state.detailsToggled}
            completed={project.details_completed}
            toggleField="details_toggle"
            onToggleChange={this.handleDetailsToggleChange}
            onUpdateContent={this.handleUpdateContent}
            onEditToggled={ this.handleEditShow }
            onOpenToggled={ () => this.handleAccordionToggle( "detailsOpen" ) }
            actions={details_actions}
            schema={details_schema}
            number="5"/>

          <ProjectLineItem
            title="Delivery"
            className={`c-project-line-item--${this.state.deliveryOpen ? "expanded" : "collapsed"}`}
            projectId={id}
            toggled={this.state.deliveryToggled}
            completed={project.delivery_completed}
            toggleField="delivery_toggle"
            onToggleChange={this.handleDeliveryToggleChange}
            onUpdateContent={this.handleUpdateContent}
            onEditToggled={ this.handleEditShow }
            onOpenToggled={ () => this.handleAccordionToggle( "deliveryOpen" ) }
            actions={delivery_actions}
            schema={delivery_schema}
            number="6"/>

          <ProjectLineItem
            title="Evaluation"
            className={`c-project-line-item--${this.state.evaluationOpen ? "expanded" : "collapsed"}`}
            projectId={id}
            toggled={this.state.evaluationToggled}
            completed={project.evaluation_completed}
            toggleField="evaluation_toggle"
            onToggleChange={this.handleEvaluationToggleChange}
            onUpdateContent={this.handleUpdateContent}
            onEditToggled={ this.handleEditShow }
            onOpenToggled={ () => this.handleAccordionToggle( "evaluationOpen" ) }
            actions={evaluation_actions}
            schema={evaluation_schema}
            number="7"/>

        </div>
        
      </div>

    );

  }

};