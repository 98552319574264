import React from "react";

import "./toggle.css";

export default class Toggle extends React.Component {
  

  handleToggleClicked = ( event ) => {

    event.preventDefault();

    const toggled = !this.props.toggled;

    if ( this.props.onToggleChange ) {
      this.props.onToggleChange( toggled );
    }

    return false;

  }


  render() {

    const size = ( this.props.size ) ? this.props.size : "normal";
    const toggled = ( this.props.toggled ) ? "c-toggle--toggled" : "";

    return (

      <a href="#0" className={`c-toggle c-toggle--${size} ${toggled} ${this.props.className}`} onClick={this.handleToggleClicked}>
        <div className="c-toggle__toggle"></div>
      </a>

    );

  }
  
}