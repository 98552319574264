// export const company_services_schema = {
//   type: "object",
//   properties: {
//     company_services: {
//       title: "Select 1-3 specialties",
//       type: "array",
//       items: {
//         title: "",
//         type: "object",
//         properties: {
//           title: {
//             type: "string",
//             default: "",
//           }
//         }
//       }
//     }
//   }
// };

export const company_services_schema = {
  type: "object",
  properties: {
    company_services: {
      title: "Select 1-3 specialties",
      type: "array",
      items: {
        type: "object",
        properties: {
          id: {
            type: "number",
            enum: [],
            enumNames: [],
          },
          // title: {
          //   type: "string",
          //   enum: [],
          //   enumNames: [],
          // }
        }
      }
    }
  }
};

export const company_services_ui_schema = {
  company_services: {
    items: {
      id: {
        "ui:options": { label: false },
      },
      title: {
        "ui:options": { display: false },
      }
    }
  }
}