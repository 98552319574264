import React from 'react';
import IconArrow from '../Icons/IconArrow';
import Image from '../Image';

import "./link.css";

const Link = ( props ) => {

  const { content } = props;
  const link_modifier = ( content.visual ) ? 'visual' : 'no-visual';
  let visual = <></>;
  if ( content.visual ) visual = <div className="c-link__visual-container"><Image className="c-link__visual" image={content.visual} /></div>;
  
  const link_title = ( content.link_title ) ? <p className="c-link__link-title">{content.link_title}</p> : <></>;
  const link_subtitle = ( content.link_subtitle ) ? <p className="c-link__link-subtitle">{content.link_subtitle}</p> : <></>;

  let link_url = content.link;
  if ( link_url && link_url.indexOf("http") !== 0 ) link_url = `https://${link_url}`;

  return (
    <a href={link_url} className={`c-link c-link--${link_modifier}`} target="_blank" rel="noopener noreferrer">

      {visual}

      <div className="c-link__text-container">
        {link_title}
        {link_subtitle}

        <IconArrow className="c-link__arrow" direction="up-right" />
      </div>

    </a>
  );
}

export default Link;