export const company_schema = {
  type: "object",
  properties: {
    company: {
      title: "",
      type: "object",
      properties: {
        id: {
          type: "number",
        },
        name: {
          type: "string",
          default: "",
          title: "Company profile name"
        }
      },
      required: [ "name" ]
    },
  }
};

export const company_ui_schema = {
  company: {
    id: {
      "ui:widget": "hidden",
      "ui:options": {
        label: false
      }
    },
    name: {
      "ui:widget": "AutoCompleteWidget"
    }
  }
}