import React from "react";
import { Redirect } from "react-router-dom";

import { company_schema, company_ui_schema } from "./schemas/company";
import Form from "../../../../components/Form";

import "../create_project.css";

export default class CreateProjectCompany extends React.Component {
  

  constructor( props ) {

    super( props );

    this.state = {

      completed: false,

    };

  }


  handlePreviousClicked = () => {

    this.setState( { redirect: <Redirect to="/create-project/title" /> } );

  }

  
  handleSubmit = ( formData ) => {
    
    this.props.projectUpdated( formData.data );
    this.setState( { completed: true } );

  }


  render() {

    const { completed, redirect } = this.state;

    if ( redirect ) return redirect;

    if ( completed ) {
      return <Redirect to="/create-project/responsible" />
    }

    const foundation = {

      url: "partners",
      field: "name",
      return: "id"

    }

    // Include data to make sure a field is created
    // Won't automatically me created becuase company is an array field
    // and the ui schema specifies that you can only add a single company here
    const data = { company: [ { name: "" } ] };

    return (
      <div className="c-create-project c-page">

        <div className="c-create-project__header l-columns-container l-columns-container--outmost">
          <h1 className="c-create-project__title">Select or create new collaborator</h1>
        </div>

        <div className="c-create-project__main l-columns-container--outmost">

          <Form
              className="c-form"
              {...this.props}
              noValidate={true}
              schema={ company_schema }
              uiSchema={ company_ui_schema }
              content={this.props.project}
              contentType="projects"
              foundation={foundation}
              data={data}
              onUpdateContent={this.handleSubmit}
              onCancelClicked={this.handlePreviousClicked}
              cancelLabel = "Previous"
              submitLabel="Next"
            >
          </Form>

        </div>

      </div>
    );

  }

};