export const evaluation_comment_schema = {
  type: "object",
  properties: {
    evaluation_comment: {
      type: "string",
      default: "",
      title: "Comment"
    },
  }
};

export const evaluation_comment_ui_schema = {
  evaluation_comment: {
    "ui:widget": "TextAreaWidget"
  }
}