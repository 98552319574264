import React from 'react';
import Link from '../Link';

import "./link_page.css";

export default class LinkPage extends React.Component {
  
  handlePreviousClicked = ( event ) => {

    if ( this.props.onCancelClicked ) this.props.onCancelClicked( event );
  
  }
  

  handleNextClicked = ( event ) => {
  
    if ( this.props.onCancelClicked ) this.props.onNextClicked( event );
  
  }

  render() {
    
    const { content } = this.props;
    let title = "";
    let link_element;
    if ( Array.isArray( content) ) {
      
      title = content[ 0 ].title;

      link_element = content.map( link => {
        return <Link key={link.id} content={link} />;
      });

    } else {

      title = content.title;
      link_element = <Link content={content} />;

    }

    return (
      <div className="c-page c-link-page">
        <div className="l-columns-container l-columns-container--outmost">
          <h1 className="c-page__headline c-form__headline">{title}</h1>
        </div>
    
        <div className="c-page__bottom">
          <div className="c-link-page__links l-columns-container l-columns-container--outmost">
            {link_element}
          </div>
    
          <div className="c-form__bottom">
                    
            <a
              href="#0"
              onClick={this.handlePreviousClicked}
              className="c-form__button c-form__button--secondary c-form__submit--left"
            >Previous</a>
    
            <a
              href="#0"
              onClick={this.handleNextClicked}
              className="c-form__submit c-form__submit--right"
            >Next</a>
    
          </div>
        </div>
      </div>
    );
  }

}