export const locations_schema = {
  type: "object",
  properties: {
    locations: {
      title: "",
      type: "array",
      items: {
        type: "object",
        properties: {
          city: {
            title: "Choose city",
            type: "string",
          },
          country: {
            title: "Choose country",
            type: "string",
            enum: [],
          }
        }
      }
    }
  }
};

export const locations_ui_schema = {
  locations: {
    items: {
      classNames: "c-form-group c-form-group--inline c-form-group--location",
    }
  }
}