import React from "react";
import { Link } from "react-router-dom";

import "./icon_cross.css";

const IconCross = ( props ) => {

  const close_icon = props.closeIcon ? 'c-icon-cross--close' : '';
  const delete_icon = props.deleteIcon ? 'c-icon-cross--delete' : '';

  const to = props.to;
  if ( to ) {
  
    return (
      <Link
        className={`c-icon-cross ${props.className} ${close_icon} ${delete_icon}`}
        to={to}>
      </Link>
    );

  }

  return (
    <div className={`c-icon-cross ${props.className} ${close_icon} ${delete_icon}`}>
    </div>
  );

}

export default IconCross;