// http://localhost:3000/projects/1/onboarding?email=en_bruger@andreasoby.com
import React from "react";
import axios from 'axios';
import { Switch, Route, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';

import AppContext from '../../utils/AppContext.js';

import Header from "../../components/Header";
import Partners from "../Partners";
import Partner from "../Partner";
import Projects from "../Projects";
import Project from "../Project";
import Engine from "../Project/engine";
import Onboarding from "../Project/onboarding";
import CreateProject from "../Project/create";
// import CreateProjectManager from "../ProjectManager/create";
import Login from "../../containers/Login";
import Auth from "../../containers/Auth";
import NoMatch from "../NoMatch";
import Dev from "../../components/Dev";

import "./app.css";
import Error from "../../components/Error/index.js";

export default class App extends React.Component {


  constructor(props) {

    super(props);

    this.state = {

      deniedPath: null,
      errorMessages: null,
      errorStyle: null,
      isLoggedIn: false,
      isEditingField: false,
      isShowingError: false,
      showHeader: true,
      showMenu: true,
      hideError: () => this.setState( { isShowingError: false } ),
      showError: ( messages, style ) => {
        this.setState( {
          errorMessages: messages,
          isShowingError: true,
          errorStyle: style
        } )
      },
      toggleEditingField: () => this.setState( { isEditingField: !this.state.isEditingField } ),
      user: null,
      email: null,
      userDataLoaded: false,

    }

  }


  userSignIn = ( user ) => {

    this.setState( {

      isLoggedIn: true,
      user,

    } );

  }


  userSignOut = () => {

    console.log('userSignOut');
    this.setState( {

      user: null,
      isLoggedIn: false,
      userDataLoaded: true

    } );

    Cookies.remove('authToken')

  }


  componentDidMount() {

    this.handleWindowResize();
    window.addEventListener( "resize", () => this.handleWindowResize() );

    const updated_state = { deniedPath: window.location.pathname };
    const query_string = window.location.search;
    const url_params = new URLSearchParams( query_string );
    const email = url_params.get('email')
    if ( email ) updated_state.email = email;
    this.setState( updated_state );

    let auth_token = Cookies.get('authToken')
    if ( !auth_token ) {
      
      const url_params = new URLSearchParams( document.location.search.substring( 1 ) );
      auth_token = url_params.get( 'authToken' );
      if ( auth_token ) Cookies.set('authToken', auth_token);

    }

    axios({

      method: 'GET',
      url: `${process.env.REACT_APP_BACKEND_URL}/users/me`,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + auth_token
      }

    })
      .then(response => {

        const data = response.data;
        if ( data.id ) {

          this.userSignIn( data );
          this.setState( { userDataLoaded: true } );
          
        }

      })
      .catch(error => {

        this.setState( {

          userDataLoaded: true,
          isLoggedIn: false

        } );

      });

  }


  componentDidUpdate() {

    // Make sure deniedPath is reset after redirect so the root path(/)
    // becomes available again
    if ( this.state.isLoggedIn && this.state.deniedPath ) {

      this.setState( { deniedPath: null } );

    }

  }


  handleWindowResize = () => {

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

  }


  conditionalInitialRoute = () => {

    if (this.state.isLoggedIn) {

      if (window.location.pathname === "/") {
        // If Project Manager
        if ( this.state.user && this.state.user.role.name === "Project manager" ){

          if ( this.state.deniedPath ) {
            const denied_path = this.state.deniedPath;
            return <Redirect to={denied_path} />;
          }
          return <Redirect to="/partners" />;

        }

        return <Redirect to="/projects" />;

      }

      return <React.Fragment></React.Fragment>;

    }

    return <Login userSignIn={this.userSignIn} email={this.state.email} />;

  }


  rerouteAfterSignout = () => {

    const { userDataLoaded, isLoggedIn, user } = this.state;

    // User wants to set password, redirect to /auth/email-confirmation
    // and user isn't already logged in
    const url_params = new URLSearchParams(window.location.search);
    const user_id = url_params.get('user');
    const auth_token = url_params.get('confirmation');

    // If url param has user id and authToken the uswer has clicked on the email confirmation link
    if ( user_id && auth_token ) {
      // If the user is already logged in
      // Else send user to set password page
      if ( userDataLoaded && user ) {
        return <Redirect to="/"></Redirect>;
      } else {
        return <></>;
      }

    }

    if ( userDataLoaded && !isLoggedIn ) {
      
      return <Redirect to="/"></Redirect>;

    }

    return <React.Fragment></React.Fragment>;

  }


  showHeader = () => this.setState( { showHeader: true } );
  hideHeader = () => this.setState( { showHeader: false } );
  showMenu = () => this.setState( { showMenu: true } );
  hideMenu = () => this.setState( { showMenu: false } );


  render() {

    const { errorMessages, errorStyle, isShowingError, isLoggedIn, user, showHeader, showMenu, isEditingField, userDataLoaded } = this.state;
    const is_editing_modifier = ( isEditingField ) ? 'c-app--is-editing' : '';

    let error_messages = <></>;
    if ( errorMessages && errorMessages.length ) {
      error_messages = <Error messages={errorMessages} dismissed={!isShowingError} errorStyle={errorStyle}></Error>
    }

    // If user data is fetched
    if ( userDataLoaded ) {

      // If user is successfully logged in
      if ( isLoggedIn && user ) {

        return (
          <AppContext.Provider value={{
            isEditingField: this.state.isEditingField,
            toggleEditingField: this.state.toggleEditingField,
            isShowingError: this.state.isShowingError,
            showError: this.state.showError,
          }}>
            <div className={`c-app ${is_editing_modifier}`}>
    
              {error_messages}

              <Header
                isLoggedIn={isLoggedIn}
                user={user}
                userSignOut={this.userSignOut}
                showHeader={showHeader}
                showMenu={showMenu}
              />
    
              <main className="c-main">
    
                {/* Redirect to root if not logged in */}
                {this.rerouteAfterSignout()}
    
                <Switch>
    
                  {/* Redirect to partners and skip login if user already has a session running */}
                  <Route exact path="/">
                    {this.conditionalInitialRoute}
                  </Route>
    
                  <Route path="/partners" component={Partners} exact />
                  <Route path="/partners/:id" exact render={ ( props ) =>
                    <Partner
                      {...props}
                      showHeader={this.showHeader}
                      hideHeader={this.hideHeader}
                    />
                  } />
    
                  {/* <Route path="/create-project-manager" component={CreateProjectManager} exact /> */}
                  <Route path="/create-project">
                    <CreateProject user={user} />
                  </Route>
    
                  <Route path="/projects" key="projects" exact>
                    <Projects user={user} notifications={false} />
                  </Route>

                  <Route path="/notifications" key="notifications" exact>
                    <Projects user={user} notifications={true} />
                  </Route>
                  
                  <Route path="/projects/:id" exact render={ ( props ) =>
                    <Project
                      {...props}
                      user={user}
                    />}
                  />
                  <Route path="/projects/:id/engine" exact render={ ( props ) =>
                    <Engine
                      {...props}
                      showMenu={this.showMenu}
                      hideMenu={this.hideMenu}
                    />}
                  />
                  <Route path="/projects/:id/onboarding" render={ ( props ) => 
                    <Onboarding {...props} />
                  } />
                  {/* <Route path="/projects/:id/onboarding/basics" render={(props) =>
                    <Basics {...props} />
                  } /> */}
    
                  <Route component={NoMatch} />
    
                </Switch>
    
              </main>
    
              <Dev />
            </div>
          </AppContext.Provider>
        );

      }

      // If error occured when trying to fetch user
      return (

        <AppContext.Provider value={{
          isEditingField: this.state.isEditingField,
          toggleEditingField: this.state.toggleEditingField,
          isShowingError: this.state.isShowingError,
          showError: this.state.showError,
        }}>
      
          <div className={`c-app ${is_editing_modifier}`}>
    
            {error_messages}

            <Header />
    
            <main className="c-main">
    
              {/* Redirect to root if not logged in */}
              {this.rerouteAfterSignout()}
    
              <Switch>
    
                {/* Redirect to partners and skip login if user already has a session running */}
                <Route exact path="/">
                  <Login userSignIn={this.userSignIn} email={this.state.email} />
                </Route>
                
                <Route exact path="/auth/email-confirmation">
                  <Auth userSignIn={this.userSignIn} />  
                </Route>

                <Route exact path="/auth/reset-password">
                  <Auth userSignIn={this.userSignIn} />  
                </Route>
    
                <Route component={NoMatch} />
    
              </Switch>
    
            </main>
    
            <Dev />
            
          </div>
        </AppContext.Provider>
  
      );

    }

    // While fethcing user data
    return (
      
        <div className={`c-app`}>
  
          <Header />
  
          <main className="c-main">
  
           <p>Loading</p>
  
          </main>
  
          <Dev />
        </div>
  
      );
    
  }

}