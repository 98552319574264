import React from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { withTheme } from "react-jsonschema-form";

import AppContext from '../../utils/AppContext';
import ErrorConsumer from '../../components/ErrorConsumer'

import FileUpload from "../Fields/FileUpload";
import AutoSuggest from "../Fields/AutoSuggest";
import IconArrow from "../Icons/IconArrow";
import IconCross from "../Icons/IconCross";

export default class Form extends React.Component {


  constructor( props ) {

    super( props );
    
    // const data = props.data ? props.data : undefined;
    this.state = {
      
      errorMessages: [],
      users: null,

    }

  }


  static getDerivedStateFromProps( props, state ) {

    // return null;
    // Setting data on initial state
    if ( !state.data && props.data && props.data !== state.data ) {
      
      const new_state = state.data = props.data;
      return new_state;
      
    } else if (
      props.data &&
      props.data !== state.data &&
      props.foundation &&
      props.uiSchema ) {
      
      const first_key = Object.keys( props.uiSchema )[ 0 ];
      let new_data = {};
      new_data[ first_key ] = props.data[ first_key ];
      
      return { data: new_data };
    
    } else if (
      props.data &&
      props.data !== state.data ) {
      
        return { data: props.data };

    }

    return null;

  }


  handleCancelClicked = () => {

    if (this.props.onCancelClicked ) this.props.onCancelClicked();

  }


  handleChange = ( { formData } ) => {

    if ( this.props.onChange && this.props.foundation ) {

      // If we're working with a username we must be working with a user
      if ( this.props.foundation.field === "username" ) {
        
        const first_key = Object.keys(this.props.schema.properties)[0];

        // If an array of users
        // Else it's single user
        if ( Array.isArray( formData[ first_key ] ) ) {
          let users = formData[ first_key ].map( user => {

            // If user already existed and is selected by autosuggest
            // Else if user was already assigned
            // Else if user is new user where username and email field entered
            if ( user.username && user.username.username && user.username.id ) {
            
              return user.username;

            } else if ( user.id && user.username && user.email ) {
            
                return user;

            } else if ( user.username && user.email ) {

              return {
                username: user.username,
                email: user.email
              }
            }

            return user.username;
          
          } );

          const new_data = {};
          new_data[first_key] = users;
          this.props.onChange( new_data );

        } else {
        
          const new_data = {};
            
          // Pass the entire user entry in case we need to autofill any fields
          // The entry us stored in the username key (see AutoSuggest component)
          if ( formData[ first_key ] && formData[ first_key ]["username"] && formData[ first_key ]["username"]["id"] ) {
            
            new_data[first_key] = formData[ first_key ]["username"];
            this.props.onChange( new_data );

          }

        }

      }

    }

  }


  callAPI = async ( method, url, data, successCallback, errorCallback ) => {

    axios( {

      method: method,
      url: `${process.env.REACT_APP_BACKEND_URL}/${url}`,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + Cookies.get('authToken')
      },
      data: data

    } )
    .then(response => {
      if ( successCallback ) successCallback( response);
    } )
    .catch(error => {
      if ( errorCallback ) errorCallback( error.response );
    } );

  }


  // Call this when handleSubmit hs executed it's last API call
  createEntrySuccess = response => {
    
    if ( this.props.onUpdateContent ) {

      this.props.onUpdateContent( { contentType: this.props.contentType, data: response.data } );

    }

  }

  // And this if there's an error :()
  createEntryError = error => {

    if ( error && error.data ) {
      if (
        error.data.data &&
        error.data.data.errors
      ) {
        
        const keys = Object.keys(error.data.data.errors);
        const error_messages = [];
        for ( let i = 0; i < keys.length; i++ ) error_messages.push(error.data.data.errors[ keys[ i ] ][ 0 ] );
        this.setState( { errorMessages: error_messages } );

      } else if ( error.data.message ) {
        if ( Array.isArray(error.data.message) ) {
          if (
            error.data.message[ 0 ].messages &&
            error.data.message[ 0 ].messages[ 0 ] &&
            error.data.message[ 0 ].messages[ 0 ].message
          ) {
            this.setState( { errorMessages: [ error.data.message[ 0 ].messages[ 0 ].message ] } );
          }
        } else if ( error.data.message ) {
          if ( error.data.message === "missing.email" ) {
            this.setState( { errorMessages: ["Missing email"] } );
          } else {
            this.setState( { errorMessages: [error.data.message] } );
          }
        }
      }
    }

  }


  createNewUsers = async ( users, first_key ) => {

    const data = users.map( user => {

      // If username is a string it's an existing user
      if ( typeof user.username === "string" && user.id ) {
        return new Promise( ( resolutionFunc ) => {
          return resolutionFunc(user)
        });
      }

      // If username is an object with an id it's an existing user
      if ( user.username && user.username.id ) {
        return new Promise( ( resolutionFunc ) => {
          return resolutionFunc(user.username)
        });
      }

      // Else we have to create a user
      let username = "";
      let email = "";
      let company = "";
      if ( this.props.contentType === "projects" && this.props.content.company ) company = this.props.content.company.id;
      if ( this.props.contentType === "partners" && this.props.content ) company = this.props.content.id;

      // If user has id and user.username is an object with with id === false we're creating a new user
      // and assigning it to a field where there already was a user assigned
      if ( user.id && user.username.id === false ) {
        username = user.username.username;
        email = user.email;
      } else if ( first_key ) {
        username = user[ first_key ].username;
        if ( username && username.username ) {
          username = user[ first_key ].username.username;
        }
        email = user[ first_key ].email;
      } else {
        if ( typeof user.username === "string" ) {
          username = user.username;
        } else {
          username = user.username.username;
        }
        email = user.email;
      }

      const user_to_create = {
        username: username,
        email: email,
        password: 'nopassword',
        confirmed: true,
        company: company,
        role: `${process.env.REACT_APP_USER_ROLE_EXTERNAL_PRIMARY}`
      }

      return axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/users`,
        user_to_create,
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + Cookies.get('authToken')
          },
        }
      )
      .catch(error => this.createEntryError( error.response ));

    } );

    return Promise.all(data)
    .then( result => {

      return result.map( item => {
        
        // If the item contains an object/entry
        if (item ) {
        
          // If item has id the user is ready to be passed
          if ( item.id ) return item;
          
          // If item data, it's an axios request and we should pass the data
          if ( item.data ) return item.data;

        }

        return false;

      } ) ;

    } );

  }


  createNewCompany = async ( companies, first_key ) => {

    const data = companies.map( company => {

      // If name is a string it's an existing company
      if ( typeof company.name === "string" && company.id ) {
        return new Promise( ( resolutionFunc ) => {
          return resolutionFunc(company)
        });
      }

      // If name is an object with an id it's an existing company
      if ( company.name && company.name.id ) {
        return new Promise( ( resolutionFunc ) => {
          return resolutionFunc(company.name)
        });
      }

      // Else we have to create a user
      let name = "";

      // If company has id and company.name is an object with with id === false we're creating a new company
      // and assigning it to a field where there already was a company assigned
      if ( company.id && company.name.id === false ) {
        name = company.name.name;
      } else if ( first_key ) {
        name = company[ first_key ].name;
        if ( name && name.name ) {
          name = company[ first_key ].name.name;
        }
      } else {
        if ( typeof company.name === "string" ) {
          name = company.name;
        } else {
          name = company.name.name;
        }
      }

      const company_to_create = { name: name }

      return axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/partners`,
        company_to_create,
        {
          withCredentials: true,
          headers: {
            Authorization: "Bearer " + Cookies.get('authToken')
          },
        }
      )
      .catch(error => this.createEntryError( error.response ));

    } );

    return Promise.all(data)
    .then( result => {

      return result.map( item => {
        
        // If the item contains an object/entry
        if (item ) {
        
          // If item has id the user is ready to be passed
          if ( item.id ) return item;
          
          // If item data, it's an axios request and we should pass the data
          if ( item.data ) return item.data;

        }

        return false;

      } ) ;

    } );

  }


  handleSubmit = ( { formData } ) => {

    let no_interruptions = true;
    
    // this.props.field always has to contain an array of links
    if ( this.props.field ) {
      
      const field = this.props.content[this.props.field.name];
      if ( this.props.field.i > -1 && field[ this.props.field.i ] ) {
        
        if ( this.props.field.name === "evaluation_ratings" ) {
          if ( this.props.field.noManipulatedData ) {
            field[ this.props.field.i ] = formData;
          } else {
            field[ this.props.field.i ] = formData.rating;
          }
        } else if ( formData.link ) {
          field[ this.props.field.i ] = formData.link;
        } else if ( formData.rating ) {
          field[ this.props.field.i ] = formData.rating;
        }
        
      } else {
        
        if ( formData.link ) {
          field.push(formData.link)
        } else if ( formData.rating ) {
          field.push(formData.rating)
        } else if ( formData.evaluation_ratings ) {
          field.push(formData.evaluation_ratings)
        }
        
      }
      
      const modified_form_data = {};
      modified_form_data[ this.props.field.name ] = field;
      formData = modified_form_data;
      
    }


    if ( this.props.options && this.props.options.field ) {  

      formData[ this.props.options.field ].map( item => {

        const option = this.props.options.options.filter( option => option.id === item.id );
        if ( option ) item.title = [ 0 ].title;
        return item;

      } );
      
    }

    // If entering a username we asume we're dealing with a user relation
    // If entering a name we asume we're dealing with a company relation
    // User and company relations only accepts the id of the related entry
    // therefore we only pass the id and not the entire entry object
    if ( this.props.foundation ) {
      if (
        this.props.foundation.field === "username" ||
        this.props.foundation.field === "name"
      ) {
      
        const key = ( this.props.foundation.key ) ? this.props.foundation.key : Object.keys(formData)[0];
        let new_data = ( this.props.foundation.key ) ? formData : {};

        if ( Array.isArray(formData[key]) ) {

          no_interruptions = ( this.props.foundation.key );
          const form_entities = formData[key].map( item => {
            
            // If item has id, it's an exisiting item in the list
            // Else if AutoSuggest returns an object with an id the entity already exists, but isn't assigned to the list
            // Else we have to create a new entity
            if ( item.id ) return item;

            if ( item[this.props.foundation.field] && item[this.props.foundation.field].id ) {

              return item[this.props.foundation.field];

            }

            const entity_data = {};
            entity_data[key] = item;
            return entity_data;    

          } );

          let new_entities_promise;
          if (this.props.foundation.field === "username") {
            new_entities_promise = this.createNewUsers( form_entities, key );
          } else if (this.props.foundation.field === "name") {
            new_entities_promise = this.createNewCompany( form_entities, key );
          }

          // Non exisiting enetities has been created
          new_entities_promise.then( response => {

            let url = `${this.props.contentType}/${this.props.content.id}`; 
            const data = {};
            data[key] = response;
            new_data[key] = response;
            // Add created entities to field
            this.callAPI( "PUT", url, data, this.createEntrySuccess, this.createEntryError );

          } );

        } else {
          
          // If new_data[key] returns a string it means that no id was found
          // which means that we have to create either the user or the company
          // based on the value of this.props.foundation.field
          // console.log('SUBMIT ELSE', formData, new_data, key);
          
          // If we're not recieving an id
          if ( !Number.isInteger(formData[key]) ) {
            
            // If foundation.field is "name" we assume the field is handling a company
            if ( this.props.foundation.field === "name" ) {
              
              // If we're getting an object with id and name back
              // we have an exisiting company to add
              if (
                formData[key]["name"]["id"] &&
                formData[key]["name"]["name"]
              ) {
                new_data[key] = {
                  id: formData[key]["name"]["id"],
                  name: formData[key]["name"]["name"]
                };
                no_interruptions = true;

              // If we're getting a string
              // we have to create a new company before we can add it
              // to the project
              } else if ( formData[key]["name"] ) {

                const company_successfully_created = ( response ) => {
                  
                  let url = `${this.props.contentType}/${this.props.content.id}`; 
                  const data = {};
                  data[key] = {
                    id: response.data.id,
                    name: response.data.name,
                  }
                  
                  // Add company to field
                  this.callAPI( "PUT", url, data, this.createEntrySuccess, this.createEntryError );
                  
                };
                
                // New company data
                const company_to_create = {
                  name: formData[key]["name"]
                };

                
                // Create company
                this.callAPI( "POST", "partners", company_to_create, company_successfully_created, this.createEntryError );

                no_interruptions = false;

              }
              

            } else if ( this.props.foundation.field === "username" ) {
              
              no_interruptions = true;
              // If username is string the user aready exists and is unchanged from initial value
              // Or if username has id
              // and we only have to create a proper new_data object
              // and add it to the entry we're editing
              if (
                typeof formData[key]["username"] === "string" &&
                formData[key]["username"]["id"]
              ) {
                
                // If foundation specifies that we only need the id
                // Else pass the id and the username
                if ( this.props.foundation.return === "id" ) {
                  new_data[key] = formData[key]["id"];
                } else {
                  new_data[key] = {
                    id: formData[key]["id"],
                    name: formData[key]["username"]
                  };
                }
                

              // If we're getting a string
              // we have to create a new user before we can add it
              // to the project
              } else {

                no_interruptions = false;
                const users_promise = this.createNewUsers( [formData[key]] );
                
                // Non exisiting users has been created
                users_promise.then( response => {

                  let url = `${this.props.contentType}/${this.props.content.id}`; 
                  const data = {};

                  // If the response doesn't have any errors
                  if (response[ 0 ] ) {
 
                    // If foundation specifies that we only need the id
                    // Else pass complete user entry
                    if ( this.props.foundation.return === "id" ) {
                      data[key] = response[ 0 ].id;
                    } else {
                      data[key] = response[ 0 ];
                    }

                    this.callAPI( "PUT", url, data, this.createEntrySuccess, this.createEntryError );

                  }

                } );

              }

            }
            

          } else {

            new_data[key] = formData[key][this.props.foundation.field];

          }

        }

        formData = new_data;

      }
      
    }
    
    if ( no_interruptions ) {
      
      const method = this.props.method ? this.props.method : "PUT";
      let url = `${this.props.contentType}`;
      if ( method === "PUT") url += `/${this.props.content.id}`
      this.callAPI( method, url, formData, this.createEntrySuccess, this.createEntryError );

    }

  }


  CustomFieldTemplate = (props) => {

    const {id, classNames, label, help, description, rawDescription, errors, children, displayLabel, required, schema } = props;
    
    const label_text = ( required ) ? `${label}<span className="c-input__required">*</span>`: label ;
    const raw_label_text = {__html: label_text};
    const label_element = ( displayLabel && label !== "" ) ? <label className="c-input__label" htmlFor={id} dangerouslySetInnerHTML={raw_label_text}></label> : <></>;
    
    let description_element = description;
    if ( rawDescription && rawDescription.length ) {
      const raw_description = {__html: rawDescription};
      description_element = <p className="c-input__description" dangerouslySetInnerHTML={raw_description}></p>
    }

    const hidden_modifier = schema && schema.hidden ? 'c-input--hidden' : '';

    return (
      <div className={`c-input ${hidden_modifier} ${classNames}`}>
        {description_element}
        {label_element}
        {children}
        {errors}
        {help}
      </div>
    );
  }


  getCompanyUsers = () => {

    // Fetching complete company object to obtain users associated with it
    let company;
    if (this.props.contentType === 'partners' ) company = this.props.content;
    if (this.props.contentType === 'projects' ) company = this.props.content.company;
    
    if ( company ) {
      
      axios( {
        
        method: 'GET',
        url: `${process.env.REACT_APP_BACKEND_URL}/partners?id=${company.id}`,
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + Cookies.get('authToken')
        }
        
      } )
      .then( response => { this.setState( { users: response.data[0].team_persons } ); } )
      .catch( error => console.log( error.response.data ) );

    }

  }


  render() {
    
    const textWidget = (props) => {
      
      const value = ( !props.value ) ? "" : props.value;
    
      return (
        <input type="text"
          className="c-input__input"
          value={value}
          required={props.required}
          onChange={(event) => props.onChange(event.target.value)} />
      );
    
    };

    const numberWidget = (props) => {
      
      const value = ( !props.value ) ? 0 : props.value;
    
      return (
        <input type="number"
          className="c-input__input"
          value={value}
          required={props.required}
          onChange={(event) => props.onChange(event.target.value)} />
      );
    
    };
    
    const textAreaWidget = (props) => {
    
      const value = ( !props.value ) ? "" : props.value;
    
      return (
        <textarea type="text"
          className="c-input__input c-input__input--textarea"
          required={props.required}
          onChange={(event) => props.onChange(event.target.value)}
          value={value}
        />
      );
    
    };
    
    const fileWidget = (props) => {
      return <FileUpload
        { ...props }
        onChange={ ( response ) => {
          // const urls = response.response.map( item => item.url );
          // console.log(urls[0]);
          console.log(response.response);
          props.onChange(response.response[0])
        } }  
      />
    };
    
    const autoCompleteWidget = ( props ) => {
      
      // Foundation should be set by url
      return <AutoSuggest
        foundation={this.props.foundation}
        required={props.required}
        value={props.value}
        onChange={ response => {
          props.onChange(response);
        } }
        onBlur={ response => {
          
          console.log('Form onBlur', response);

        } }
      />;
    
    };

    const selectWidget = (props) => {
      
      const value = ( !props.value ) ? "" : props.value;
      const options = props.options.enumOptions;
    
      return (
        <div className="c-input__input-container">
          <select
            className="c-input__input"
            value={value}
            required={props.required}
            onChange={(event) => props.onChange(event.target.value)}>
              {options.map(option => <option key={option.value} value={option.value}>{option.label}</option>) }
          </select>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" preserveAspectRatio="xMaxYMid">
            <path fill="#FFF" d="M15.44 2.15l-6.63 9.28a1 1 0 01-1.62 0L.56 2.15A1 1 0 011.37.57h13.26a1 1 0 01.81 1.58z"/>
          </svg>
        </div>  
      );
    
    };

    const ratingWidget = props => {

      const value = ( !props.value ) ? "" : props.value;
      const options = props.options.enumOptions;
      
      const rate_buttons = options.map( ( option, i ) => {

        const modifier = value && value === option.value ? "s-selected" : "";
        // console.log(rating, option.value);
        return (
          <a
            key={`${props.id}_${i}`}
            href="#0"
            className={`c-input__rating-number ${modifier}`}
            onClick={ (event) => {

              event.preventDefault();
              // if ( rating ) this.setState( { rating: option.value } );
              props.onChange(option.value);
              return false;

            } }
            >
            <span>{option.label}</span>
          </a>
        );
      } );

      return (
        <div className="c-input__input-container c-input__input-container--rating">
          {rate_buttons}
        </div>
      );
      
    }

    const myWidgets = {
      TextWidget: textWidget,
      NumberWidget: numberWidget,
      TextAreaWidget: textAreaWidget,
      FileWidget: fileWidget,
      AutoCompleteWidget: autoCompleteWidget,
      SelectWidget: selectWidget,
      RatingWidget: ratingWidget
    };

    const ArrayFieldTemplate = ( props ) => {

      const {className, title, required, schema } = props;
    
      const title_text = ( required ) ? `${title}<span className="c-input__required">*</span>`: title ;
      const raw_title_text = {__html: title_text};
      const title_element = ( title && title !== "" ) ? <p className="c-input__label" dangerouslySetInnerHTML={raw_title_text}></p> : <></>;
      const button_top_spacing_modifier = schema && schema.buttonTopSpacing ? 'c-form__array-item--button-top-spacing' : '';
      
      return (
        <div className={className}>
          
          {title_element}
          
          {props.items.map( (element, i) => {
            return (
              <div key={ element.key } className={`c-form__array-item ${button_top_spacing_modifier}`}>
                {element.children}
                {element.hasMoveUp && <button className="c-input__button" type="button" onClick={element.onReorderClick(element.index, element.index - 1)}><IconArrow direction="up" /></button>}      
                {element.hasMoveDown && <button className="c-input__button" type="button" onClick={element.onReorderClick(element.index, element.index + 1)}><IconArrow direction="down" /></button>}      
                {element.hasRemove && <button className="c-input__button" type="button" onClick={element.onDropIndexClick(element.index)}><IconCross deleteIcon /></button>}      
              </div>
            );
          })}
          
          {props.canAdd && <button className="c-input__button" type="button" onClick={props.onAddClick}><IconCross /></button>}
        </div>
      );
    }

    const theme = { ArrayFieldTemplate: ArrayFieldTemplate, widgets: myWidgets };
    const ThemedForm = withTheme(theme);

    const submit_label = ( this.props.submitLabel ) ? this.props.submitLabel : "Submit";
    
    let cancel_button = <></>;
    if ( this.props.onCancelClicked ) {
      
      const cancel_label = ( this.props.cancelLabel ) ? this.props.cancelLabel : "Cancel";
      cancel_button = (
          <a
            href="#0"
            onClick={this.handleCancelClicked}
            className="c-form__button c-form__button--secondary c-form__submit--left"
            >{cancel_label}</a>
      );
    }

    let error_messages = <></>;
    if ( this.state.errorMessages && this.state.errorMessages.length ) {
      
      error_messages = (
        <AppContext.Consumer>
          { ( context ) => {
            return (
              <ErrorConsumer
                showError={() => {
                  context.showError(this.state.errorMessages)
                  this.setState( { errorMessages: [] } );
                } }
              />
            );
          } }
        </AppContext.Consumer>
      );
      
    }


    return (

      <>

        {error_messages}

        <ThemedForm
          className="c-form"
          noValidate={true}
          schema={ (this.props.schema) ? this.props.schema : {} }
          formData={ (this.state.data) ? this.state.data : {} }
          uiSchema={ (this.props.uiSchema) ? this.props.uiSchema : {} }
          FieldTemplate={this.CustomFieldTemplate}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
        >
          <div className="c-form__bottom">

            {cancel_button}
            <input className="c-form__submit c-form__submit--right" type="submit" value={submit_label} />

          </div>
          
        </ThemedForm>

      </>
    );

  }

}