import React from "react";
import { Link } from "react-router-dom";

import Image from '../../components/Image';
import Tag from '../../components/Tag';

import "./partner_teaser.css";

const PartnerTeaser = ( props ) => {

  // Image
  const image_data = props.profile_picture;
  let image = image_data ? <Image image={image_data} className="c-partner-teaser__profile-image" /> : <React.Fragment />;

  // Projects length
  const projects_length = props.projects.length;

  // Locations
  let locations;
  if ( props.locations && props.locations.length ) {
    locations = props.locations.map( ( location, i ) => {
      if ( i < 2 ) return <p key={`partnerTeaserLocation-${i}`} className="c-partner-teaser__location">{location.city}, {location.country}</p>;
      return <React.Fragment key={`partnerTeaserLocation-${i}`}></React.Fragment>;
    } );

    if ( props.locations.length > 2) {
      locations.push(
        <Link key="partnerTeaserLocationDots" to={`/partners/${props.id}`} className="c-partner-teaser__location__dots">
          <span />
          <span />
          <span />
        </Link>
      );
    }
  } else if ( props.address && props.address.city_country ) {
    locations = <p className="c-partner-teaser__location">{props.address.city_country.city}, {props.address.city_country.country}</p>
  }
  
  // Services
  let services;
  if ( props.company_services ) {
    services = props.company_services.map( ( service, i ) => {
      return <Tag title={service.title} key={`partnerTeaserService-${i}`} url={`/service/${service.id}`} />;
    } );
    // Reverse, flex direction is reversed because of layout
    services = services.reverse();
  }

  return (
    <div className="c-partner-teaser">
      
      <Link to={`/partners/${props.id}`} className="c-partner-teaser__profile-image-container">
        {image}
      </Link>

      <Link to={`/partners/${props.id}`} className="c-partner-teaser__name">{props.name} <span className="c-partner-teaser__name__projects">{projects_length}</span></Link>

      <div className="c-partner-teaser__locations">{locations}</div>

      <div className="c-partner-teaser__services">{services}</div>

    </div>
  );

}

export default PartnerTeaser;