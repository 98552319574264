import React from "react";

export default class ErrorConsumer extends React.Component {

  componentDidMount() {

    this.props.showError();

  }

  render() {

    return <></>;

  }

}