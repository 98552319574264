import React from "react";
import axios from 'axios';
import { Redirect, Link } from "react-router-dom";
import Cookies from 'js-cookie';

import "./partners.css";

import SearchForm from "../../components/SearchForm";
import PartnerTeaser from "../../components/PartnerTeaser";
import IconCross from "../../components/Icons/IconCross";

export default class Partners extends React.Component {

  constructor( props ) {
    
    super( props );

    this.state = {

      partners: [],
      partnersSorted: [],
      redirectPath: undefined

    }

  }


  componentDidMount() {

    axios( {
      method: 'GET',
      withCredentials: true,
      url: `${process.env.REACT_APP_BACKEND_URL}/partners`,
      headers: {
        Authorization: "Bearer " + Cookies.get('authToken')
      }
    } )
    .then( response => {

      // Handle success.
      const data = response.data;
      this.setState( {

        partners: data,
        partnersSorted: data

      } );
      
    } )
    .catch( error => console.log( error ) );

  }


  handleUpdateQuery = ( queries ) => {

    let result;
    if ( queries.length) {

      // Returns an array for each query
      const results = queries.map( query => {
      
        return this.state.partners.filter( partner => {
  
          // Filter by partner name
          if ( partner.name.toLowerCase().indexOf(query.toLowerCase()) > -1) {
            return true;
          }

          // Filter by partner services
          for ( let i = 0; i < partner.company_services.length; i++ ) {
            if ( partner.company_services[ i ].title.toLowerCase().indexOf(query.toLowerCase()) > -1) {
              return true;
            }
          }

          // Filter by partner projects
          for ( let i = 0; i < partner.projects.length; i++ ) {
            if ( partner.projects[ i ].title.toLowerCase().indexOf(query.toLowerCase()) > -1) {
              return true;
            }
          }
          
          return false;
  
        } );
  
      });

      // Combine arrays to a single object
      result = [];
      for ( let i = 0; i < results.length; i++ ) {
        result = result.concat(results[i]);
      }

      // Remove duplicates
      const unique_results = new Set( result );
      result = [ ...unique_results ];

    } else {

      result = this.state.partners;

    }

    this.setState( { partnersSorted: result } );

  }


  partners() {

    return this.state.partnersSorted.map( ( partner, i ) => {
      return <PartnerTeaser key={`partnerTeaser-${i}` } { ...partner } />
    } );

  }


  render() {

    if ( this.state.redirectPath && this.state.redirectPath !== "" ) {
      return <Redirect to={this.state.redirectPath} ></Redirect>
    }

    return (
      <div className="c-partners c-page">

        <div className="c-partners__header l-columns-container--outmost">
          <SearchForm updateQuery={this.handleUpdateQuery} />

          <Link to="/create-project/title" className="c-partners__header__link-right">
            <IconCross />
          </Link>
        </div>

        <div className="c-partners__partners l-columns-container l-columns-container--outmost">
          
            {this.partners()}
              
        </div>
      </div>
    );
  }
  
}