export const biography_schema = {
  type: "object",
  properties: {
    biography: {
      type: "string",
      default: "",
      title: "Short company biography (-200)",
    },
  }
};

export const biography_ui_schema = {
  biography: {
    "ui:widget": "TextAreaWidget",
    "ui:placeholder": "Begin typing"
  }
}