import React from "react";
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
import Cookies from 'js-cookie';

let cancelToken;

export default class AutoSuggest extends React.Component {


  constructor( props ) {

    super( props );

    // const foundation = this.props.foundation ? this.props.foundation : [];
    let value = this.props.value ? this.props.value : '';
    
    // If we get a user object passed, only use the username
    if ( value.username ) value = value.username;
    // If we get a company object passed, only use the company name
    if ( value.name ) value = value.name;

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.

    this.state = {

      foundationRaw: [],
      foundation: [],
      value,
      suggestions: []

    };

  }


  componentDidMount() {

    if ( this.props.foundation ) this.getFoundation();

  }


  componentWillUnmount() {

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

  }


  getFoundation = async () => {

    const { foundation } = this.props;
    let data;
    
    if ( foundation.url ) {
 
      cancelToken = axios.CancelToken.source();

      await axios( {
          
        method: 'GET',
        url: `${process.env.REACT_APP_BACKEND_URL}/${foundation.url}`,
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + Cookies.get('authToken')
        },
        cancelToken: cancelToken.token
        
      } )
      .then( response => {

        data = response.data;

      } )
      .catch( error => console.log( error ) );
    
    } else if ( foundation.data ) {

      data = foundation.data;

    }

    if ( data ) {
    
      // Using foundation_raw to hold the requested entries in their entire form
      let foundation_raw = [];

      // Using foundation_arr to hold the values use for suggestions
      let foundation_arr = [];
      
      // If requested multiple entries
      if ( data.length > 1 ) {

        foundation_raw = data;
        if ( foundation_raw && foundation_raw.length ) foundation_arr = foundation_raw.map( item => item[foundation.field]);

      // If requested single entry containing the needed array
      } else if ( foundation.array ) {

        foundation_raw = data[0][foundation.array];
        if ( foundation_raw && foundation_raw.length ) foundation_arr = foundation_raw.map( item => item[foundation.field]);

      } else if ( foundation.data ) {

        foundation_raw = data;
        foundation_arr = data.map( item => item[foundation.field]);

      }

      this.setState( {

        foundationRaw: foundation_raw,
        foundation: foundation_arr

      } );

    }

  }


  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = value => {

    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : this.state.foundation.filter( item =>
      item.toLowerCase().slice(0, inputLength) === inputValue
    );

  };


  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = suggestion => suggestion;//[this.props.foundation.field];


  // Use your imagination to render suggestions.
  renderSuggestion = suggestion => (
    <div>
      {suggestion}
    </div>
  );
  

  onChange = (event, { newValue }) => {

    this.setState({
      value: newValue
    });

    this.props.onChange( newValue );

  };


  onSuggestionSelected = ( event, { suggestionValue } ) => {

    const { foundationRaw } = this.state;
    
    let item_to_parse = foundationRaw.filter( item => {
      return item[this.props.foundation.field] === suggestionValue
    } );
    
    if ( this.props.onChange && item_to_parse && item_to_parse.length ) {
      
      // If entering a username we asume we're dealing with a user relation
      // If entering a name we asume we're dealing with a company relation
      // Pass entire object in case we need to autofill any other fields in the form
      // with values from the user/company
      if ( this.props.foundation.field === "username" || this.props.foundation.field === "name" ) {
        
        item_to_parse[0]['autoComplete'] = true;
        this.props.onChange( item_to_parse[ 0 ] );
        
      } else {
      
        this.props.onChange( item_to_parse[ 0 ][this.props.foundation.field] );
        
      }

    } else {

      // If value isn't in the foundation
      // pass the value and an empty id, to override the id from the initial state
      item_to_parse = { id: false };
      item_to_parse[this.props.foundation.field] = suggestionValue;
      this.props.onChange( item_to_parse );

    }

  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {

    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: '',
      value,
      onChange: this.onChange,
      className: "c-input__input",
      required: this.props.required
    };

    // Finally, render it!
    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
      />
    );
    
  }

}