import React from "react";

import Form from "../../../Form";
import Completed from "../../../Form/Completed";

import "./edit.css";

export default class Edit extends React.Component {

  
  constructor( props ) {

    super( props );

    this.state = {
      
      data: null,
      components: {},
      errorMessages: [],
      users: null,
      usersCreated: [],
      mounted: false


    }

  }


  componentDidMount() {

    setTimeout(() => {
      
      if ( this.props.toggleEditingField ) this.props.toggleEditingField();
      
    }, 100);
    
  }


  componentWillUnmount() {

    if ( this.props.toggleEditingField ) this.props.toggleEditingField();

  }


  componentDidUpdate() {

    if ( !this.state.data && this.props.data ) {
      
      this.setState( {
        
        // forceUpdate: true,
        data: this.props.data

      } );
    
    }

  }


  handleChange = ( formData ) => {

    // If new data us comming in and the new data is an object
    // update data to autofill id field and email field
    if ( this.state.data !== formData ) {
      if ( this.props.foundation && this.props.uiSchema ) {

        const first_key = Object.keys(this.props.uiSchema)[ 0 ];
        if ( formData[ first_key ] ) {

          let update_form_data = false;
          if ( Array.isArray( formData[ first_key ] ) ) {

            for ( let i = 0; i < formData[ first_key ].length; i++ ) {
          
              if ( formData[ first_key ][ i ] && formData[ first_key ][ i ].autoComplete ) {
                  
                // Don't need this property any longer, so just delete it
                delete formData[ first_key ][ i ].autoComplete;
                update_form_data = true;
                
              }
              
            }

          } else if ( formData[ first_key ].autoComplete ) {
                  
            // Don't need this property any longer, so just delete it
            delete formData[ first_key ].autoComplete;
            update_form_data = true;
              
          }
          
          if ( update_form_data ) this.setState( { data: formData } );

        }

      }

    }

  }


  handleEditSubmit = ( formData ) => {
    
    this.props.onEditClose();
    this.props.onUpdateContent( { contentType: formData.contentType, data: formData.data } );

  }


  handleCancelClicked = () => {

    // TODO: Implement deleteUploadedFiles functionality
    // Make sure this loop works
    // const file_upload_components = this.state.fileUploadComponents;
    // for ( let i = 0; i < file_upload_components.length; i++ ) file_upload_components[ i ].deleteUploadedFiles();

    this.props.onEditClose();

    // Delete users created
    // const users_created = this.state.usersCreated;
    // for ( let i = 0; i < users_created.length; i++ ) {

    //   axios( {

    //     method: 'DELETE',
    //     url: `${process.env.REACT_APP_BACKEND_URL}/users/${users_created[ i ].id}`,
    //     withCredentials: true,
    //     headers: {
    //       Authorization: "Bearer " + Cookies.get('authToken')
    //     }
  
    //   } )
    //   .then( response => console.log( response ) )
    //   .catch( error => console.log( error ) );
  
    // }

  }


  render() {

    if ( this.props.completed ) {

      return (
        <div className="c-edit l-columns-container l-columns-container--outmost">
  
          <div className="c-edit__inner">
  
            <Completed
              className="c-form"
              {...this.props}
              noValidate={true}
              // schema={ (this.props.schema) ? this.props.schema : undefined }
              // uiSchema={ (this.props.uiSchema) ? this.props.uiSchema : undefined }
              data={ (this.state.data) ? this.state.data : undefined }
              FieldTemplate={this.CustomFieldTemplate}
              onCancelClicked={this.handleCancelClicked}
            >
            </Completed>
  
          </div>
  
        </div>
      );

    };
    
    return (
      <div className="c-edit l-columns-container l-columns-container--outmost">

        <div className="c-edit__inner">

          <Form
            className="c-form"
            {...this.props}
            noValidate={true}
            // schema={ (this.props.schema) ? this.props.schema : undefined }
            // uiSchema={ (this.props.uiSchema) ? this.props.uiSchema : undefined }
            data={ (this.state.data) ? this.state.data : undefined }
            FieldTemplate={this.CustomFieldTemplate}
            onChange={this.handleChange}
            onCancelClicked={this.handleCancelClicked}
            onUpdateContent={this.handleEditSubmit}
            submitLabel="Save"
          >
          </Form>

        </div>

      </div>
    );

  }

}