export const team_responsible_contact_schema = {
  type: "object",
  title: "",
  properties: {
    phone: {
      type: "string",
      default: "",
      title: "Phone"
    },
    company_role: {
      type: "string",
      default: "",
      title: "Company role"
    }
  }
};