import React from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Switch, Route } from "react-router-dom";
import Welcome from './welcome';
import Overview from './overview';
import Basics from './basics';
import Brief from './brief';
import Details from './details';
import Delivery from './delivery';
import Evaluation from './evaluation';

import "./onboarding.css"

export default class Onboarding extends React.Component {

  constructor( props ) {

    super( props );

    this.state = {
      
      // welcomeDisplayed: false,
      projectFetching: false,
      project: null,

    }

  }


  componentDidMount() {
    
    this.ifMatchParamsReady();
    
  }


  componentDidUpdate() {

    this.ifMatchParamsReady();

  }
  
  
  ifMatchParamsReady() {
    
    if (
      !this.state.projectFetching &&
      !this.state.project &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params ) this.getProject();
    
  }


  getProject() {

    this.setState( { projectFetching: true } );

    axios( {

      method: 'GET',
      url: `${process.env.REACT_APP_BACKEND_URL}/projects?id=${this.props.match.params.id}`,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + Cookies.get('authToken')
      }

    } )
    .then( response => {

      this.setState( {
        
        project: response.data[ 0 ],

      } );
      
    } )
    .catch( error => console.log( 'An error occurred:', error.response ) );

  }


  handleProjectUpdated = ( project ) => {

    console.log('handleProjectUpdated', project);

    this.setState( { project: project } );

  }


  onboardingTop = ( project ) => {

    if ( project ) {

      const steps = [ "basics", "nda", "brief", "contract", "details", "delivery", "evaluation" ];
      const step_titles = [ "Basics", "NDA", "Brief", "Contract", "Details", "Delivery", "Evaluation" ];
      const next_step = [];
      let val = 0;
      steps.map( ( step, i ) => {

        if ( project[`${step}_toggle`] ) val++;
        if ( project[`${step}_toggle`] && !project[`${step}_completed`] ) {
          next_step.push( { step: step, title: step_titles[i], i: val } );
        }
        return false;

      } );
      
      if ( next_step && next_step[ 0 ] ) {

        return (
          <header className="c-onboarding__top">
            <h1 className="c-onboarding__top__title">{next_step[ 0 ].title}</h1>
            <p className="c-onboarding__top__number">{next_step[ 0 ].i}</p>
          </header>
        );
      }
    }

    return <></>;

  }


  render() {

    const { project } = this.state;
    
    // let step = <></>;
    // if ( project ) {
    //   if ( !welcomeDisplayed ) {
    //     step = <Redirect to={{
    //       pathname: `/projects/${project.id}/onboarding/basics/welcome`,
    //       state: { project: project }
    //     }}></Redirect>;
    //   }
    // }
    //   } else if ( project.basics_toggle && !project.basics_completed) {
    //     step = <Redirect to={{
    //       pathname: `/projects/${project.id}/onboarding/overview`,
    //       state: { project: project }
    //     }}></Redirect>
    //   } else if ( project.brief_toggle && !project.brief_completed) {
    //     step = <p>Brief</p>
    //   } else if ( project.details_toggle && !project.details_completed) {
    //     step = <p>details</p>
    //   } else if ( project.delivery_toggle && !project.delivery_completed) {
    //     step = <p>delivery</p>
    //   } else if ( project.evaluation_toggle && !project.evaluation_completed) {
    //     step = <p>evaluation</p>
    //   }

    // }

    const top = this.onboardingTop( project );

    return (
      <div className="c-onboarding">
        
        {/* {step} */}

        <Switch>
            
          <Route path="/projects/:id/onboarding/welcome" exact>
            <Welcome projectUpdated={this.handleProjectUpdated} project={project} />
          </Route>

          <Route path="/projects/:id/onboarding/overview" exact>
            <Overview projectUpdated={this.handleProjectUpdated} project={project} />
          </Route>

          <Route path="/projects/:id/onboarding/basics" exact>
            <>
              {top}
              <Basics projectUpdated={this.handleProjectUpdated} project={project} onProjectUpdate={this.handleProjectUpdated}/>
            </>
          </Route>

          <Route path="/projects/:id/onboarding/brief" exact>
            <>
              {top}
              <Brief projectUpdated={this.handleProjectUpdated} project={project} onProjectUpdate={this.handleProjectUpdated}/>
            </>
          </Route>

          <Route path="/projects/:id/onboarding/details" exact>
            <>
              {top}
              <Details projectUpdated={this.handleProjectUpdated} project={project} onProjectUpdate={this.handleProjectUpdated}/>
            </>
          </Route>

          <Route path="/projects/:id/onboarding/delivery" exact>
            <>
              {top}
              <Delivery projectUpdated={this.handleProjectUpdated} project={project} onProjectUpdate={this.handleProjectUpdated}/>
            </>
          </Route>

          <Route path="/projects/:id/onboarding/evaluation" exact>
            <>
              {top}
              <Evaluation projectUpdated={this.handleProjectUpdated} project={project} onProjectUpdate={this.handleProjectUpdated}/>
            </>
          </Route>

        </Switch>
      </div>
    );
  }
}