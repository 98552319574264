import React from 'react';
import { Redirect } from 'react-router-dom';

import { github_schema } from "./schemas/github";
import { delivery_credits_schema, delivery_credits_ui_schema } from "./schemas/deliveryCredits";
import { complete_schema, complete_ui_schema } from "./schemas/complete";

import Form from '../../../../components/Form';
import LinkPage from '../../../../components/LinkPage';

export default class Delivery extends React.Component {

  constructor( props ) {

    super( props );

    const project = this.props.project ? this.props.project : null;
    const formConfig = project ? this.getFormConfig( project ) : [];
    this.state = {

      formConfig: formConfig,
      project: project,
      step: 0,

    }

  }


  componentDidUpdate() {

    const { project, formConfig } = this.state;

    if ( !project && this.props.project ) {
      this.setState( {

        project: this.props.project,
        formConfig: this.getFormConfig( project )

      } );

    } else if ( project && ( !formConfig || formConfig.length === 0 ) ) {
      
      this.setState( {

        formConfig: this.getFormConfig( project )
      
      } );

    }

  }


  handleUpdateContent = ( response ) => {

    const { formConfig, step } = this.state;
    
    if ( step === formConfig.length - 1) {

      const updated_project = response.data;
      this.props.onProjectUpdate( updated_project );
      this.setState( { step: step + 1 } );

    } else if ( response.contentType === "projects" ) {

      const updated_project = response.data;
      this.setState( {

        project: updated_project,
        step: step + 1

      } );
  
    }

  }


  handleChangeContent = formData => {

    const { formConfig, step } = this.state;
     // If new data us comming in and the new data is an object
    // update data to autofill id field and email field
    if ( formConfig[ step ].data !== formData ) {
      if ( formConfig[ step ].foundation && formConfig[ step ].uiSchema ) {

        const first_key = Object.keys(formConfig[ step ].uiSchema)[ 0 ];
        if ( formData[ first_key ] ) {

          let update_form_data = false;
          for ( let i = 0; i < formData[ first_key ].length; i++ ) {
            
            if ( formData[ first_key ][ i ] && formData[ first_key ][ i ].autoComplete ) {
                
              // Don't need this property any longer, so just delete it
              delete formData[ first_key ][ i ].autoComplete;
              update_form_data = true;
              
            }
            
          }
          
          if ( update_form_data ) {
            
            formConfig[ step ].data = formData;
            this.setState( { formConfig: formConfig } );

          }

        }

      }

    }

  }


  handlePreviousClicked = ( event ) => {

    if ( event ) event.preventDefault();
    
    const { step } = this.state;
    this.setState( { step: step - 1 } );

    return false;
    
  }


  handleNextClicked = ( event ) => {

    if ( event && event.data ) {

      

    } else if ( event ) {

      event.preventDefault();

    }
    
    
    const { step } = this.state;
    this.setState( { step: step + 1 } );

    return false;

  }


  getFormConfig = ( project ) => {

    const steps = [];
    
    if ( project ) {

      let data;

      if ( project.delivery_files_link_new && project.delivery_files_link_new.toggle ) {
        steps.push( <LinkPage content={ project.delivery_files_link_new } onCancelClicked={this.handlePreviousClicked} onNextClicked={this.handleNextClicked} /> );
      }

      if ( project.delivery_documents_link_new && project.delivery_documents_link_new.toggle ) {
        steps.push( <LinkPage content={ project.delivery_documents_link_new } onCancelClicked={this.handlePreviousClicked} onNextClicked={this.handleNextClicked} /> );
      }
      
      // Github
      if ( project.delivery_github_toggle ) {
        
        data = { delivery_github: project.delivery_github };

        let delivery_github_shema = {
          title: "Share Github repository with SPACE10 and make it open-soruce",
          contentType: "projects",
          schema: github_schema,
          data: data,
          content: project,
        }

        steps.push( delivery_github_shema );

      }


      // Credit
      if ( project.delivery_credits_toggle ) {

        data = { delivery_credits: project.delivery_credits };

        let credits_schema = {
          title: "Project credits",
          contentType: "projects",
          schema: delivery_credits_schema,
          uiSchema: delivery_credits_ui_schema,
          data: data,
          content: project,
          foundation: {
            url:`partners?id=${project.company.id}`,
            array: "team_persons",
            field: "username",
            return: "id"
          },
        };

        steps.push( credits_schema );
      }

      // Complete step
      data = { delivery_completed: true };
      steps.push( {
        title: "That’s all.<br>Please confirm you’ve added the milstones and you are up and running with slack and the G suite.", contentType: "projects",
        schema: complete_schema,
        uiSchema: complete_ui_schema,
        data: data,
        content: project,
        submitLabel: "Submit"
      } );
      
    }

    return steps;

  }


  render() {

    const { formConfig, step } = this.state;

    if ( this.props && this.props.project && formConfig && formConfig.length && step === formConfig.length) {
      
      return <Redirect to={`/projects/${this.props.project.id}/onboarding/overview/`}></Redirect>;

    }

    let content = <></>;
    if ( formConfig[ step ] && formConfig[ step ].schema ) {
      
      const form_config = formConfig[ step ];
      let title = form_config && form_config.title ? form_config.title : "";
      const title_element = { __html: title };

      content = (
        <div className="c-page">
          
          <div className="l-columns-container l-columns-container--outmost">
            <h1 className="c-page__headline c-form__headline" dangerouslySetInnerHTML={title_element}></h1>
          </div>
          
          <Form
            submitLabel="Next"
            cancelLabel="Previous"
            {...form_config}
            onUpdateContent={this.handleUpdateContent}
            onChange={this.handleChangeContent}
          />
        </div>
      );

    } else {
      content = formConfig[ step ];
    }

    let progress_element = <></>;
    if ( formConfig.length ) {

      const progress_unit = 100 / formConfig.length;
      const progress = progress_unit * step + progress_unit;
      const style = { width: `${progress}%` };
      progress_element = <div className="c-progress" style={style} />;

    }


    return (
      <>
        {progress_element}
        {content}
      </>
    );
  }
}